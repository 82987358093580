import React, { Component } from 'react';
import axios from 'axios';
import { getToken } from '../../../utils/Common';

import { withTranslation } from 'react-i18next';

import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';

import PieChart from '../../ProjectTracking/components/Charts/PieChart';

class CustomersProjectsAnalysis extends Component{
  constructor(props){
    super(props);
    this.state = {
      colors:['#002d73', '#999', '#618BD4', '#ffaa00', '#58CF6C', '#a61d04', '#ff7400', '#006635', '#764e00', '#0065FF', '#ff8f7a'],
      projectEvaluation:[],
      customerEvaluation:[],
      projectEvaluationData:[],
      customerEvaluationData:[],
    }
  }

  componentDidMount(){
    this.loadProjectEvaluation()
    this.loadCustomerEvaluation()
  }

  loadProjectEvaluation(){
    let token = getToken()

    if(token) {
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/evaluation/me/project`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(response => {
          var list = []
          var data = []
          for(var j = 0; j<response.data.length;j++){
            data.push({hours:response.data[j].hours, name:response.data[j].project})
            list.push(response.data[j].hours)
          }
          this.setState({projectEvaluation:list, projectEvaluationData:data })
      }).catch(error =>{
        this.setState({projectEvaluation:[]})
      })
    }
  }

  loadCustomerEvaluation(){
    let token = getToken()

    if(token) {
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/evaluation/me/customer`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(response => {
          var list = []
          var data = []
          for(var j = 0; j<response.data.length;j++){
            data.push({hours:response.data[j].hours, name:response.data[j].customer})
            list.push(response.data[j].hours)
          }
          this.setState({customerEvaluation:list, customerEvaluationData:data})
      }).catch(error =>{
        this.setState({customerEvaluation:[]})
      })
    }
  }

  render(){
    const {t} = this.props
    return(<>
      <Row className='mt-25'>
        <Col md={8} lg={8} xs={8}>
          <h4>{t('customers') + '- ' + t('Projects')} <b>{t('analysis')}</b></h4>
          <p>{t('sentences.customersProjectsAnalysis')}</p>
        </Col>
        <Col md={4} lg={4} xs={4}>

        </Col>
        <Col md={7} lg={4} xs={12} className={'text-center'} style={{display:"flex"}}>
          <PieChart
            data={ this.state.customerEvaluation }
            radius={ this.props.windowWidth > (160 * 2) ? 160 : (this.props.windowWidth / 2) - 60 }
            hole={ 60 }
            colors={ this.state.colors }
            labels={ true }
            percent={ true }
            strokeWidth={ 3 }
            stroke={ '#fff' }
          />
        </Col>
        <Col md={5} lg={2} xs={12} className={'my-auto'} >
          {this.state.customerEvaluationData.map((el,elIndex) => { 
            return <h5 key={'c-'+elIndex} ><FontAwesomeIcon style={{color: this.state.colors[elIndex % this.state.colors.length]}} icon={faCircle} /> {el.name}</h5>
          })}
        </Col>

        <Col md={7} lg={4} xs={12} className={'text-center'} style={{display:"flex"}}>
          <PieChart
            data={ this.state.projectEvaluation }
            radius={ this.props.windowWidth > (160 * 2) ? 160 : (this.props.windowWidth / 2) - 60 }
            hole={ 60 }
            colors={ this.state.colors }
            labels={ true }
            percent={ true }
            strokeWidth={ 3 }
            stroke={ '#fff' }
          />
        </Col>
        <Col md={5} lg={2} xs={12} className={'my-auto'}>
          {this.state.projectEvaluationData.map((el,elIndex) => { 
            return <h5 key={'p-'+elIndex} ><FontAwesomeIcon style={{color: this.state.colors[elIndex % this.state.colors.length]}} icon={faCircle} /> {el.name}</h5>
          })}
        </Col>
      </Row>
      </>
    );
  }
}

export default (withTranslation()(CustomersProjectsAnalysis));