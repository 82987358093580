import React, { Component } from 'react';
import axios from 'axios';
import config from '../../../../config/Settings.json'
import { connect } from "react-redux";
import { ShowDialog, ListCustomers } from "../../../../store/Actions/index";
import { getToken } from '../../../../utils/Common';
import { withTranslation } from 'react-i18next';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import DraggableModalDialog from '../../../DraggableModalDialog/DraggableModalDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/free-regular-svg-icons'

class CustomerModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      Name:null,
      Location:null,
      Address:null,
      PostalCode:null,
      Active:null,
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  handleOnSubmit(){
    let token = getToken()
    if(token) {
      if(this.props.thisCustomer){
        axios.put(`${window._env_.REACT_APP_SERVICE_API}/customer/${this.props.thisCustomer.Id}`, { Name:this.state.Name, Address:this.state.Address, Location:this.state.Location, PostalCode:this.state.PostalCode, Active:this.state.Active, CustomerNumber:this.state.CustomerNumber}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }}).then(response => {
            this.props.ListCustomers()
            if(response.data.changedRows>0){
              this.props.onHide()
            }
        }).catch(error =>{
          this.props.onHide()
          this.props.ShowDialog('Error', error.message)
        })
      }
      else{
        axios.put(`${window._env_.REACT_APP_SERVICE_API}/customers/`, { Name:this.state.Name, Address:this.state.Address, Location:this.state.Location, PostalCode:this.state.PostalCode, Active:this.state.Active, CustomerNumber:this.state.CustomerNumber}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }}).then(response => {
            this.props.ListCustomers()
            if(response.data.insertId>0){
              this.props.onHide()
            }
        }).catch(error =>{
          this.props.onHide()
          this.props.ShowDialog('Error', error.message)
        })
      }
    }
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }
  
  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="lg"
      backdrop="static"
      dialogAs={DraggableModalDialog} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Customer')} <b>{t('Settings')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4><FontAwesomeIcon icon={faBuilding} /> {this.props.thisCustomer ? this.props.thisCustomer.Name : this.state.Name}</h4>
        <Form>

          <Form.Row> 
            <Form.Group as={Col} controlId="Name">
              <Form.Label>{t('Name')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="text" placeholder={t('Name')} defaultValue={this.props.thisCustomer ? this.props.thisCustomer.Name : this.state.Name} />
              <Form.Text className="text-muted">
              {t('fullCompanyName')}
              </Form.Text>
            </Form.Group>

            <Form.Group as={Col} controlId="CustomerNumber" >
              <Form.Label>{t('customerNumber')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="text" placeholder={t('customerNumber')} defaultValue={this.props.thisCustomer ? this.props.thisCustomer.CustomerNumber : this.state.CustomerNumber}/>
            </Form.Group> 
          </Form.Row> 
          <Form.Group controlId="Address" >
            <Form.Label>{t('Address')}</Form.Label>
            <Form.Control onChange={this.handleOnChange} type="text" placeholder={t('Address')} defaultValue={this.props.thisCustomer ? this.props.thisCustomer.Address : this.state.Address}/>
          </Form.Group>

          <Form.Row> 
            <Form.Group as={Col} controlId="PostalCode" >
              <Form.Label>{t('postalCode')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="text" placeholder={t('postalCode')} defaultValue={this.props.thisCustomer ? this.props.thisCustomer.PostalCode : this.state.PostalCode}/>
            </Form.Group>

            <Form.Group as={Col} controlId="Location" >
              <Form.Label>{t('Location')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="text" placeholder={t('Location')} defaultValue={this.props.thisCustomer ? this.props.thisCustomer.Location : this.state.Location}/>
            </Form.Group>
          </Form.Row> 

        </Form>

      </Modal.Body>

      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        <Button onClick={this.handleOnSubmit} variant="primary" >{t('Save')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    thisCustomer: state.thisCustomer[0],
    customerModalShow: state.customerModalShow,
  };
};

export default connect(mapStateToProps, {ShowDialog, ListCustomers})(withTranslation()(CustomerModal));