import { GET_ROLE, GET_USER, UPDATE_USER, ADD_USER, SET_ME_NOTIFICATION } from "../Constants/action-types";
import { getToken, getUser } from '../../utils/Common';
import config from '../../config/Settings.json';

export function GetRole(){
  return function(dispatch){
    const token = getToken();
    if(token){
      var request = require('request');
      var options = {
        method: 'POST',
        url: window._env_.REACT_APP_SERVICE_API + '/auth/verifyToken',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      };
      request(options, function (error, response,body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: GET_ROLE, payload: json.role });
        }
      });
    }
  }
}

export function GetMyNotification(){
  return function(dispatch) {
    const token = getToken();
    if(token){
      var request = require("request");
      var options = { 
        method: 'GET',
        url: window._env_.REACT_APP_SERVICE_API + '/users/me/notification',
        headers: {
          Authorization: 'Bearer ' + token
        }
      };
      
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: SET_ME_NOTIFICATION, payload: json });
        }
      });
    }
  }
}

export function AddMyNotification(emailType){
  return function(dispatch) {
    const token = getToken();
    if(token){
      var request = require("request");
      var options = { 
        method: 'POST',
        url: window._env_.REACT_APP_SERVICE_API + '/users/me/notification',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"emailType":emailType})
      };
      
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: SET_ME_NOTIFICATION, payload: json });
        }
      });
    }
  }
}

export function RemoveMyNotification(emailType){
  return function(dispatch) {
    const token = getToken();
    if(token){
      var request = require("request");
      var options = { 
        method: 'DELETE',
        url: window._env_.REACT_APP_SERVICE_API + `/users/me/notification?emailType=${emailType}`,
        headers: {
          Authorization: 'Bearer ' + token
        }
      };
      
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: SET_ME_NOTIFICATION, payload: json });
        }
      });
    }
  }
}

export function UpdateUser(obj) {
  return function(dispatch){
    const token = getToken();
    if(token){
      var request = require("request");
      var options = { 
        method: 'PUT',
        url: window._env_.REACT_APP_SERVICE_API + '/users/'+obj.guid,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      };
      
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        return dispatch({ type: UPDATE_USER, payload: obj });
      });
    }
  }
}

export function WhoIam() {
  return function(dispatch) {
    const token = getToken();
    const user = getUser();
    if(token && user){
      var request = require("request");
      var options = { 
        method: 'GET',
        url: window._env_.REACT_APP_SERVICE_API + '/users/'+user.guid,
        headers: {
          Authorization: 'Bearer ' + token
        }
      };
      
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: GET_USER, payload: json[0] });
        }
      });
    }
  }
}

export function ShowAddUserModal(val) {
  return function(dispatch){
    return dispatch({type: ADD_USER, payload: val})
  }
}