import React from 'react';
import { useHistory } from "react-router";
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import logo from '../../images/error-404.png';

const NotFound = props => {

  const history = useHistory();
  const back = () => {
    history.push('/')
  }

  const {t} = props
  return(
    <Container>
      <Row>
        <Col>
          <Image src={logo} fluid  style={{ width: '80%' }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button 
            variant="primary"
            onClick={back} >
            {t('sentences.backHome')}
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default withTranslation()(NotFound);