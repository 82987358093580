import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import axios from 'axios';
import config from '../../../../config/Settings.json'
import { connect } from "react-redux";
import { InsertWorkRecords, UpdateWorkRecords, ListWorkRecords } from "../../../../store/Actions/index";
import { getToken } from '../../../../utils/Common';
import { DERgetTimeDifference } from '../../../../utils/TimeParser';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import DraggableModalDialog from '../../../DraggableModalDialog/DraggableModalDialog'
import { withTranslation } from 'react-i18next';

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

class WorkModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      Edit:false,
      Loaded:false,
      Id:null,
      Date:'',
      TravelTime:0,
      Employee:'',
      Description:'',
      Departure:'',
      Arrival:'',
      Customer_fk:null,
      Customer_Name:null,
      Project_fk:null,
      Project_Name:null,
      DistanceKM:0,
      privateCar:false,
      FoodAllowance:0,
      TravelExpenses:0,
      HoursWorked:0,
      Location:'',
      CustomerOptions:'',
      ProjectOptions:'',
      ProjectOptionsArray: [],
      CustomerOptionsArray:[],
      DescriptionError: null,
      HoursWorkedError: null,
      LocationError: null,
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleCustomerChange = this.handleCustomerChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleOnSubmitAndNext = this.handleOnSubmitAndNext.bind(this)
    this.clearForm = this.clearForm.bind(this)
  }

  UNSAFE_componentWillUpdate(){
    if(this.state.Edit && !this.state.Loaded){
      let token = getToken()
  
      if(token){
        const header = {
          headers: { Authorization: `Bearer ${token}` }
        };
  
        axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${this.state.Customer_fk}`,header).then(response => {
          var items = []
          var list = []
          for(var j = 0; j<response.data.length;j++){
            list.push({key:response.data[j].Id,value:response.data[j].Name})
            if(response.data[j].Id === this.state.Project_fk)
              items.push(<option selected key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
            else
              items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
          }
          this.setState({
            Loaded:true,
            ProjectOptions:items,
            ProjectOptionsArray:list
          })
        }).catch(error => {
          this.setState({
            Loaded:true,
            ProjectOptions:<option >Error</option>,
            ProjectOptionsArray:null
          })
        });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.show && !this.props.show && nextProps.thisWork){
      let token = getToken()

      if(token){
        const header = {
          headers: { Authorization: `Bearer ${token}` }
        };
    
        axios.get(`${window._env_.REACT_APP_SERVICE_API}/customers`,header).then(response => {
          var items = []
          var list = []
          for(var j = 0; j<response.data.length;j++){
            list.push({key:response.data[j].Id,value:response.data[j].Name})
            if(response.data[j].Id === nextProps.thisWork.Customer_fk)
              items.push(<option selected key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
            else
              items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
          }
          this.setState({
            CustomerOptions:items,
            CustomerOptionsArray:list,
          })
        }).catch(error => {
          this.setState({
            CustomerOptions:<option >Error</option>,
            CustomerOptionsArray:null
          })
        });
      }

      var privateCar = false
      if(nextProps.thisWork.TravelExpenses > 0)
        privateCar = true

      this.setState({
        Edit:true,
        Loaded:false,
        Id:nextProps.thisWork.Id,
        Date:dateFormat(nextProps.thisWork.Date,"yyyy-mm-dd"),
        Employee:nextProps.thisWork.Employee,
        Description:nextProps.thisWork.Description,
        Departure:nextProps.thisWork.Departure,
        Arrival:nextProps.thisWork.Arrival,
        Customer_fk:nextProps.thisWork.Customer_fk,
        Project_fk:nextProps.thisWork.Project_fk,
        Customer_Name:nextProps.thisWork.Customer_Name,
        Project_Name:nextProps.thisWork.Project_Name,
        DistanceKM:nextProps.thisWork.DistanceKM,
        privateCar:privateCar,
        FoodAllowance:nextProps.thisWork.FoodAllowance,
        HoursWorked:nextProps.thisWork.HoursWorked,
        TravelTime:nextProps.thisWork.TravelTime,
        Location:nextProps.thisWork.Location,
        TravelExpenses:nextProps.thisWork.TravelExpenses,
        DescriptionError: null,
        HoursWorkedError: null,
        LocationError: null,
      })
    }
    else if(nextProps.show && !this.props.show && !nextProps.thisWork){
      let token = getToken()
      if(token){
        var selectedCustomer = null

        const header = {
          headers: { Authorization: `Bearer ${token}` }
        };
    
        axios.get(`${window._env_.REACT_APP_SERVICE_API}/customers`,header).then(response => {
          var items = []
          var list = []
          for(var j = 0; j<response.data.length;j++){
            if(!selectedCustomer)
              selectedCustomer = response.data[j]
            list.push({key:response.data[j].Id,value:response.data[j].Name})
            items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
          }
          this.setState({
            Customer_fk:selectedCustomer.Id,
            Customer_Name:selectedCustomer.Name,
            CustomerOptions:items,
            CustomerOptionsArray:list,
          })
          if(selectedCustomer){
            axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${selectedCustomer.Id}`,header).then(response => {
              var items = []
              var list = []
              var selected = null
              for(var j = 0; j<response.data.length;j++){
                if(!selected)
                  selected = response.data[j]
                list.push({key:response.data[j].Id,value:response.data[j].Name})
                items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
              }
              this.setState({
                Project_fk:selected.Id,
                Project_Name:selected.Name,
                ProjectOptions:items,
                ProjectOptionsArray:list
              })
            }).catch(error => {
              this.setState({
                Project_fk:null,
                ProjectOptions:<option >Error</option>,
                ProjectOptionsArray:null
              })
            });
          }
        }).catch(error => {
          this.setState({
            Customer_fk:null,
            CustomerOptions:<option >Error</option>,
            CustomerOptionsArray:null
          })
        });
      }
      this.setState({
        Edit:false,
        Loaded:false,
        Id:null,
        Date:dateFormat(Date.now(),"yyyy-mm-dd"),
        TravelTime:0,
        Employee:'',
        Description:'',
        Departure:'',
        Arrival:'',
        Location:nextProps.user.location,
        DistanceKM:0,
        privateCar:false,
        FoodAllowance:0,
        TravelExpenses:0,
        HoursWorked:0,
        CustomerOptions:'',
        ProjectOptions:'',
        DescriptionError: null,
        HoursWorkedError: null,
        LocationError: null,
      })
    }
  }

  clearForm(){
    const fields = ['TravelTime', 'Departure', 'Arrival', 'DistanceKM', 'FoodAllowance', 'TravelExpenses', 'FoodAllowance']
    fields.map(field => {
      ReactDOM.findDOMNode(this.refs[field]).value = null
    });
  }

  validate = () => {
    let isError = false;
    const errors = {
      DescriptionError: null,
      HoursWorkedError: null,
      LocationError: null,
    }

    if(!this.state.Description){
      isError = true;
      errors.DescriptionError = 'Enter description';
    }

    if(!this.state.Location && !this.props.user.location){
      isError = true;
      errors.LocationError = 'Enter location';
    }

    if(this.state.HoursWorked === null){
      isError = true;
      errors.HoursWorkedError = 'Enter hours';
    }
    this.setState({
      ...this.state,
      ...errors
    })
    return isError;
  }

  handleCustomerChange(event){
    let token = getToken()

    if(token){
      this.setState({[event.target.id]: event.target.value})

      const header = {
        headers: { Authorization: `Bearer ${token}` }
      };

      axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${event.target.value}`,header).then(response => {
        var items = []
        var list = []
        var selected = null
        for(var j = 0; j<response.data.length;j++){
          if(!selected)
            selected = response.data[j]
          list.push({key:response.data[j].Id,value:response.data[j].Name})
          items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
        }
        this.setState({
          Project_fk:selected.Id,
          Project_Name:selected.Name,
          ProjectOptions:items,
          ProjectOptionsArray:list
        })
      }).catch(error => {
        this.setState({
          Project_fk:null,
          ProjectOptions:<option >Error</option>,
          ProjectOptionsArray:null
        })
      });
    }
  }

  handleOnSubmit(){
    const err = this.validate()
    if(!err)
    {
      var cObj = this.state.CustomerOptionsArray.filter(element=> element.key === parseInt(this.state.Customer_fk))
      var pObj = this.state.ProjectOptionsArray.filter(element=> element.key === parseInt(this.state.Project_fk))
  
      var cName = cObj[0].value
      var pName = pObj[0].value

      if(this.state.Edit){  
        this.props.UpdateWorkRecords({
          Id:this.state.Id,
          Date: dateFormat(this.state.Date,"yyyy-mm-dd"), 
          Project_fk:this.state.Project_fk, 
          Description:this.state.Description, 
          Customer_fk:this.state.Customer_fk,
          TravelTime:this.state.TravelTime,
          Departure:this.state.Departure,
          Arrival:this.state.Arrival,
          DistanceKM:this.state.DistanceKM,
          FoodAllowance:this.state.FoodAllowance,
          TravelExpenses:this.state.TravelExpenses || 0,
          HoursWorked:this.state.HoursWorked,
          Location:(this.state.Location === '' ? this.props.user.location : this.state.Location),
          Customer_Name:cName,
          Project_Name:pName
        })
      }
      else{
        this.props.InsertWorkRecords({
          Date:dateFormat(this.state.Date,"yyyy-mm-dd"), 
          Project_fk:this.state.Project_fk, 
          Description:this.state.Description, 
          Customer_fk:this.state.Customer_fk,
          TravelTime:this.state.TravelTime,
          Departure:this.state.Departure,
          Arrival:this.state.Arrival,
          DistanceKM:this.state.DistanceKM,
          FoodAllowance:this.state.FoodAllowance,
          TravelExpenses:this.state.TravelExpenses || 0,
          HoursWorked:this.state.HoursWorked,
          Location:(this.state.Location === '' ? this.props.user.location : this.state.Location),
          Customer_Name:cName,
          Project_Name:pName
        })
      }
      this.props.ListWorkRecords()
      this.props.onHide()
    }
  }

  handleOnSubmitAndNext(){
    const err = this.validate()
    if(!err) {
      var cObj = this.state.CustomerOptionsArray.filter(element=> element.key === parseInt(this.state.Customer_fk))
      var pObj = this.state.ProjectOptionsArray.filter(element=> element.key === parseInt(this.state.Project_fk))

      var cName = cObj[0].value
      var pName = pObj[0].value

      this.props.InsertWorkRecords({
        Date:dateFormat(this.state.Date,"yyyy-mm-dd"), 
        Project_fk:this.state.Project_fk, 
        Description:this.state.Description, 
        Customer_fk:this.state.Customer_fk,
        TravelTime:this.state.TravelTime,
        Departure:this.state.Departure,
        Arrival:this.state.Arrival,
        DistanceKM:this.state.DistanceKM,
        FoodAllowance:this.state.FoodAllowance,
        TravelExpenses:this.state.TravelExpenses || 0,
        HoursWorked:this.state.HoursWorked,
        Location:(this.state.Location === '' ? this.props.user.location : this.state.Location),
        Customer_Name:cName,
        Project_Name:pName
      })

      this.props.ListWorkRecords()
      
      let nextDate = new Date(this.state.Date)
      nextDate.setDate(nextDate.getDate() + 1)
      ReactDOM.findDOMNode(this.refs['Date']).value = dateFormat(nextDate, "yyyy-mm-dd")

      this.setState({
        Date:nextDate,
        TravelTime:0,
        Departure:'',
        Arrival:'',
        DistanceKM:null,
        FoodAllowance:0,
        TravelExpenses:0,
        privateCar:false
      })
      this.clearForm()
    }
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})

    if(e.target.id === 'privateCar'){
      if(e.target.checked)
        this.setState({[e.target.id]: e.target.checked,TravelExpenses:(this.state.DistanceKM*config.travelExpenses.mileageAllowance)})
      else
        this.setState({[e.target.id]: e.target.checked,TravelExpenses:0})
    }
    
    if(e.target.id === 'Departure' || e.target.id === 'Arrival'){
      let absence = DERgetTimeDifference(e.target.id === 'Departure' ? e.target.value :this.state.Departure,e.target.id === 'Arrival' ? e.target.value : this.state.Arrival)
      if(absence.hours >= 8 && absence.minutes >= 0 && absence.hours <= 23 && absence.minutes < 59 )
        this.setState({FoodAllowance:config.travelExpenses.foodAllowanceSmall})
      else if(absence.hours >= 23 && absence.minutes >= 59)
        this.setState({FoodAllowance:config.travelExpenses.foodAllowanceBig})
      else
      this.setState({FoodAllowance:0})
    }

    if(this.state.privateCar && (e.target.id === 'DistanceKM')){
      this.setState({TravelExpenses:(e.target.value*config.travelExpenses.mileageAllowance)})
    }
  }

  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      dialogAs={DraggableModalDialog} 
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Record')}  <b>{t('Working Time')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group controlId="Date" as={Col}>
              <Form.Label>{t('Date')}</Form.Label>
              <Form.Control 
                ref={'Date'}
                onChange={this.handleOnChange} 
                defaultValue={this.props.thisWork ? dateFormat(this.props.thisWork.Date,"yyyy-mm-dd") : dateFormat(Date.now(),"yyyy-mm-dd")} 
                type="date"/>
            </Form.Group>

            <Form.Group controlId="Location" as={Col}>
              <Form.Label>{t('Location')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} defaultValue={this.state.Location} type="text" isInvalid={(this.state.LocationError)} />
              <Form.Control.Feedback type="invalid">
                {this.state.LocationError}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>  
            <Form.Group controlId="HoursWorked" as={Col}>
              <Form.Label>{t('Work hours')}</Form.Label>
              <Form.Control onChange={this.handleOnChange}  defaultValue={this.state.HoursWorked} type="number" placeholder="0" isInvalid={(this.state.HoursWorkedError)} />
              <Form.Control.Feedback type="invalid">
                {this.state.HoursWorkedError}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="TravelTime" as={Col}>
              <Form.Label>{t('Travel time')}</Form.Label>
              <Form.Control ref={'TravelTime'} onChange={this.handleOnChange} defaultValue={this.state.TravelTime} type="number" placeholder="0" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group controlId="Customer_fk" as={Col}>
              <Form.Label>{t('Customer')}</Form.Label>
              <Form.Control defaultValue={this.state.Customer_fk} onChange={this.handleCustomerChange.bind(this)} as="select" >
                {this.state.CustomerOptions}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="Project_fk" as={Col}>
              <Form.Label>{t('Project')}</Form.Label>
              <Form.Control defaultValue={this.state.Project_fk} onChange={this.handleOnChange} as="select" >
                {this.state.ProjectOptions}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="Description" >
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control as="textarea" onChange={this.handleOnChange} defaultValue={this.state.Description} type="text" placeholder={t('Description')} isInvalid={(this.state.DescriptionError)}/>
            <Form.Control.Feedback type="invalid">
              {this.state.DescriptionError}
            </Form.Control.Feedback>
          </Form.Group>
          <h5>{t('Field service')}</h5>
          <Form.Row>
            <Form.Group controlId="Departure" as={Col}>
              <Form.Label>{t('Departure')}</Form.Label>
              <Form.Control ref={'Departure'} onChange={this.handleOnChange} defaultValue={this.state.Departure} type="time" />
            </Form.Group>

            <Form.Group controlId="Arrival" as={Col}>
              <Form.Label>{t('Arrival')}</Form.Label>
              <Form.Control ref={'Arrival'} onChange={this.handleOnChange} defaultValue={this.state.Arrival} type="time" />
            </Form.Group>

            <Form.Group controlId="DistanceKM" as={Col}>
              <Form.Label>{t('Distance')}</Form.Label>
              <Form.Control ref={'DistanceKM'} onChange={this.handleOnChange} defaultValue={this.state.DistanceKM} type="number" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <fieldset>
              <Form.Group onChange={this.handleOnChange}>
                <Form.Label as="legend" column sm={2}>
                  {t('Vehicle')}
                </Form.Label>
                <Col sm={10}>
                <Form.Check 
                  type="switch"
                  id="privateCar"
                  label={t('privateCar')}
                  checked={this.state.privateCar}
                  />
                </Col>
              </Form.Group>
            </fieldset>
            
            <Form.Group controlId="TravelExpenses" as={Col}>
              <Form.Label>{t('Travel expenses')}</Form.Label>
              <Form.Control ref={'TravelExpenses'} readOnly onChange={this.handleOnChange} value={this.state.TravelExpenses} type="number" />
            </Form.Group>

            <Form.Group controlId="FoodAllowance" as={Col}>
              <Form.Label>{t('Food Allowance')}</Form.Label>
              <Form.Control ref={'FoodAllowance'} onChange={this.handleOnChange} value={this.state.FoodAllowance} type="number" />
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        {!this.state.Edit ? <><Button onClick={this.handleOnSubmitAndNext} variant="secondary" >{t('saveNext')}</Button>{' '}</> : '' }
        <Button onClick={this.handleOnSubmit} variant="primary" >{t('saveClose')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    thisWork: state.thisWorkRecord ? state.thisWorkRecord[0] : null,
    workModalShow: state.workRecordModalShow,
  };
};

export default connect(mapStateToProps,{InsertWorkRecords, UpdateWorkRecords, ListWorkRecords})(withTranslation()(WorkModal));