import axios from 'axios';

import jsPDF from 'jspdf'
import 'jspdf-autotable'

import { getToken } from './Common';
import config from "../config/Settings.json"

function setHeaderFooter(pdfObject,employee){
  var number_of_pages = pdfObject.internal.getNumberOfPages()
  var pdf_pages = pdfObject.internal.pages

  var myFooter = "Seite "
  for (var i = 1; i < pdf_pages.length; i++) {
      pdfObject.setPage(i)
      let pageHeight = pdfObject.internal.pageSize.height;
      pdfObject.addImage(config.pdf.logo, 'png', 10, 5, 60, 20);
      pdfObject.setFontSize(6)
      pdfObject.text(myFooter + i + " von " + number_of_pages + "   " + employee, 10, pageHeight - 10)
  }
  return pdfObject
}

export const sendTimeSheetPDF = (Id,name,username) => {
  let token = getToken()
  if(token) {
    axios.get(`${window._env_.REACT_APP_SERVICE_API}/sheet/time/${Id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(response => {
        var columns = [
          {title: "Datum",dataKey:"Datum"},
          {title: "Ort",dataKey:"Ort"},
          {title: "Von",dataKey:"Von"},
          {title: "Bis",dataKey:"Bis"},
          {title: "AStd",dataKey:"AStd"},
          {title: "RStd",dataKey:"RStd"},
          {title: "Kunde",dataKey:"Kunde"},
          {title: "Projekt",dataKey:"Projekt"},
          {title: "Tätigkeit",dataKey:"Tätigkeit"},
          {title: "kM",dataKey:"kM"},
          {title: "VSatz",dataKey:"VSatz"},
          {title: "BelNr",dataKey:"BelNr"},
          {title: "Art",dataKey:"Art"},
          {title: "Betrag",dataKey:"Betrag"} 
        ]
        var signature = response.data.signature[0]
        var rows = response.data.data

        const countHoursWorked = rows.reduce((acc, cur) => cur.AStd !== null ? acc += cur.AStd : acc, 0);
        const countTravelTime = rows.reduce((acc, cur) => cur.RStd !== null ? acc += cur.RStd : acc, 0);
        const countDistance = rows.reduce((acc, cur) => cur.kM !== null ? acc += cur.kM : acc, 0);
        const countTravelExpenses = rows.reduce((acc, cur) => cur.VSatz !== null ? acc += cur.VSatz : acc, 0);
        const countExpenses = rows.reduce((acc, cur) => cur.Betrag !== null ? acc += cur.Betrag : acc, 0);

        var footer = {Datum:"Summe",AStd:countHoursWorked, RStd:countTravelTime, kM:countDistance, VSatz:countTravelExpenses.toFixed(2), Betrag:countExpenses.toFixed(2)}
        rows.push(footer)

        const RobotoLight = '../fonts/Roboto-Light.ttf'

        var document = new jsPDF({unit:"mm",format:"a4",orientation:"l"});
        document.addFileToVFS("../fonts/Roboto-Light.ttf", RobotoLight);
        document.addFont("../fonts/Roboto-Light.ttf", "Roboto-Light", "normal");
      
        document.setProperties({
          title: 'Timesheet',
          subject: 'Firstattribute AG Timesheet',
          author: 'FA Project Tracking',
          keywords: 'generated, node js, express js, jsPDF',
          creator: 'dennis.rupp@firstattribute.com'
        });
      
        document.setFontSize(6)
        document.setTextColor(255,170,0);
        document.text(20, 40, config.company.name);
      
        document.setTextColor(0,0,0);
        document.text(37, 40, '| ' + config.company.streetAddress + ' | ' + config.company.postalCode + ' ' + config.company.location);
      
        document.setFontSize(10)
        document.text(20,45, config.company.name)
        document.text(20,50, config.company.streetAddress)
        document.text(20,55,  config.company.postalCode + ' ' + config.company.location)

        document.setFontSize(14)
        document.text(15,70, "Arbeitszeitnachweis")

        document.autoTable({
          styles: { 
             fontSize: 7
          },
          headStyles: { fillColor: [100,100,100] },
          margin: {top: 40, bottom: 20},
          startY: 80,
          columns: columns,
          body: rows
        })

        let pageHeight = document.internal.pageSize.height;
        let finalY = document.lastAutoTable.finalY
        if (finalY+60>=pageHeight){
          document.addPage()
          finalY = 30
        }

        document.setFontSize(10)

        var line1 = `Erstattung Auslagen: ${countExpenses.toFixed(2)} EUR`
        var line2 = `Verpflegungsmehraufwand: ${countTravelExpenses.toFixed(2)} EUR`
        var line3 = `Erstattung Gesamt: ${(countTravelExpenses + countExpenses).toFixed(2)} EUR`
        var all = ["Zusammenfassung", line1, line2, line3]

        var sign = ['_________________________________', 'Datum / Unterschrift']
        document.text(20, finalY + 10, all)
        document.text(190, finalY + 40, sign)
        if(signature.signature){
          let base64Sign = signature.signature
          let typeSign = base64Sign.split(';')[0].split('/')[1];

          document.addImage(base64Sign, typeSign, 195, finalY + 15, 60, 30);
          document.setFontSize(6)
          document.text(195, finalY + 35, signature.signatureTimestamp)
          document.text(195, finalY + 37, signature.mail)
        }

        if(rows){
          for(var j = 0; j<rows.length;j++){
            if(rows[j].FileLink){
              var supported_image_types = ['jpeg', 'jpg', 'png'];
              let base64 = rows[j].FileLink
              let type = base64.split(';')[0].split('/')[1];
              if(supported_image_types.indexOf(type) > -1 && base64){
                document.addPage("a4", "p")
                document.setFontSize(14)
                document.text(15,40, "Belegnummer: " + rows[j].BelNr)
                document.addImage(base64, type, 15, 50)
              }
            }
          }
          document = setHeaderFooter(document,username)

          const token = getToken();
          if(token){
            let attachment = document.output('datauristring')
            axios.post(`${window._env_.REACT_APP_SERVICE_API}/sheet/time/${Id}/mailsender`, {attachment}, {
              headers: {
                'Authorization': `Bearer ${token}`
              }}).then((res) => {
              if(res.status === 'ok') console.log("Yeah!");
              else console.log(":(");
            });
          }
        }
        else{
          document = setHeaderFooter(document,username)
          const token = getToken();
          if(token){
            let attachment = document.output('datauristring')
            axios.post(`${window._env_.REACT_APP_SERVICE_API}/sheet/time/${Id}/mailsender`, {attachment}, {
              headers: {
                'Authorization': `Bearer ${token}`
              }}).then((res) => {
              if(res.status === 'ok') console.log("Yeah!");
              else console.log(":(");
            });
          }
        }
    }).catch(error =>{
      console.log(error)
    })
  }
}
export const createTimeSheetPDF = (Id,name,username,output) => {
  let token = getToken()
  if(token) {
    axios.get(`${window._env_.REACT_APP_SERVICE_API}/sheet/time/${Id}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(response => {
        var columns = [
          {title: "Datum",dataKey:"Datum"},
          {title: "Ort",dataKey:"Ort"},
          {title: "Von",dataKey:"Von"},
          {title: "Bis",dataKey:"Bis"},
          {title: "AStd",dataKey:"AStd"},
          {title: "RStd",dataKey:"RStd"},
          {title: "Kunde",dataKey:"Kunde"},
          {title: "Projekt",dataKey:"Projekt"},
          {title: "Tätigkeit",dataKey:"Tätigkeit"},
          {title: "kM",dataKey:"kM"},
          {title: "VSatz",dataKey:"VSatz"},
          {title: "BelNr",dataKey:"BelNr"},
          {title: "Art",dataKey:"Art"},
          {title: "Betrag",dataKey:"Betrag"} 
        ]
        var signature = response.data.signature[0]
        var rows = response.data.data

        const countHoursWorked = rows.reduce((acc, cur) => cur.AStd !== null ? acc += cur.AStd : acc, 0);
        const countTravelTime = rows.reduce((acc, cur) => cur.RStd !== null ? acc += cur.RStd : acc, 0);
        const countDistance = rows.reduce((acc, cur) => cur.kM !== null ? acc += cur.kM : acc, 0);
        const countTravelExpenses = rows.reduce((acc, cur) => cur.VSatz !== null ? acc += cur.VSatz : acc, 0);
        const countExpenses = rows.reduce((acc, cur) => cur.Betrag !== null ? acc += cur.Betrag : acc, 0);

        var footer = {Datum:"Summe",AStd:countHoursWorked, RStd:countTravelTime, kM:countDistance, VSatz:countTravelExpenses.toFixed(2), Betrag:countExpenses.toFixed(2)}
        rows.push(footer)

        const RobotoLight = '../fonts/Roboto-Light.ttf'

        var document = new jsPDF({unit:"mm",format:"a4",orientation:"l"});
        document.addFileToVFS("../fonts/Roboto-Light.ttf", RobotoLight);
        document.addFont("../fonts/Roboto-Light.ttf", "Roboto-Light", "normal");
      
        document.setProperties({
          title: 'Timesheet',
          subject: 'Firstattribute AG Timesheet',
          author: 'FA Project Tracking',
          keywords: 'generated, node js, express js, jsPDF',
          creator: 'dennis.rupp@firstattribute.com'
        });
      
        document.setFontSize(6)
        document.setTextColor(255,170,0);
        document.text(20, 40, config.company.name);
      
        document.setTextColor(0,0,0);
        document.text(37, 40, '| ' + config.company.streetAddress + ' | ' + config.company.postalCode + ' ' + config.company.location);
      
        document.setFontSize(10)
        document.text(20,45, config.company.name)
        document.text(20,50, config.company.streetAddress)
        document.text(20,55,  config.company.postalCode + ' ' + config.company.location)

        document.setFontSize(14)
        document.text(15,70, "Arbeitszeitnachweis")

        document.autoTable({
          styles: { 
             fontSize: 7
          },
          headStyles: { fillColor: [100,100,100] },
          margin: {top: 40, bottom: 20},
          startY: 80,
          columns: columns,
          body: rows
        })

        let pageHeight = document.internal.pageSize.height;
        let finalY = document.lastAutoTable.finalY
        if (finalY+60>=pageHeight){
          document.addPage()
          finalY = 30
        }

        document.setFontSize(10)

        var line1 = `Erstattung Auslagen: ${countExpenses.toFixed(2)} EUR`
        var line2 = `Verpflegungsmehraufwand: ${countTravelExpenses.toFixed(2)} EUR`
        var line3 = `Erstattung Gesamt: ${(countTravelExpenses + countExpenses).toFixed(2)} EUR`
        var all = ["Zusammenfassung", line1, line2, line3]

        var sign = ['_________________________________', 'Datum / Unterschrift']
        document.text(20, finalY + 10, all)
        document.text(190, finalY + 40, sign)
        if(signature.signature){
          let base64Sign = signature.signature
          let typeSign = base64Sign.split(';')[0].split('/')[1];

          document.addImage(base64Sign, typeSign, 195, finalY + 15, 60, 30);
          document.setFontSize(6)
          document.text(195, finalY + 35, signature.signatureTimestamp)
          document.text(195, finalY + 37, signature.mail)
        }

        if(rows){
          for(var j = 0; j<rows.length;j++){
            if(rows[j].FileLink){
              var supported_image_types = ['jpeg', 'jpg', 'png'];
              let base64 = rows[j].FileLink
              let type = base64.split(';')[0].split('/')[1];
              if(supported_image_types.indexOf(type) > -1 && base64){
                document.addPage("a4", "p")
                document.setFontSize(14)
                document.text(15,40, "Belegnummer: " + rows[j].BelNr)
                document.addImage(base64, type, 15, 50)
              }
            }
          }
          document = setHeaderFooter(document,username)

          if (output)
            return document.output(output)
          else
            return document.save(name)
        }
        else{
          document = setHeaderFooter(document,username)
          if (output)
            return document.output(output)
          else
            return document.save(name)
        }
    }).catch(error =>{
      console.log(error)
    })
  }
}