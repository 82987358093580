import React from 'react';
import axios from 'axios';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';

import './Login.css'

class CodeVerification extends React.Component{

  constructor(props){
    super(props)
    this.state = {
      formBasicEmail: '',
      formBasicEmailError: '',
      verificationCode: '',
      verificationCodeError: ''
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.submitButton = this.submitButton.bind(this);
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  submitButton(){
    if(this.state.formBasicEmail && this.state.verificationCode){
      axios.post(`${window._env_.REACT_APP_SERVICE_API}/auth/reset/${this.state.formBasicEmail}`, { token: this.state.verificationCode }).then(response => {
        this.props.history.push({pathname:'/resetPassword',authProps:{
          oAuthProvider:'custom',
          guid:response.data.guid,
          verificationCode:this.state.verificationCode,
          mail:this.state.formBasicEmail
        }})
      }).catch(error => {
        const errors = {
          verificationCodeError: 'Invailid'
        }
        this.setState({
          ...this.state,
          ...errors
        })
      });
    }
  }

  render(){
    return(
      <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className={"d-none d-md-flex col-md-4 col-lg-4 bg-image"}></div>
        <Col md={8} lg={8}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                 <Card.Body>
                    <Card.Text>
                      <h4>Verifizierung</h4>
                      bitte gebe deinen zugesendeten Code ein
                    </Card.Text>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control 
                          type="text" 
                          placeholder="Enter mailaddress" 
                          value={this.state.formBasicEmail}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.formBasicEmailError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.formBasicEmailError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group controlId="verificationCode">
                        <Form.Control 
                          type="text" 
                          placeholder="Enter code" 
                          value={this.state.verificationCode}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.verificationCodeError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.verificationCodeError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button 
                        variant="primary"
                        onClick={this.submitButton} >
                        Absenden
                      </Button>
                    </Form>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    );
  }
}

export default (CodeVerification);