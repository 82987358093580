import { LIST_TIMESHEETS, INSERT_TIMESHEET, REMOVE_TIMESHEET, UPDATE_TIMESHEET } from "../Constants/action-types";
import { SIGN_TIMESHEET, SHOW_SIGNATUREBOARD, HIDE_SIGNATUREBOARD } from "../Constants/action-types";

import { getToken } from '../../utils/Common';
import { sendTimeSheetPDF } from "../../utils/PDF"
import config from '../../config/Settings.json';

export function ListTimeSheets(start,count){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/sheet/time',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"start":start,"count":count})
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        var list = []
        for(var j = 0; j<json.length;j++){
          list.push(json[j].body)
        }
        if(json) {
          return dispatch({ type: LIST_TIMESHEETS, payload: list });
        }
      });
    }
  }
}

export function InsertTimeSheet(obj){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'PUT',
        'url': window._env_.REACT_APP_SERVICE_API + '/sheet/time',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "state":'prepared',
          "from":obj.dateFrom || null,
          "to":obj.dateTo || null,
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json.insertId){
          obj.id = json.insertId
          obj.state = 'prepared'
          obj.from = obj.dateFrom
          obj.to = obj.dateTo
          return dispatch({ type: INSERT_TIMESHEET, payload: obj });
        }
      });
    }
  }
}

export function RemoveTimeSheet(id){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'DELETE',
        'url': window._env_.REACT_APP_SERVICE_API + '/sheet/time/' + id,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        return dispatch({ type: REMOVE_TIMESHEET, payload: id });
      });
    }
  }
}

export function RequestTimeSheet(id,filename,username) {
  return function(dispatch){
    sendTimeSheetPDF(id,filename,username)
    var obj = {
      id:id,
      state:'requested'
    }
    return dispatch({ type: UPDATE_TIMESHEET, payload: obj });
  }
}

export function SignTimeSheet(Id){
  return function(dispatch){
    return dispatch({type: SIGN_TIMESHEET, payload: Id})
  }
}

export function ShowSignTimeSheet(Id){
  return function(dispatch){
    return dispatch({type: SHOW_SIGNATUREBOARD, payload: Id})
  }
}

export function HideSignTimeSheet(){
  return function(dispatch){
    return dispatch({type: HIDE_SIGNATUREBOARD, payload: false})
  }
}