import React, { Component } from 'react';

import { connect } from "react-redux";
import { ShowSpinner, UpdateUser } from "../../../store/Actions/index";

import { Modal, Button, Form } from 'react-bootstrap';

import { withTranslation } from 'react-i18next';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'; 

class ImageCropper extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectedFile:null,
      imageFull:null,
      imageMedium:null,
      imageSmall:null,
      cropped:false,
      src: null,
      aspect:1/1,
    }

    this.handleImageChange = this.handleImageChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.show && !this.props.show){

      let aspect = 1 / 1
      if(nextProps.attribute === 'background'){
        aspect = 3 / 1
      }

      this.setState({
        selectedFile:null,
        imageFull:null,
        imageMedium:null,
        imageSmall:null,
        cropped:false,
        src: null,
        aspect:aspect,
      })
    }
  }

  _crop() {
    this.setState({
      imageFull:this.cropper.getCroppedCanvas().toDataURL("image/jpeg"),
      imageMedium:this.cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.5),
      imageSmall:this.cropper.getCroppedCanvas().toDataURL("image/jpeg", 0.1),
      cropped:true
    })
  }

  onCropperInit(cropper) {
    this.cropper = cropper;
  }

  handleImageChange(e){
    e.preventDefault();
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      this.setState({
        src: reader.result,
        selectedFile: file.name
      });
    };
  }


  handleOnSubmit(){
    let obj = {
      guid:this.props.guid,
      photo:this.state.imageFull,
      photoSmall:this.state.imageSmall,
      photoMedium:this.state.imageMedium,
      background:this.state.imageFull,
    }

    if(this.props.attribute === 'background'){
      obj.photo = null
      obj.photoSmall = null
      obj.photoMedium = null
    }
    else 
      obj.background = null

    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key])
    this.props.UpdateUser(obj)
    this.props.onHide()
  }

  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Image')} <b>{t('Change')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="FileLink" >
          <Form.Label>{t('sentences.selectFile')}</Form.Label>
              <Form.File 
                id="custom-file"
                label={this.state.selectedFile ? this.state.selectedFile : t('fileInput')}
                custom
                onChange={this.handleImageChange}
              />
              <Form.Text className="text-muted">
              {t('sentences.maxUpload')}
              </Form.Text>
          </Form.Group>
          {this.state.src && (
            <Cropper
              src={this.state.src}
              style={{height: 400, width: '100%'}}
              initialAspectRatio={this.state.aspect}
              aspectRatio={this.state.aspect}
              guides={false}
              crop={this._crop.bind(this)}
              onInitialized={this.onCropperInit.bind(this)}
              responsive={true}
              background={true}
          /> )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        <Button disabled={!this.state.cropped} onClick={this.handleOnSubmit} variant="primary" >{t('Save')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

export default connect(null,{ShowSpinner, UpdateUser})(withTranslation()(ImageCropper));