import React from 'react'
import { connect } from "react-redux"
import { SelectCustomer } from "../../../../store/Actions/index"
import { Col,Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { withTranslation } from 'react-i18next'
import Avatar from '../../../Avatar/Avatar'

const CustomerTableRow = props => {

  const {t} = props

  const renderMembers = (members) => {
    var avatars = []
    let moveLeft = 10
    let zIndex = 100
    members.forEach( function(attendee, index) {
      if(index < 6) {
        avatars.push(
          <Avatar
            key={props.Id+'-'+attendee.guid}
            guid={attendee.guid}
            name={attendee.name}
            zIndex={zIndex}
            index={index}
            left={moveLeft}
          />
        )
      }
      else if(index +1 === members.length)
      avatars.push(<span style={{fontSize:'34px', position: "absolute", left: `${moveLeft+15}px`}}>+ {members.length - 5}</span>)
      moveLeft += 15
    })
    return avatars
  }

  const renderProjects = (projects) => {
    var pendingProjects = projects.filter((project) => {return project.ProjectState === 'pending'})
    var activeProjects = projects.filter((project) => {return project.ProjectState === 'active'})  
    var verifiedProjects = projects.filter((project) => {return project.ProjectState === 'verified'})
    var failedProjects = projects.filter((project) => {return project.ProjectState === 'failed'})
    var closedProjects = projects.filter((project) => {return project.ProjectState === 'closed'})
    return(
      <Row className="text-center" style={{fontSize: "10px"}}>
        {pendingProjects.length > 0 && (
          <Col className="text-info">
            {pendingProjects.length}<br/>
            {t('pending')}
          </Col>
        )}
        {verifiedProjects.length > 0 && (
          <Col className="text-warning">
            {verifiedProjects.length}<br/>
            {t('verified')}
          </Col>
        )}
        {activeProjects.length > 0 && (
          <Col className="text-primary">
            {activeProjects.length}<br/>
            {t('active')}
          </Col>
        )}
        {closedProjects.length > 0 && (
          <Col className="text-success">
            {closedProjects.length}<br/>
            {t('closed')}
          </Col>
        )}
        {failedProjects.length > 0 && (
          <Col className="text-danger">
            {failedProjects.length}<br/>
            {t('failed')}
          </Col>
        )}
      </Row>
    )
  }

  return(
    <tr key={props.Id} onDoubleClick={() => props.History.push('/customer/'+props.Id)}>
      <td>{props.Name}</td>
      <td style={{position: "relative"}} >{props.Members && renderMembers(props.Members)}</td>
      <td className='d-none d-md-none d-lg-table-cell' >{props.Projects && (renderProjects(props.Projects))}</td>
      <td className='d-none d-md-none d-lg-table-cell' >{props.Location}</td>
      <td className='d-none d-md-none d-lg-table-cell' >{props.CustomerNumber}</td>
      <td>
        <FontAwesomeIcon onClick={() => props.History.push('/customer/'+props.Id)} className={"text-info"} style={{fontSize: "20px"}} icon={faEdit} />
      </td>
    </tr>
  );
}

export default connect(null,{SelectCustomer})(withTranslation()(CustomerTableRow))