import React, { useState, useEffect, useRef }  from 'react';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux"
import { ShowSpinner, GetKanban, AddKanbanMember, RemoveKanbanMember, UpdateKanban, DestroyKanbanBoardStore, RemoveKanban } from "../../store/Actions/index"
import { Row, Col, Form, InputGroup, Button } from 'react-bootstrap'
import Avatar from '../Avatar/Avatar'
import { withTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash} from '@fortawesome/free-solid-svg-icons'
import KanbanBoard from './components/KanbanBoard'
import AddMemberOverlay from './components/AddMemberOverlay'

const Kanban = (props) => {

  const history = useHistory()
  const boardRef = useRef();
  const [data, setData ] = useState({
    title:null,
  })

  const [edit, setEdit ] = useState({
    edit:false
  })

  const [showCompleted, setShowCompleted ] = useState(false)

  useEffect(() => {
    let id = props.match.params.urlPath
    props.ShowSpinner()
    props.GetKanban(id)
    document.addEventListener('mousedown', handleOnClickOutside)

    return () => {
      props.DestroyKanbanBoardStore()
      document.removeEventListener('mousedown', handleOnClickOutside)
    }

  },[props.match.params.urlPath])

  const handleOnRemoveBoard = () => {
    props.ShowSpinner()
    props.RemoveKanban(this.props.kanbanBoard.id)
    props.DestroyKanbanBoardStore()
    history.push('/planner')
  }

  const handleOnClickName = () => {
    setEdit(prevEdit => ({
      ...prevEdit,
      edit: true
    }))
  }

  const handleOnClickOutside = (event) => {
    if (boardRef && !boardRef.current.contains(event.target)) {
      if(data.title){
        props.UpdateKanban({id:props.kanbanBoard.id, title:data.title})

        setEdit(prevEdit => ({
          ...prevEdit,
          edit: false
        }))

        setData(prevData => ({
          ...prevData,
          title: null
        }))
      }
      else{
        setEdit(prevEdit => ({
          ...prevEdit,
          edit: false
        }))

        setData(prevData => ({
          ...prevData,
          title: null
        }))
      }
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(data.title){
        props.UpdateKanban({id:props.kanbanBoard.id, title:edit.title})
        setEdit(prevEdit => ({
          ...prevEdit,
          edit: false
        }))

        setData(prevData => ({
          ...prevData,
          title: null
        }))
      }
    }
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    console.log(e.target)
    setData(prevData => ({
        ...prevData,
        [id]: value
    }))
  }

  const handleOnAddMember = (e) => {
    props.AddKanbanMember(props.kanbanBoard.id,e.id)
  }

  const handleOnRemoveMember = (e) => {
    props.RemoveKanbanMember(props.kanbanBoard.id,e.guid)
  }

  const renderProjectMembers = (members) => {
    var avatars = []
    let moveLeft = 10
    let zIndex = 100
    members.forEach( function(attendee, index) {
      avatars.push(<Avatar
        height={35}
        width={35}
        key={attendee.guid}
        guid={attendee.guid}
        name={attendee.name}
        zIndex={zIndex}
        index={index}
        left={moveLeft}
        align={'right'}
        placement={'top'}
      />)
      moveLeft += 15
    })
    return avatars
  }



  const {t} = props
  return(<>
    {props.kanbanBoard && (      
      <>
    <Row className={"table-title"}>
      <Col xs={8} ref={boardRef}>
        {edit.edit && props.kanbanBoard.class === 'personally' ?
          <Form.Group>
            <InputGroup>
              <Form.Control id="title" type={'text'} onChange={handleOnChange} defaultValue={props.kanbanBoard.title} onKeyDown={handleKeyDown} />
              <InputGroup.Append>
                <Button onClick={handleOnRemoveBoard} variant="outline-secondary" style={{border:'none'}}><FontAwesomeIcon icon={faTrash} /></Button>
              </InputGroup.Append>
            </InputGroup>
          </Form.Group>
        : 
          <h2>{t('Planner')} <b onClick={handleOnClickName}>{props.kanbanBoard.title}</b></h2> 
        }
      </Col>
      <Col xs={3}>
          {props.kanbanBoard.members && (renderProjectMembers(JSON.parse(props.kanbanBoard.members)))}
      </Col>
      <Col xs={1} className={'text-right'}>
        {props.kanbanBoard.class === 'personally' && (
          <AddMemberOverlay
            key={props.kanbanBoard.id}
            members={JSON.parse(props.kanbanBoard.members)}
            handleOnAdd={handleOnAddMember}
            handleOnRemove={handleOnRemoveMember}
          />
        )}
      </Col>
    </Row>
    <Row>
      <Col xs={7} className={'text-left'}>
        <Form.Switch
          onChange={()=>setShowCompleted(!showCompleted)}
          id="custom-switch"
          label={t('Show completed tasks')}
        />
      </Col>
    </Row>
    <KanbanBoard 
      kanbanId={props.kanbanBoard.id}
      showCompleted={showCompleted}
    />
    </>)}</>
  )
}

const mapStateToProps = state => {
  return {
    kanbanBoard: state.kanbanBoard,
  };
};

export default connect(mapStateToProps,{ ShowSpinner, GetKanban, AddKanbanMember, RemoveKanbanMember, UpdateKanban, DestroyKanbanBoardStore, RemoveKanban })(withTranslation()(Kanban));