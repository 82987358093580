import React from 'react';
import './Charts.scss'

function compareNumbers(a, b) {
  return a - b;
}

const Charts = (props) => {

  var self = props
  var data = props.data
  var dataLabel = props.dataLabel
  var layered = props.grouping === 'layered' ? true : false
  var stacked = props.grouping === 'stacked' ? true : false
  var opaque = props.opaque
  var max = 0;
  
  for (var i = data.length; i--; ) {
    for (var j = data[i].length; j--; ) {
      if (data[i][j] > max) {
        max = data[i][j];
      }
    }
  }
  return (
    <div className={ 'Charts' + (props.horizontal ? ' horizontal' : '' ) }>
      { data.map(function (serie, serieIndex) {
        var sortedSerie = serie.slice(0),
          sum;
        
        sum = serie.reduce(function (carry, current) {
          return carry + current;
        }, 0);
        sortedSerie.sort(compareNumbers);				 		
                  
        return (
          <div className={ 'Charts--serie ' + (self.grouping) }
            key={ serieIndex }
            style={{ height: self.height ? self.height: 'auto' }}
          >
          <label>{ self.labels[serieIndex] }</label>
          { serie.map(function (item, itemIndex) {
            var color = self.colors[itemIndex], style,
              size = item / (stacked ? sum : max) * 100;
            
            style = {
              backgroundColor: color,
              opacity: opaque ? 1 : (item/max + .05),
              zIndex: item
            };
          
            if (self.horizontal) {
              style['width'] = size + '%';
            } else {								
              style['height'] = size + '%';						
            }

            if (layered && !self.horizontal) {
              style['right'] = ((sortedSerie.indexOf(item) / (serie.length + 1)) * 100) + '%';
              // style['left'] = (itemIndex * 10) + '%';
            }
          
            return (
              <div
              className={ 'Charts--item ' + (self.grouping) }
              style={ style }
              key={ itemIndex }
            >
              <b style={{ color: color }}>{ item }</b><br></br><b style={{ color: '#fff' }}>{ dataLabel[serieIndex][itemIndex] }</b>
              </div>
          );
          }) }
          </div>
        );
      }) }
    </div>
  );
}

export default Charts;