import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLandmark} from '@fortawesome/free-solid-svg-icons'

const CustomerCard = props => {
  return(
    <Col sm={12} lg={6} md={12}>
      <Card
      bg={'light'}
      border={props.Edit ? 'danger' : 'light'}
      text={'dark'}
      onClick={()=>{props.onClickCustomer(props.id)}}
      >
        <Card.Body>
          <Row>
            <Col sm={3} lg={3} md={3}>
              <FontAwesomeIcon className={"text-info"} style={{fontSize: "60px"}} icon={faLandmark}/>
            </Col>
            <Col className="text-left" sm={9} lg={9} md={9}>
              <Card.Text style={{ fontSize: '18px', margin : '0', whiteSpace: 'nowrap' }}><b>{props.name}</b></Card.Text>
              {props.address && <Card.Text style={{ margin : '0', whiteSpace: 'nowrap' }}>{props.address}</Card.Text>}
              {props.postalCode && <Card.Text style={{ margin : '0', whiteSpace: 'nowrap' }}>{props.postalCode} {props.location}</Card.Text>}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default CustomerCard