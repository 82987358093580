import React from 'react';
import { connect } from "react-redux"
import { ShowDialog, RemoveExpenses } from "../../../../store/Actions/index"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const dateFormat = require('dateformat');

const ExpensesTableRow = props => {
  const handleDeleteEntry = (event) => {
    props.RemoveExpenses(event)
  }

  return(
    <tr>
      <td>{props.VoucherNumber}</td>
      <td>{dateFormat(props.Date,"d. mmm yyyy")} </td>
      <td>{props.TotalCost > 0 ? props.TotalCost.toFixed(2) + "€" :  props.TotalCost}</td>
      <td>{props.Type}</td>
      <td>{props.Description}</td>
      <td><FontAwesomeIcon style={{color: "#d9534f", fontSize: "20px"}} icon={faTrash} onClick={()=>handleDeleteEntry(props.Id)} /></td>
    </tr>
  );
}

export default connect(null,{ShowDialog, RemoveExpenses})(ExpensesTableRow);