import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

class SessionExpired extends React.Component{
  constructor(props) {
    super(props);
    this.back = this.back.bind(this)
  }

  back(){
    this.props.history.push('/')
  }
  render(){
    const {t} = this.props
    return(
      <>
      <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className={"d-none d-md-flex col-md-4 col-lg-4 bg-image"}></div>
        <Col md={8} lg={8}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Col md={12} lg={12} xs={12} className={'text-center'}>
                    <h1>{t('session expired')}</h1>
                    <h4>{t('sentences.sessionExpired')}</h4>
                  </Col>
                  <Col md={12} lg={12} xs={12} className={'text-center'}>
                    <Button 
                      variant="primary"
                      onClick={this.back} >
                      {t('sentences.backHome')}
                    </Button>
                  </Col>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
      </>
    );
  }
}

export default withTranslation()(SessionExpired);