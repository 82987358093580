import React, { useState } from 'react';
import { connect } from "react-redux";
import { RemoveWorkRecords, InsertWorkRecords, UpdateWorkRecords, ListWorkRecords, SelectWorkRecord } from "../../../../store/Actions/index";
import axios from 'axios';
import config from '../../../../config/Settings.json'
import { getToken } from '../../../../utils/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faEdit, faTimesCircle, faCopy } from '@fortawesome/free-regular-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { Form, Col } from 'react-bootstrap';

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

const WorkTableRow = (props) => {
  const [data, setData ] = useState({
    ProjectOptions:'',
    CustomerOptions:'',
    ProjectOptionsArray: [],
    CustomerOptionsArray:[],
    Date:null,
    Customer_fk:null,
    Customer_Name:null,
    Project_fk:null,
    Project_Name:null,
    Description:null,
    HoursWorked:null,
    DescriptionError: null,
    HoursWorkedError: null,
    DateError: null,
  })

  const [edit, setEdit ] = useState({
    editEntry:false,
  })

  const validate = () => {
    let isError = false;
    const errors = {
      DescriptionError: null,
      HoursWorkedError: null,
      DateError:null,
    }

    if(data.Date === null){
      isError = true;
      errors.DateError = 'Enter Date';
    }

    if(data.Description.length < 1){
      isError = true;
      errors.DescriptionError = 'Enter description';
    }

    if(data.HoursWorked === null){
      isError = true;
      errors.HoursWorkedError = 'Enter hours';
    }

    setData(prevData => ({
      ...prevData,
      ...data,
      ...errors
    }));

    return isError;
  }

  const handleOnEditChange = () => {
    const token = getToken()
    if(token){
      const header = {
        headers: { Authorization: `Bearer ${token}` }
      };
  
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/customers`,header).then(response => {
        var items = []
        var list = []
        for(var j = 0; j<response.data.length;j++){
          list.push({key:response.data[j].Id,value:response.data[j].Name})
          items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
        }
        setData(prevData => ({
          ...prevData,
          CustomerOptions:items,
          CustomerOptionsArray:list,
        }));

        setEdit(prevData => ({
          ...prevData,
          editEntry: true
        }))
      }).catch(error => {
        setData(prevData => ({
          ...prevData,
          CustomerOptions:<option >Error</option>,
          CustomerOptionsArray:null
        }));

        setEdit(prevData => ({
          ...prevData,
          editEntry: true
        }))
      });

      axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${props.Record.Customer_fk}`,header).then(response => {
        var items = []
        var list = []
        for(var j = 0; j<response.data.length;j++){
          list.push({key:response.data[j].Id,value:response.data[j].Name})
          items.push(<option key={response.data[j].Id} value={response.data[j].Id} >{response.data[j].Name}</option>)
        }

        setData(prevData => ({
          ...prevData,
          ProjectOptions:items,
          ProjectOptionsArray:list
        }));
      }).catch(error => {
        setData(prevData => ({
          ...prevData,
          ProjectOptions:<option >Error</option>,
          ProjectOptionsArray:null,
        }));
      });
    }
  }

  const setEditEntry = (e) => {
    if(e===true){
      setData(prevData => ({
        ...prevData,
        Date:props.Record.Date, 
        Project_fk:props.Record.Project_fk, 
        Description:props.Record.Description, 
        Customer_fk:props.Record.Customer_fk,
        HoursWorked:props.Record.HoursWorked,
        Location:props.user.location,
        Customer_Name:props.Record.Customer_Name,
        Project_Name:props.Record.Project_Name,
        DescriptionError: null,
        HoursWorkedError: null,
        DateError: null,
      }));
      handleOnEditChange()
    }
    else{
      setData(prevData => ({
        ...prevData,
        Date:null, 
        Project_fk:null, 
        Description:null, 
        Customer_fk:null, 
        HoursWorked:null, 
        Location:null, 
        Customer_Name:null, 
        Project_Name:null, 
        DescriptionError: null,
        HoursWorkedError: null,
        DateError: null,
      }));
      setEdit(prevData => ({
        ...prevData,
        editEntry: e
      }))
    }
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }));
  }

  const handleDeleteEntry = (event) => {
    props.RemoveWorkRecords(event)
  }

  const handleOnUpdateEntry = () => {
    const err = validate()
    if(!err){
      var cObj = data.CustomerOptionsArray.filter(element=> element.key === parseInt(data.Customer_fk))
      var pObj = data.ProjectOptionsArray.filter(element=> element.key === parseInt(data.Project_fk))

      var CData = cObj[0].value
      var PData = pObj[0].value

      props.UpdateWorkRecords({
        Id:props.Record.Id,
        Date:dateFormat(data.Date,"yyyy-mm-dd"),
        Customer_fk:data.Customer_fk,
        Project_fk:data.Project_fk,
        Description:data.Description,
        HoursWorked:data.HoursWorked,
        Customer_Name:CData,
        Project_Name:PData,
      })
      setData(prevData => ({
        ...prevData,
        Customer_Name:CData,
        Project_Name:PData
      }));
      setEdit(prevData => ({
        ...prevData,
        editEntry: false
      }))
    }
  }

  const handleCopyEntry = () => {
    props.InsertWorkRecords({
      Date:dateFormat(props.Record.Date,"yyyy-mm-dd"), 
      Project_fk:props.Record.Project_fk, 
      Description:(props.Record.Description + " (copy)"), 
      Customer_fk:props.Record.Customer_fk,
      HoursWorked:props.Record.HoursWorked,
      Location: props.Record.Location ? props.Record.Location : props.user.location,
      Customer_Name:props.Record.Customer_Name,
      Project_Name:props.Record.Project_Name,
    })
    window.scrollTo(0, 0)
  }

  const handleCustomerChange = (e) => {
    const token = getToken()
    if(token){
      const { id, value } = e.target
      setData(prevData => ({
          ...prevData,
          [id]: value
      }));
      const header = {
        headers: { Authorization: `Bearer ${token}` }
      };
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${value}`,header).then(response => {
        var items = []
        var list = []
        var selected = null
        for(var j = 0; j<response.data.length;j++){
          if(!selected)
            selected = response.data[j]
          list.push({key:response.data[j].Id,value:response.data[j].Name})
          items.push(<option key={response.data[j].Id} value={response.data[j].Id} >{response.data[j].Name}</option>)
        }
        setData(prevData => ({
          ...prevData,
          Project_fk:selected.Id,
          Project_Name:selected.Name,
          ProjectOptions:items,
          ProjectOptionsArray:list
        }));
        handleOnChange({target: {id:'Customer_fk',value:value}})
      }).catch(error => {
        setData(prevData => ({
          ...prevData,
          Project_fk:null,
          ProjectOptions:<option >Error</option>,
          ProjectOptionsArray:null,
        }));
      });
    }
  }

  const renderEdit = () => {
    return(
      <tr>
        <td> 
          <Form.Group controlId="Date" >
            <Form.Control onChange={handleOnChange} type={'date'} defaultValue={dateFormat(props.Record.Date,"yyyy-mm-dd")} isInvalid={(data.DateError)}/>
            <Form.Control.Feedback type="invalid">
              {data.DateError}
            </Form.Control.Feedback>
          </Form.Group>
        </td>
        <td>
          <Form.Group controlId="Customer_fk" as={Col}>
            <Form.Control defaultValue={props.Record.Customer_fk} onChange={handleCustomerChange} as="select" >
              {data.CustomerOptions}
            </Form.Control>
          </Form.Group>
        </td>
        <td>
          <Form.Group controlId="Project_fk" as={Col}>
            <Form.Control defaultValue={props.Record.Project_fk} onChange={handleOnChange} as="select" >
              {data.ProjectOptions}
            </Form.Control>
          </Form.Group>
        </td>
        <td>
          <Form.Group controlId="Description" >
            <Form.Control onChange={handleOnChange} type={'text'} defaultValue={props.Record.Description} isInvalid={(data.DescriptionError)} />
            <Form.Control.Feedback type="invalid">
              {data.DescriptionError}
            </Form.Control.Feedback>
          </Form.Group>
        </td>
        <td>          
          <Form.Group controlId="HoursWorked" >
            <Form.Control onChange={handleOnChange} type={'number'} step={0.5} defaultValue={props.Record.HoursWorked} isInvalid={(data.HoursWorkedError)}/>
            <Form.Control.Feedback type="invalid">
              {data.HoursWorkedError}
            </Form.Control.Feedback>
          </Form.Group>
        </td>
        <td>
          <FontAwesomeIcon style={{color: "#5cb85c", fontSize: "20px"}} icon={faCheckCircle} onClick={()=>handleOnUpdateEntry()}/>{'   '}
          <FontAwesomeIcon style={{color: "#d9534f", fontSize: "20px"}} icon={faTimesCircle} onClick={()=>setEditEntry(false)} />
        </td>
      </tr>
    )
  }

  const renderShow = () => {
    return(      
      <tr onDoubleClick={()=>setEditEntry(true)}>
        <td>
          <span>{dateFormat(props.Record.Date,"d. mmm yy")}<br/></span>
          {props.Record.Departure ? <small>{props.Record.Departure} - {props.Record.Arrival}</small> : ''}
        </td>
        <td>{props.Record.Customer_Name}</td>
        <td>{props.Record.Project_Name}</td>
        <td>{props.Record.Description}</td>
        <td>{props.Record.HoursWorked}</td>
        <td>
          <FontAwesomeIcon className={"text-info"} style={{fontSize: "20px"}} icon={faEdit} onClick={() => props.onEditWork(props.Record.Id)} />{' '}
          <FontAwesomeIcon style={{color: "#d9534f", fontSize: "20px"}} icon={faTrash} onClick={()=>handleDeleteEntry(props.Record.Id)} />{' '}
          <FontAwesomeIcon className={"text-info"} style={{fontSize: "20px"}} icon={faCopy} onClick={()=>handleCopyEntry()}/>
        </td>
      </tr>
    )
  }

  return(
    <>
    {edit.editEntry ?
      renderEdit() :
      renderShow()
    }
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps,{RemoveWorkRecords, InsertWorkRecords, UpdateWorkRecords, ListWorkRecords, SelectWorkRecord})(WorkTableRow)