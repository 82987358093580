import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Form, Card, Button } from 'react-bootstrap';

import './Login.css'

class ForgottenPassword extends React.Component{

  constructor(props){
    super(props)
    this.state = {
        formBasicEmail: '',
        formBasicEmailError: ''
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.submitButton = this.submitButton.bind(this);
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  submitButton(){
    if(this.state.formBasicEmail){
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/auth/reset/${this.state.formBasicEmail}`).then(response => {
        this.props.history.push('/codeVerification')
      }).catch(error => {
        const errors = {
          formBasicEmailError: 'Invailid'
        }
        this.setState({
          ...this.state,
          ...errors
        })
      });
    }
  }

  render(){
    return(
      <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className={"d-none d-md-flex col-md-4 col-lg-4 bg-image"}></div>
        <Col md={8} lg={8}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Card.Body>
                    <Card.Text>
                      <h4>Du hast dein Passwort vergessen,</h4>
                      bitte gebe deine Email Adresse ein um einen Verifizierungscode zu erhalten. Der Code wird dir anschließend per Mail zugesendet.
                    </Card.Text>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Control 
                          type="email" 
                          placeholder="Enter email" 
                          value={this.state.formBasicEmail}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.formBasicEmailError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.formBasicEmailError}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                        Wir werden deine Email Adresse niemals teilen!
                        </Form.Text>
                      </Form.Group>
                      <Button 
                        variant="primary"
                        onClick={this.submitButton} >
                        Absenden
                      </Button>
                    </Form>
                    <Card.Text>
                      <Link to={{
                          pathname:'/codeVerification',
                        }}
                        >Ich habe einen Code erhalten!
                        </Link>
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    );
  }
}

export default (ForgottenPassword);