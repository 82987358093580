import React from 'react'
import { connect } from "react-redux"

import { ShowSpinner, UpdateKanbanBucket, RemoveKanbanBucket } from "../../../store/Actions/index"

import { Col, Form, InputGroup, Button, Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next'

import KanbanTask from './KanbanTask'
import NewTask from './NewTask'

class KanbanBucket extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
      mouseIsHovering: false,
      name:null,
      edit:false
    };
    this.bucketRef = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnClickColumn = this.handleOnClickColumn.bind(this)
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this)
    this.handleOnRemoveBucket = this.handleOnRemoveBucket.bind(this)
  }
  
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOnClickOutside);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOnClickOutside);
  }

	componentWillReceiveProps(nextProps) {
		this.setState({ mouseIsHovering: false });
	}

  handleOnClickColumn(){
    this.setState({
      edit:true
    })
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleOnClickOutside(event) {
    if (this.bucketRef && !this.bucketRef.current.contains(event.target)) {
      if(this.state.name){
        this.props.UpdateKanbanBucket(this.props.bucketId, this.state.name, this.props.stage)
        this.setState({
          edit:false,
          name:null
        })
      }
      else this.setState({ edit:false, name:null })
    }
  } 

  handleOnRemoveBucket() {
    this.props.RemoveKanbanBucket(this.props.bucketId)
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(this.state.name){
        this.props.UpdateKanbanBucket(this.props.bucketId, this.state.name, this.props.stage)
        this.setState({
          edit:false,
          name:null
        })
      }
    }
  }

	generateKanbanCards() {
		return this.props.projects.slice(0).map((project) => {
			return (
				<KanbanTask
					project={project}
					key={project.id}
					onDragEnd={this.props.onDragEnd}
          onEditTask={this.props.onEditTask}
				/>
			);
		});
	}

	render() {
		return  (
      <Col
        key={this.props.bucketId}
        className={'kanban-column allign-middle'}
				onDragEnter={(e) => {this.setState({ mouseIsHovering: true }); this.props.onDragEnter(e, this.props.bucketId);}}
				onDragExit={(e) => {this.setState({ mouseIsHovering: false });}}
			>
        <div ref={this.bucketRef}>
          {this.state.edit ? 
            <Form.Group>
              <InputGroup>
                <Form.Control id="name" type={'text'} onChange={this.handleOnChange} defaultValue={this.props.name} onKeyDown={this.handleKeyDown} />
                {this.props.projects.length < 1 && (
                <InputGroup.Append>
                  <Button onClick={this.handleOnRemoveBucket} variant="outline-secondary" style={{border:'none'}}><FontAwesomeIcon icon={faTrash} /></Button>
                </InputGroup.Append>)}
              </InputGroup>
            </Form.Group>
            : 
            <><h3><Badge className={"float-right"} variant="primary">{this.props.projects.length}</Badge></h3><p onClick={this.handleOnClickColumn} style={{marginTop: 'revert'}}><b>{this.props.name}</b></p></>
          }
        </div>
        <NewTask
          kanbanId = {this.props.kanbanId}
          bucketId = {this.props.bucketId}
          stage = {10}
					key={('newTask-slot-' + this.props.bucketId)}
        />
				{this.generateKanbanCards()}
				<br/>
      </Col>);
	}
}

export default connect(null,{ShowSpinner, UpdateKanbanBucket, RemoveKanbanBucket})(withTranslation()(KanbanBucket));