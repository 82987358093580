import React, { useState, useEffect } from 'react';
import config from '../../../config/Settings.json'
import { connect } from "react-redux";
import { ShowSpinner, ListWorkRecords, SelectWorkRecord, DestroyWorkRecordStore } from "../../../store/Actions/index";
import { getToken } from '../../../utils/Common';
import { Row, Col, Table, Button, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import WorkTableRow from '../components/TableRows/WorkTableRow';
import WorkRecordModal from '../components/Modals/WorkModal';
import { withTranslation } from 'react-i18next';
import '../ProjectTracking.css';

const Work = (props) => {

  const [ showModal, setShowModal ] = useState(false)
  const [ tableStartIndex, setTableStartIndex ] = useState(0)

  const handleOnShowModal = (id) => {
    props.SelectWorkRecord(id)
    setShowModal(true)
  }

  const handleOnHideModal = () => {
    setShowModal(false)
    props.DestroyWorkRecordStore()
  }

  const setTablePignation = (op) => {
    var value = tableStartIndex
    if(op==='+')
      value = value+config.maxTableRows
    
    if(op==='-')
      value = value-config.maxTableRows
    
    props.ShowSpinner()
    props.ListWorkRecords(value,config.maxTableRows)
    setTableStartIndex(value)
  }

  const isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }

  useEffect(() => {
    if(getToken()){
      props.ShowSpinner()
      props.ListWorkRecords()
    }
  },[]);

  const renderTable = () => {
    var items = []
    for(var j = 0; j<props.workRecords.length;j++){
      items.push(<WorkTableRow
        key={props.workRecords[j].Id}
        Record={props.workRecords[j]}
        Id={props.workRecords[j].Id}
        onEditWork={handleOnShowModal}
      />
      )
    }
    return items;
  }

  const {t} = props
  return(
    <>
    <Row className={"table-title"}>
      <Col xs={5}>
        <h2>{t('Working Time')} <b>{t('Management')}</b></h2>
      </Col>
      <Col xs={7}>
        <Button 
          variant="outline-secondary"
          onClick={handleOnShowModal}>
          <FontAwesomeIcon icon={faPlus}/>
          {' '}{t('New Entry')}
        </Button>
      </Col>
    </Row>
      <Table responsive hover>
        <thead>
          <tr>
            <th>{t('Date')}</th>
            <th>{t('Customer')}</th>
            <th>{t('Project')}</th>
            <th>{t('Description')}</th>
            <th>{t('Hours')}</th>
            <th>{t('Action')}</th>
          </tr>
        </thead>
        <tbody>
          {props.workRecords && renderTable()}
        </tbody>
      </Table>
      <Pagination>
        {tableStartIndex > 0 ? <Pagination.Prev onClick={() => setTablePignation('-')}/> : <></>}
        {props.workRecords.length >= config.maxTableRows ? <Pagination.Next onClick={() => setTablePignation('+')}/> : <></>}
      </Pagination>
      <WorkRecordModal
        show={showModal}
        onHide={handleOnHideModal}>
      </WorkRecordModal>
    </>
  );
}

const mapStateToProps = state => {
  return {
    workRecords: state.workRecords,
    workRecordModalShow: state.workRecordModalShow
  };
};

export default connect(mapStateToProps,{ShowSpinner, ListWorkRecords, SelectWorkRecord, DestroyWorkRecordStore})(withTranslation()(Work));