import React from 'react'
import { ListGroup, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

const SidebarSubitem = (props) => {
  const {t} = props
  return (<>
    {props.show ?
      (<ListGroup.Item 
      action
      eventKey={props.key} 
      as={Link}
      to={props.path}
      {...props}
      style={{marginLeft:"20px", fontSize: "small"}}
    >
      <Row className={'align-items-center'}>
        {props.icon}
        <span className={'d-none d-md-none d-lg-block'}>{t(props.title)}</span>
      </Row>
    </ListGroup.Item>) :
    (<div></div>)
    }</>
  )
}

export default withTranslation()(SidebarSubitem)