import React from 'react';
import { connect } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import Avatar from '../../Avatar/Avatar'

const AccountTableRow = props => {

  return(
    <tr onDoubleClick={()=>{props.onClickAccount(props.guid)}}>
      <td>
        <div style={{display: "flex"}}>
          <Avatar
            key={props.guid}
            guid={props.guid}
            name={(props.givenname + ', ' + props.surname)}
          />
        </div>
      </td>
      <td>{props.givenname}</td>
      <td>{props.surname}</td>
      <td>{props.phone ? <><a href={"tel:" + props.phone}>{props.phone}</a></> : ''}</td>
      <td>{props.mail ? <><a href={"mailto:" + props.mail}>{props.mail}</a></> : ''}</td>
      <td><FontAwesomeIcon className={"text-info"} style={{fontSize: "20px"}} icon={faExternalLinkAlt} onClick={()=>{props.onClickAccount(props.guid)}} /></td>
    </tr>
  );
}

export default connect(null,{})(AccountTableRow);