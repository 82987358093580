import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import config from '../config/Settings.json'
import { removeUserSession, getToken, getUser, runSession } from '../utils/Common';
import { LogoutUser, LoginUser, GetRole, WhoIam, ToggleSidebar } from '../store/Actions/index';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import logo from '../images/FA_Logo_gray.png';
import userImage from '../images/defaultUserM.png'
import { Sidebar, Header, Footer } from '../components'

const Layout = (props) => {

  const [language, setLanguage ] = useState('DE')
  const [photo, setPhoto ] = useState(userImage)

  useEffect(() => {
    if(getToken()){
      if(!props.authState || !props.user)
        refreshLogin()

      props.GetRole()
      runSession()
      getUserProfilePhoto()
    }
    else
      removeUserSession()
  });

  const refreshLogin = async () => {
    const user = await getUser()
    props.LoginUser(user.guid)
    props.WhoIam()
  }

  const handleOnLogout = () => {
    const user = getUser()
    props.LogoutUser(user.guid)
    removeUserSession()
  }

  const handleOnLanguageChange = (event) => {
    setLanguage(event)
    props.i18n.changeLanguage(event)
  }

  const getUserProfilePhoto = () => {
    const token = getToken()
    const user = getUser()
    if(token && user){
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/users/${user.guid}/photo?type=medium`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        }).then(response => {
          setPhoto(response.data.photo)
      })
    }
  }

  const {t} = props
  return(<>
    {props.authState ? (<>
      <Header 
        photo={photo}
        givenname={props.user.givenname}
        surname={props.user.surname}
        logo={logo}
        toggleSidebar={props.ToggleSidebar}
      />
      <main style={{marginTop: "80px"}}>
        <Row>
          <Col md={2} lg={2} xs={2} className={props.sidebarView ? 'sidebar-wrapper': 'sidebar-wrapper closed'} >
            <Sidebar
              handleOnLogout={handleOnLogout}
              handleOnLanguageChange={handleOnLanguageChange}
              toggleSidebar={props.ToggleSidebar}
            />
          </Col>
          <Col>
            {props.children}
          </Col>
        </Row>
      </main>
      </>) :
      (<>
        <Container fluid >
          {props.children}
        </Container>
      </>)}
      {props.authState && (
        <Footer />)}
    </>
  );
}

const mapStateToProps = state => {
  return {
    authState: state.authState,
    user: state.user,
    sidebarView: state.sidebarView
  };
};

export default connect(mapStateToProps,{ GetRole, LoginUser, LogoutUser, WhoIam, ToggleSidebar })(withTranslation()(Layout));