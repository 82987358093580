import React, {useState} from 'react'
import { connect } from "react-redux"
import { HideDialog } from "../../store/Actions/index"
import { Modal, Button, Image} from 'react-bootstrap'
import { Document, Page } from 'react-pdf';
import { withTranslation } from 'react-i18next'

const Dialog = props => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const {t} = props
  return(
    <Modal
      show={props.dialog.show}
      onHide={props.HideDialog}
    >
      <Modal.Header closeButton>
        <Modal.Title>{props.dialog.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {props.dialog.contentType === 'application/pdf' && 
        <div>
          <Document
            file={props.dialog.message}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} />
          </Document>
          <p>Page {pageNumber} of {numPages}</p>
        </div>
        }
        {props.dialog.contentType === 'image/png' && <Image src={props.dialog.message} />}
        {props.dialog.contentType === 'text' && <p>{props.dialog.message}</p> }
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={props.HideDialog} variant="secondary">{t('Close')}</Button>
      </Modal.Footer>
    </Modal>
  ) 
}

const mapStateToProps = state => {
  return {
    dialog: state.dialog,
  };
};

export default connect(mapStateToProps,{HideDialog})(withTranslation()(Dialog));