import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faExclamation, faAmbulance, faSortAmountDown, faComments } from '@fortawesome/free-solid-svg-icons'
import { Card } from 'react-bootstrap';
import Avatar from '../../Avatar/Avatar'
import { withTranslation } from 'react-i18next';

const KanbanTaskCard = props => {

  const renderTaskMembers = (members) =>{
    var avatars = []
    members.forEach( function(attendee) {
      avatars.push(
        <Avatar
          key={attendee.guid}
          guid={attendee.guid}
          name={attendee.name}
        />
      )
    })
    return avatars
  }

  const {t} = props
  return (
    <Card
      key={props.project.id}
      bg={
        props.project.state === 'Not started' ? ('warning') : 
        props.project.state === 'In work' ? ('info') :
        props.project.state === 'Completed' && ('success')
      }
      border={
        props.project.state === 'Not started' ? ('warning') : 
        props.project.state === 'In work' ? ('info') : 
        props.project.state === 'Completed' && ('success') 
      }
      className={'hover'}
      text={'white'}
      onClick={(e) => {props.onEditTask(e, props.project);}}>
    <Card.Body>
      <div className={'rotate'}>
        <FontAwesomeIcon icon={faTasks} size={'3x'}/>
      </div>
      <h6 className="text-uppercase text-left">
        {props.project.priority === 'Important' && (<FontAwesomeIcon className={'text-danger'} icon={faExclamation} />)}
        {props.project.priority === 'Urgent' && (<FontAwesomeIcon className={'text-danger'} icon={faAmbulance} />)}
        {props.project.priority === 'Irrelevant' && (<FontAwesomeIcon className={'text-primary'} icon={faSortAmountDown} />)}
        {'  '}
        {t(props.project.state)}
      </h6>
      <h2 className="text-left">{props.project.title}</h2>
      <div style={{display: "flex"}}>
        {props.project.members &&(renderTaskMembers(JSON.parse(props.project.members)))}
        {props.project.hasComments === 'true' &&(<div style={{flex:1}} className={'text-right'}><FontAwesomeIcon className={'text-right'} icon={faComments} /></div>)}
      </div>
    </Card.Body>
  </Card>
  );
}

export default (withTranslation()(KanbanTaskCard));