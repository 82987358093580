import { RESET_ERROR, SEARCH_SET_RESULT } from "../Constants/action-types"

import { getToken } from '../../utils/Common'
import config from '../../config/Settings.json'

export function SearchBarSearch(searchString) {
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/data/find/' + searchString,
        'headers': {
          Authorization: 'Bearer ' + token
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(response.statusCode === 404)
          return dispatch({type: RESET_ERROR, payload: {show:true,code:404,message:'You do nat have permisson to access this document'} });
        else if(json) {
          return dispatch({type: SEARCH_SET_RESULT, payload: json });
        }
      });
    }
  }
}

export function SearchBarClear() {
  return function(dispatch){
    return dispatch({type: SEARCH_SET_RESULT, payload: null });
  }
}