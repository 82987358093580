import React, { useState, useEffect }  from 'react'
import axios from 'axios';
import { getToken } from '../../../utils/Common'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'react-bootstrap'
import BarGroup from '../../ProjectTracking/components/Charts/BarGroup'

const WorkEvaluation = (props) => {

  const [workEvaluation, setWorkEvaluation ] = useState([])

  useEffect(() => {
    loadTimeEvaluation()
  }, []);

  const loadTimeEvaluation = () => {
    const token = getToken()
    if(token) {
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/evaluation/me/time`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(response => {
          var list = []
          for(var j = 0; j<response.data.length;j++){
            var obj = {
              name:'KW ' + response.data[j].calendarWeek,
              value:response.data[j].workHours
            }
            list.push(obj)
          }
          setWorkEvaluation(list)
      }).catch(error =>{
        setWorkEvaluation([])
      })
    }
  }

  const {t} = props
  return(
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Working Time')} <b>{t('overview')}</b></h4>
        <p>{t('sentences.workingTimeOverview')}</p>  
      </Col>
      <Col md={4} lg={4} xs={4}>

      </Col>
      <Col md={12} lg={12} xs={12} style={{display:"flex"}}>
        <BarGroup 
          key={'workEvaluationChart'}
          data={workEvaluation}
          maxWidth={props.windowWidth}
        />
      </Col>
    </Row>
  );
}

export default (withTranslation()(WorkEvaluation));