import { INSERT_PROJECT, GET_PROJECT, RESET_ERROR, GET_PROJECT_HOUR_EVALUATION } from "../Constants/action-types";

import { getToken } from '../../utils/Common';
import config from '../../config/Settings.json';

export function GetProject(Id) {
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/project/' + Id,
        'headers': {
          Authorization: 'Bearer ' + token
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(response.statusCode === 403)
          return dispatch({type: RESET_ERROR, payload: {show:true,code:403,message:'You do nat have permisson to access this document'} });
        else if(json) {
          return dispatch({type: GET_PROJECT, payload: json });
        }
      });
    }
  }
}

export function GetProjectHourEvaluation(Id, StartDate, EndDate) {
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + `/project/${Id}/evaluation?startDate=${StartDate}&endDate=${EndDate}`,
        'headers': {
          Authorization: 'Bearer ' + token
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        
        var json = JSON.parse(body)
        if(json) {
          return dispatch({type: GET_PROJECT_HOUR_EVALUATION, payload: json });
        }
      });
    }
  }
}

export function InsertProject(obj){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'PUT',
        'url': window._env_.REACT_APP_SERVICE_API + '/projects',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "Customers_fk":obj.Customers_fk,
          "Name":'New project',
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json.insertId){
          var project = {}
          project.Id = json.insertId
          project.Name = "New project"
          project.Description = null
          project.Members = null
          project.OrderNumber = null
          project.ProjectNumber = null
          return dispatch({ type: INSERT_PROJECT, payload: project });
        }
      });
    }
  }
}