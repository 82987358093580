import { LIST_EMPLOYEES, SELECT_EMPLOYEE, UPDATE_EMPLOYEE } from "../Constants/action-types";

import { getToken, getUser } from '../../utils/Common';
import config from '../../config/Settings.json';

export function ListEmployees(start,count){
  return function(dispatch){
    const user = getUser()
    const token = getToken()

    if(token && user){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/users',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"start":start,"count":count})
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_EMPLOYEES, payload: json });
        }
      });
    }
  }
}

export function SelectEmployees(guid) {
  return function(dispatch) {
    const token = getToken();
    if(token){
      var request = require("request");
      var options = { 
        method: 'GET',
        url: window._env_.REACT_APP_SERVICE_API + '/users/'+guid,
        headers: {
          Authorization: 'Bearer ' + token
        } 
      };
      
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: SELECT_EMPLOYEE, payload: json[0] });
        }
      });
    }
  };
}

export function UpdateEmployee(obj) {
  return function(dispatch){
    const token = getToken();
    if(token){
      var request = require("request");
      var options = { 
        method: 'PUT',
        url: window._env_.REACT_APP_SERVICE_API + '/users/'+obj.guid,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(obj)
      };
      
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        return dispatch({ type: UPDATE_EMPLOYEE, payload: obj });
      });
    }
  }
}