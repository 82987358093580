import { LIST_KANBAN, GET_KANBAN, INSERT_KANBAN, DESTROY_KANBAN, REMOVE_KANBAN, UPDATE_KANBAN, LIST_KANBANBUCKET, INSERT_KANBANBUCKET, REMOVE_KANBANBUCKET, UPDATE_KANBANBUCKET, LIST_KANBANTASK, INSERT_KANBANTASK, UPDATE_KANBANTASK, REMOVE_KANBANTASK, GET_KANBANTASK, DESTROY_KANBANTASK, INSERT_KANBANCOMMENT, LIST_KANBANCOMMENT, LIST_MY_KANBANTASK, LIST_MY_NEXT_KANBANTASK, LIST_CUSTOMER_KANBANTASK } from "../Constants/action-types";

import { getToken } from '../../utils/Common';
import config from '../../config/Settings.json';

export function ListKanban(){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_KANBAN, payload: json });
        }
      });
    }
  }
}

export function CreateKanban(board){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(board)
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: INSERT_KANBAN, payload: json });
      });
    }
  }
}

export function ListKanbanTaskComment(taskId){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/' + taskId + '/comments',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: LIST_KANBANCOMMENT, payload: json });
      });
    }
  }
}

export function CreateKanbanTaskComment(comment){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/' + comment.taskId + '/comments',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(comment)
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: INSERT_KANBANCOMMENT, payload: json });
      });
    }
  }
}

export function GetKanban(kanbanId){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + kanbanId,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: GET_KANBAN, payload: json });
        }
      });
    }
  }
}

export function RemoveKanban(id){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'DELETE',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + id,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: REMOVE_KANBAN, payload: id });
      });
    }
  }
}

export function UpdateKanban(kanban){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'PATCH',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + kanban.id,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(kanban)
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: UPDATE_KANBAN, payload: json });
      });
    }
  }
}

export function DestroyKanbanBoardStore(){
  return function(dispatch){
    return dispatch({ type: DESTROY_KANBAN, payload: null });
  }
}

export function ListKanbanBucket(kanbanId){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + kanbanId + '/buckets',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_KANBANBUCKET, payload: json });
        }
      });
    }
  }
}

export function ListMyKanbanTask(){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/me',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_MY_KANBANTASK, payload: json });
        }
      });
    }
  }
}

export function ListMyNextKanbanTask(start,end){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': `${window._env_.REACT_APP_SERVICE_API}/kanban/tasks/me?startDate=${start}&endDate=${end}`,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_MY_NEXT_KANBANTASK, payload: json });
        }
      });
    }
  }
}

export function ListKanbanTask(kanbanId){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + kanbanId + '/tasks',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_KANBANTASK, payload: json });
        }
      });
    }
  }
}

export function ListCustomerKanbanTask(customerId){
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/customer/' + customerId + '/tasks',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_CUSTOMER_KANBANTASK, payload: json });
        }
      });
    }
  }
}

export function InsertKanbanBucket(kanbanId, name, stage){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + kanbanId + '/buckets',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "name":name,
          "stage":stage,
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: INSERT_KANBANBUCKET, payload: json });
      });
    }
  }
}

export function InsertKanbanTask(kanbanId, bucketId, title, stage, state, priority){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + kanbanId + '/tasks',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "title":title,
          "kanbanId":kanbanId,
          "bucketId":bucketId,
          "stage":stage,
          "state":state,
          "priority":priority,
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: INSERT_KANBANTASK, payload: json });
      });
    }
  }
}

export function UpdateKanbanTask(task){
  return function(dispatch){
    const token = getToken()
    if(token){

      if('hasComments' in task)
      delete task['hasComments']

      var request = require('request');
      var options = {
        'method': 'PATCH',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/' + task.id,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(task)
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: UPDATE_KANBANTASK, payload: json });
      });
    }
  }
}

export function GetKanbanTask(id){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/' + id + '/details',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: GET_KANBANTASK, payload: json });
      });
    }
  }
}

export function RemoveKanbanTask(taskId){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'DELETE',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/' + taskId,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        return dispatch({ type: REMOVE_KANBANTASK, payload: taskId });
      });
    }
  }
}

export function AddKanbanTaskMember(id,guid){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/' + id + '/member',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          guid:guid
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: UPDATE_KANBANTASK, payload: json });
      });
    }
  }
}

export function RemoveKanbanTaskMember(id,guid){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'PATCH',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/tasks/' + id + '/member',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          guid:guid
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: UPDATE_KANBANTASK, payload: json });
      });
    }
  }
}

export function AddKanbanMember(id,guid){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + id + '/member',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          guid:guid
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: UPDATE_KANBAN, payload: json });
      });
    }
  }
}

export function DestroyKanbanTaskStore(){
  return function(dispatch){
    return dispatch({ type: DESTROY_KANBANTASK, payload: null });
  }
}

export function RemoveKanbanMember(id,guid){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'PATCH',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/boards/' + id + '/member',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          guid:guid
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: UPDATE_KANBAN, payload: json });
      });
    }
  }
}

export function UpdateKanbanBucket(bucketId, name, stage){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'PATCH',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/buckets/' + bucketId,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id:bucketId,
          name:name,
          stage:stage
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        else return dispatch({ type: UPDATE_KANBANBUCKET, payload: { id:bucketId, name:name, stage:stage } });
      });
    }
  }
}

export function RemoveKanbanBucket(bucketId){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'DELETE',
        'url': window._env_.REACT_APP_SERVICE_API + '/kanban/buckets/' + bucketId,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        return dispatch({ type: REMOVE_KANBANBUCKET, payload: bucketId });
      });
    }
  }
}