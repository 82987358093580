import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { LoginUser, WhoIam } from "../../store/Actions/index";

import { Link } from 'react-router-dom';

import { setUserSession, setMSALSession } from '../../utils/Common';
import authService from '../../utils/AuthHelper'

import { Container, Row, Col, Image, Form, Card, Button } from 'react-bootstrap';
import { MicrosoftLoginButton, GoogleLoginButton } from "react-social-login-buttons";

import GoogleLogin from 'react-google-login';

import { v4 as uuidv4 } from 'uuid';

import { withTranslation } from 'react-i18next';
import './Login.css'
import logo from '../../images/FA_Logo_gray.png';

class Login extends React.Component{

  constructor(props){
    super(props)
    this.state = {
        formBasicEmail: '',
        formBasicEmailError: '',
        formBasicPassword: '',
        formBasicPasswordError: '',
        authResponse: undefined,
        error: false,
        showVerification:false,
        verificationMessage:'',
        verificationCode:null,
        verificationMail:null,
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnSendVerificationMail = this.handleOnSendVerificationMail.bind(this);
    this.submitLogin = this.submitLogin.bind(this);
    this.onGoogleSuccess = this.onGoogleSuccess.bind(this);
    this.onGoogleFailure = this.onGoogleFailure.bind(this);
    this.onMSALLogin = this.onMSALLogin.bind(this);
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleOnSendVerificationMail(){
    axios.post(`${window._env_.REACT_APP_SERVICE_API}/auth/verification/`, {verificationCode:this.state.verificationCode, verificationMail:this.state.verificationMail}).then(response => {
      this.setState({
        verificationMessage:"Es wurde eine Mail an deine Adresse versendet. Bitte verifiziere deine Adresse indem du in der Mail auf den Button klickst.",
        showVerification:true,
        verificationCode:null,
        verificationMail:null,
      })
    }).catch(error =>{
      console.log(error)
    })
  }

  validate = () => {
    let isError = false;
    const errors = {
      formBasicPasswordError: '',
      formBasicEmailError: ''
    }

    if(this.state.formBasicEmail.length < 1){
      isError = true;
      errors.formBasicEmailError = 'Username cannot be blank';
    }

    if(this.state.formBasicPassword.length < 1){
      isError = true;
      errors.formBasicPasswordError = 'Password cannot be blank';
    }

    this.setState({
      ...this.state,
      ...errors
    })

    return isError;
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.submitLogin()
    }
  }

  submitLogin(){
    const err = this.validate()
    if(!err){
      axios.post(`${window._env_.REACT_APP_SERVICE_API}/auth/signin/`, { oAuthProvider: "custom", mail: this.state.formBasicEmail, password: this.state.formBasicPassword }).then(response => {
        if(response.data.message === "account not authorized"){
          this.setState({
            showVerification:true,
            verificationMessage:'Dein Account wurde noch nicht freigeschaltet.'
          })
        }
        else if(response.data.message === "account not verified"){
          this.setState({
            showVerification:true,
            verificationMessage:'Deine EMail Adresse wurde noch nicht bestätigt.',
            verificationCode:response.data.verificationCode,
            verificationMail:this.state.formBasicEmail
          })
        }
        else{
          setUserSession(response.data.token, response.data.user);
          this.props.LoginUser(response.data.user.guid)
          this.props.WhoIam()
          this.props.history.push('/dashboard');
        }
      }).catch(error => {
        const errors = {
          formBasicPasswordError: 'Invailid',
          formBasicEmailError: 'Invailid'
        }
        this.setState({
          ...this.state,
          ...errors
        })
      });
    }
  }

  onGoogleSuccess(oAuthResponse){
    axios.post(`${window._env_.REACT_APP_SERVICE_API}/auth/signin/`, {oAuthId:oAuthResponse.googleId, oAuthProvider:"google"}).then(response => {
      if(response.data.message === "Invalid oAuthId"){
        this.props.history.push({pathname:'/registration',authProps:{
          oAuthProvider:'google',
          oAuthId:oAuthResponse.googleId,
          mail:oAuthResponse.profileObj.email,
          givenname:oAuthResponse.profileObj.givenName,
          surname:oAuthResponse.profileObj.familyName
        }})
      }
      else if(response.data.message === "account not authorized"){
        this.setState({
          showVerification:true,
          verificationMessage:'Dein Account wurde noch nicht freigeschaltet.'
        })
      }
      else if(response.data.message === "account not verified"){
        this.setState({
          showVerification:true,
          verificationMessage:'Deine EMail Adresse wurde noch nicht bestätigt.',
          verificationCode:response.data.verificationCode,
          verificationMail:oAuthResponse.profileObj.email
        })
      }
      else{
        setUserSession(response.data.token, response.data.user);
        this.props.LoginUser(response.data.user.guid)
        this.props.WhoIam()
        this.props.history.push('/dashboard')
      }
    }).catch(error =>{
      console.log(error)
    })
  }

  onGoogleFailure(response){
    console.log(response)
  }

  async onMSALLogin(){
    const identity = await authService.signIn();
    if(identity){
      const msalAccount = await authService.getIdentity();
      if(msalAccount){
        axios.post(`${window._env_.REACT_APP_SERVICE_API}/auth/signin/`, {oAuthId:msalAccount.account.accountIdentifier, oAuthProvider:"microsoft"}).then(response => {
          if(response.data.message === "Invalid oAuthId"){
            this.props.history.push({pathname:'/registration',authProps:{
              oAuthProvider:'microsoft',
              oAuthId:msalAccount.account.accountIdentifier,
              mail:msalAccount.emailAddress,
            }})
          }
          else if(response.data.message === "account not authorized"){
            this.setState({
              showVerification:true,
              verificationMessage:'Dein Account wurde noch nicht freigeschaltet.'
            })
          }
          else if(response.data.message === "account not verified"){
            this.setState({
              showVerification:true,
              verificationMessage:'Deine EMail Adresse wurde noch nicht bestätigt.',
              verificationCode:response.data.verificationCode,
              verificationMail:msalAccount.emailAddress
            })
          }
          else{
            setUserSession(response.data.token, response.data.user);
            setMSALSession(msalAccount.accessToken,msalAccount.tenantId,parseInt((new Date(msalAccount.expiresOn).getTime())))
            this.props.LoginUser(response.data.user.guid)
            this.props.history.push('/dashboard')
          }
        }).catch(error =>{
          console.log(error)
        })
      }
    }
  }

  render(){
    const {t} = this.props
    return(
      <>{this.state.showVerification ?
      <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className={"d-none d-md-flex col-md-4 col-lg-6 bg-image"}></div>
        <Col md={9} lg={6}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Card.Body>
                    <Card.Text>
                      <h4>{t('somethingMissing')}</h4>
                      {this.state.verificationMessage}
                      {this.state.verificationCode ? <><h6><Button onClick={()=>this.handleOnSendVerificationMail()}>{t('receivedNoMail')}</Button></h6></>:<></>}
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
       : <>
       <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className={"d-none d-md-flex col-md-4 col-lg-4 bg-image"}></div>
        <Col md={8} lg={8}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Image fluid style={{maxWidth: "350px"}} src={logo} alt="FirstAttribute AG" />
                  <h3>{t('sentences.welcomeBack')},</h3>
                  {t('sentences.pleaseLogin')}.
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>{t('Mail')}</Form.Label>
                        <Form.Control 
                          type="email" 
                          placeholder={t('Mail')} 
                          value={this.state.formBasicEmail}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.formBasicEmailError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.formBasicEmailError}
                        </Form.Control.Feedback>
                        <Form.Text className="text-muted">
                          {t('sentences.notShareYourMail')}!
                        </Form.Text>
                      </Form.Group>
                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>{t('Password')}</Form.Label>
                        <Form.Control 
                          type="password" 
                          placeholder={t('Password')}
                          value={this.state.formBasicPassword}
                          onChange={this.handleOnChange}
                          onKeyDown={this.handleKeyDown}
                          isInvalid={(this.state.formBasicPasswordError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.formBasicPasswordError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button 
                        variant="primary"
                        onClick={this.submitLogin} >
                        {t('Sign In')}
                      </Button>
                    </Form>
                    <Card.Text>
                      <Link to={{
                        pathname:'/forgottenPassword',
                      }}
                      >{t('forgotPassword')}
                      </Link>{' '}
                      <Link to={{
                        pathname:'/registration',
                        authProps:{
                          oAuthProvider:'custom',
                          oAuthId:uuidv4()
                        }
                      }}
                      >{t('To registration')}
                      </Link>
                    </Card.Text>
                    <Card.Text className="text-center">{t('or')}</Card.Text>
                    <Card.Text className="text-center"> 
                      <GoogleLogin
                        render={renderProps => (
                          <GoogleLoginButton  
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >{t('Sign in Google')}
                          </GoogleLoginButton>
                        )}
                        clientId={window._env_.REACT_APP_GOCL_CLIENTID}
                        buttonText={t('Sign In Google')}
                        accessType="offline"
                        responseType="id_token token code"
                        onSuccess={this.onGoogleSuccess}
                        onFailure={this.onGoogleFailure}
                      />
                    </Card.Text>
                    <Card.Text className="text-center">
                      <MicrosoftLoginButton 
                        onClick={this.onMSALLogin}
                      >{t('Sign in Microsoft')}
                      </MicrosoftLoginButton>
                    </Card.Text>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row></>
      }</>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    authState: state.authState
  };
};

export default connect(mapStateToProps,{LoginUser,WhoIam})(withTranslation()(Login));