import React, { useState, useEffect }  from 'react';
import { connect } from "react-redux"
import { ShowSpinner, ListKanbanBucket, ListKanbanTask, UpdateKanbanTask, GetKanbanTask, DestroyKanbanTaskStore, ListKanbanTaskComment } from "../../../store/Actions/index"

import { Row } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

import KanbanBucket from './KanbanBucket'
import NewBucket from './NewBucket'
import KanbanTaskModal from './KanbanTaskModal'

const KanbanBoard = (props) => {

  const [showProject, setShowProject ] = useState(false)
  const [draggedOverCol, setDraggedOverCol ] = useState(null)

  useEffect(() => {
    props.ShowSpinner()
    props.ListKanbanBucket(props.kanbanId)
    props.ListKanbanTask(props.kanbanId)
  },[])

	const handleOnDragEnter = (e, stageValue) => {
		setDraggedOverCol(stageValue);
	}

	const handleOnDragEnd = (e, project) => {
		const updatedProjects = props.kanbanTasks.slice(0)
		updatedProjects.find((projectObject) => {return projectObject.id === project.id;}).bucketId = draggedOverCol
    props.UpdateKanbanTask(project)
  }
  
  const handleOnEditTask = (e, project) => {
    props.GetKanbanTask(project.id)
    props.ListKanbanTaskComment(project.id)
    setShowProject(true)
  }

  const handleOnHideTask = () => {
    setShowProject(false)
    props.DestroyKanbanTaskStore()
  }

  return  (
    <Row style={{overflowX: 'auto', flexWrap: 'nowrap'}}>
      {props.kanbanBuckets.map((column) => {
        return (
          <KanbanBucket
            bucketId = {column.id}
            kanbanId = {props.kanbanId}
            name = {column.name}
            stage = {column.stage}
            projects={props.kanbanTasks.filter((project) => {if(props.showCompleted) return project.bucketId === column.id; else return project.bucketId === column.id && project.state !== 'Completed';})}
            onDragEnter={handleOnDragEnter}
            onDragEnd={handleOnDragEnd}
            onEditTask={handleOnEditTask}
            key={column.id}
          />
        );
      })}
      <NewBucket
        kanbanId = {props.kanbanId}
        stage = {props.kanbanBuckets.length +1}
      />
      <KanbanTaskModal 
        show = {showProject}
        onHide = {handleOnHideTask}
        fullControll={true}
      />
    </Row>
  );
}

const mapStateToProps = state => {
  return {
    kanbanBuckets: state.kanbanBuckets,
    kanbanTasks: state.kanbanTasks
  };
};

export default connect(mapStateToProps,{ShowSpinner, ListKanbanBucket, ListKanbanTask, UpdateKanbanTask, GetKanbanTask, DestroyKanbanTaskStore, ListKanbanTaskComment})(withTranslation()(KanbanBoard));