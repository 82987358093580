import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-regular-svg-icons'
import { withTranslation } from 'react-i18next';

const ProjectTableRow = props => {
  const {t} = props
  return(<>
    {!props.ShowCompleted && (props.ProjectState === 'closed' || props.ProjectState === 'failed') ? null :(
    <tr onDoubleClick={()=>props.History.push('/project/'+props.Id)}>
      <td>{props.Name}</td>
      <td>{t(props.ProjectState)}</td>
      <td className='d-none d-md-none d-lg-table-cell' >{props.Project.OrderNumber}</td>
      <td><FontAwesomeIcon className={"text-info"} style={{fontSize: "20px"}} icon={faEdit} onClick={()=>props.History.push('/project/'+props.Id)} /></td>
    </tr>
    )
    }</>
  );
}

export default (withTranslation()(ProjectTableRow))