export const SW_INIT = "SW_INIT";
export const SW_UPDATE  = "SW_UPDATE";
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_CHANGE = "LOGIN_CHANGE";
export const GET_ROLE = "GET_ROLE";
export const GET_USER = "GET_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_ME_NOTIFICATION = "GET_USER_NOTIFICATION";

export const SEARCH_SET_RESULT = "SEARCH_SET_RESULT";

export const UPDATE_SPINNER = "SHOW_SPINNER";
export const UPDATE_DIALOG = "UPDATE_DIALOG";
export const TOGGLE_GRIDVIEW = "TOGGLE_GRIDVIEW";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const RESET_ERROR = "RESET_ERROR";

export const LIST_CUSTOMERS = "LIST_CUSTOMERS";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const HIDE_CUSTOMER = "HIDE_CUSTOMER";
export const GET_CUSTOMER_HOUR_EVALUATION = "GET_CUSTOMER_HOUR_EVALUATION";

export const ADD_USER = "ADD_USER";

export const GET_PROJECT = "GET_PROJECT";
export const INSERT_PROJECT = "INSERT_PROJECT";
export const GET_PROJECT_HOUR_EVALUATION = "GET_PROJECT_HOUR_EVALUATION";

export const LIST_WORKRECORDS = "LIST_WORKRECORDS";
export const REMOVE_WORKRECORD = "REMOVE_WORKRECORD";
export const INSERT_WORKRECORD = "INSERT_WORKRECORD";
export const UPDATE_WORKRECORD = "UPDATE_WORKRECORD";
export const SELECT_WORKRECORD = "SELECT_WORKRECORD";
export const HIDE_WORKRECORD = "HIDE_WORKRECORD";

export const LIST_EXPENSES = "LIST_EXPENSES";
export const REMOVE_EXPENSES = "REMOVE_EXPENSES";
export const INSERT_EXPENSES = "INSERT_EXPENSES";

export const LIST_EMPLOYEES = "LIST_EMPLOYEES";
export const SELECT_EMPLOYEE = "SELECT_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";

export const LIST_TIMESHEETS = "LIST_TIMESHEETS";
export const INSERT_TIMESHEET = "INSERT_TIMESHEET";
export const REMOVE_TIMESHEET = "REMOVE_TIMESHEET";
export const UPDATE_TIMESHEET = "UPDATE_TIMESHEET";

export const SIGN_TIMESHEET = "SIGN_TIMESHEET";
export const SHOW_SIGNATUREBOARD = "SHOW_SIGNATUREBOARD";
export const HIDE_SIGNATUREBOARD = "HIDE_SIGNATUREBOARD";

export const LIST_KANBAN = "LIST_KANBAN";
export const GET_KANBAN = "GET_KANBAN";
export const INSERT_KANBAN = "INSERT_KANBAN";
export const REMOVE_KANBAN = "REMOVE_KANBAN";
export const UPDATE_KANBAN = "UPDATE_KANBAN";
export const LIST_KANBANBUCKET = "LIST_KANBANBUCKET";
export const INSERT_KANBANBUCKET = "INSERT_KANBANBUCKET";
export const REMOVE_KANBANBUCKET = "REMOVE_KANBANBUCKET";
export const UPDATE_KANBANBUCKET = "UPDATE_KANBANBUCKET";
export const LIST_KANBANTASK = "LIST_KANBANTASK";
export const INSERT_KANBANTASK = "INSERT_KANBANTASK";
export const REMOVE_KANBANTASK = "REMOVE_KANBANTASK";
export const UPDATE_KANBANTASK = "UPDATE_KANBANTASK";
export const GET_KANBANTASK = "GET_KANBANTASK";
export const DESTROY_KANBANTASK = "DESTROY_KANBANTASK";
export const DESTROY_KANBAN = "DESTROY_KANBAN";
export const INSERT_KANBANCOMMENT = "INSERT_KANBANCOMMENT";
export const LIST_KANBANCOMMENT = "LIST_KANBANCOMMENT";
export const LIST_MY_KANBANTASK = "LIST_MY_KANBANTASK";
export const LIST_MY_NEXT_KANBANTASK = "LIST_MY_NEXT_KANBANTASK";
export const LIST_CUSTOMER_KANBANTASK = "LIST_CUSTOMER_KANBANTASK";