import { UPDATE_DIALOG, RESET_ERROR } from "../Constants/action-types";

export function ShowDialog(title, message, contentType) {
  return function(dispatch) {
    var obj = {
      show:true,
      title:title,
      message:message,
      contentType:contentType
    }
    return dispatch({type: UPDATE_DIALOG, payload: obj });
  }
}

export function HideDialog() {
  return function(dispatch) {
    var obj = {
      show:false,
      title:'',
      message:'',
      contentType:''
    }
    return dispatch({type: UPDATE_DIALOG, payload: obj });
  }
}

export function ResetError() {
  return function(dispatch) {
    return dispatch({type: RESET_ERROR, payload: {show:false,code:null,message:null} });
  }
}