import React, { Component } from 'react';

import { connect } from "react-redux";
import { ShowSpinner, CreateKanban } from "../../../store/Actions/index";

import { withTranslation } from 'react-i18next';

import { Modal, Button, Form } from 'react-bootstrap';

class NewBoard extends Component {
  constructor(props){
    super(props)
    this.state = {
      title:null,
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  handleOnSubmit(){
    if(this.state.title){
      this.props.CreateKanban({owner:this.props.owner, title: this.state.title, class: this.props.kanbanClass})
      this.props.onHide()
    }
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }
  
  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('New')} <b>{t('Kanban')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="title" >
            <Form.Label>{t('Name')}</Form.Label>
            <Form.Control onChange={this.handleOnChange} type="text" placeholder={t('Name')} />
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        <Button onClick={this.handleOnSubmit} variant="primary" >{t('Save')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

export default connect(null,{ShowSpinner, CreateKanban})(withTranslation()(NewBoard));