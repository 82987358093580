import React, { useState } from 'react';
import { connect } from "react-redux";
import { GetKanbanTask, ListKanbanTaskComment, DestroyKanbanTaskStore } from '../../../store/Actions'
import { Row, Col } from 'react-bootstrap';
import WorkModalSmall from '../../ProjectTracking/components/Modals/WorkModalSmall';
import KanbanTaskCard from '../../Kanban/components/KanbanTaskCard'
import KanbanTaskModal from '../../Kanban/components/KanbanTaskModal'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { withTranslation } from 'react-i18next';

const dateFormat = require('dateformat');

const PendingTasks = (props) => {

  const [data, setData ] = useState({
    eventData:[],
    showInsert:false,
    showTask:false,
    insertDescription:null,
    insertHoursWorked:null,
    insertDate:null,
  })

  const handleInsertWorkRecord = (description,date,duration) => {
    setData(prevData => ({
      ...prevData,
      showInsert:true,
      insertDescription:description,
      insertDate:date,
      insertHoursWorked:duration
    }));
  }

  const handleOnHideWorkRecord = () => {
    setData(prevData => ({
      ...prevData,
      showInsert:false,
    }));
  }

  const handleOnEditTask = (e, project) => {
    props.GetKanbanTask(project.id)
    props.ListKanbanTaskComment(project.id)
    setData(prevData => ({
      ...prevData,
      showTask:true
    }));
  }

  const handleOnHideTask = () => {
    setData(prevData => ({
      ...prevData,
      showTask:false
    }));
    props.DestroyKanbanTaskStore()
  }

  const renderTasks = (myNextTasks) => {
    const {t} = props
    return myNextTasks.map((project) => {
      return (
        <Col key={project.id} sm={12} lg={4} md={6}>
          <ContextMenuTrigger id={project.id}>
            <KanbanTaskCard
              project={project}
              key={project.id}
              onEditTask={handleOnEditTask}
            />
          </ContextMenuTrigger>
          <ContextMenu id={project.id}>
            <MenuItem
              onClick={()=>handleInsertWorkRecord(project.title,dateFormat(project.startDate,"yyyy-mm-dd"),0)}
            >
              {t('Takeover')}
            </MenuItem>
            <MenuItem
              onClick={()=>handleOnEditTask(null,project)}
            >
              {t('Open')}
            </MenuItem>
          </ContextMenu>
        </Col>
      )
    })
  }

  const {t} = props
  return(
    <>
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Pending')} <b>{t('tasks')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4}>

      </Col>
    </Row>
    <Row className={'mt-25'}>
      {props.myNextTasks.length > 0 ? 
        renderTasks(props.myNextTasks) : 
        <Col className={'text-center'} key={'noEntries'}>
          <h1>{t('no entries')}</h1>
          <h4>{t('sentences.noEntries')}</h4>
        </Col>
      }
    </Row>
    <WorkModalSmall
      show={data.showInsert}
      onHide={()=>handleOnHideWorkRecord()}
      description={data.insertDescription}
      hoursWorked={data.insertHoursWorked}
      date={data.insertDate}
    />
    <KanbanTaskModal 
      show = {data.showTask}
      onHide = {()=>handleOnHideTask()}
    />
    </>
  );
}

const mapStateToProps = state => {
  return {
    myNextTasks: state.myNextTasks,
  };
};

export default connect(mapStateToProps,{GetKanbanTask, ListKanbanTaskComment, DestroyKanbanTaskStore})(withTranslation()(PendingTasks))