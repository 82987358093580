import React from 'react'
import { connect } from "react-redux"

import { ShowSpinner, InsertKanbanTask } from "../../../store/Actions/index";

import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus} from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';

class NewTask extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      edit:false,
      title:null
    }
    this.wrapperRef = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleOnClickColumn = this.handleOnClickColumn.bind(this)
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOnClickOutside);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOnClickOutside);
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleOnClickColumn(){
    this.setState({
      edit:true
    })
  }

  handleOnClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if(this.state.title){
        this.props.InsertKanbanTask(this.props.kanbanId, this.props.bucketId, this.state.title, this.props.stage, 'Not started', 'Normal')
        this.setState({
          edit:false,
          title:null
        })
      }
      else this.setState({ edit:false, title:null})
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(this.state.title){
        this.props.InsertKanbanTask(this.props.kanbanId, this.props.bucketId, this.state.title, this.props.stage)
        this.setState({
          edit:false,
          title:null
        })
      }
    }
  }

	render() {
    const {t} = this.props
		return (
			<div
        ref={this.wrapperRef} 
        onClick={this.handleOnClickColumn} 
        onKeyDown={this.handleKeyDown}
      	className={'kanban-card'}
			>
        {this.state.edit ? 
          <Form.Group controlId="title" >
            <Form.Control type={'text'} onChange={this.handleOnChange} />
          </Form.Group>
         : 
				<div><FontAwesomeIcon icon={faPlus}/>{' '}{t('add task')}</div>}
			</div>
		);
	}
}

export default connect(null,{ShowSpinner, InsertKanbanTask})(withTranslation()(NewTask));