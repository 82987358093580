import React, { Component } from 'react';
import config from '../../config/Settings.json'
import { connect } from "react-redux";
import { ShowSpinner, ListEmployees,ToggleGridView } from "../../store/Actions/index";
import { getToken } from '../../utils/Common';

import { Row, Col, Table, Button, Pagination } from 'react-bootstrap';

import { withTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge } from '@fortawesome/free-solid-svg-icons'

import EmployeeCard from '../BusinessCard/BusinessCard'
import AccountTableRow from './components/AccountTableRow'


class Employees extends Component{
  constructor(props) {
    super(props);
    this.state = {
      showAsGrid: false,
      tableStartIndex:0,
    }
    this.setTablePignation = this.setTablePignation.bind(this)
    this.showAccount = this.showAccount.bind(this)
  }

  setTablePignation(op){
    var value = this.state.tableStartIndex
    if(op==='+')
      value = value+config.maxTableRows
    
    if(op==='-')
      value = value-config.maxTableRows
      
    this.props.ShowSpinner()
    this.props.ListEmployees(value,config.maxTableRows)
    this.setState({
      tableStartIndex:value
    })
  }

  componentDidMount(){
    if(getToken()){
      this.props.ShowSpinner()
      this.props.ListEmployees(0,config.maxTableRows)
    }
  }

  showAccount(e){
    this.props.history.push(`/employee/${e}`)
  }

  renderGrid(){
    var items = []
    for(var j = 0; j<this.props.employees.length;j++){
      items.push(<EmployeeCard
        key={this.props.employees[j].guid}
        onClickAccount={this.showAccount}
        guid={this.props.employees[j].guid}
        online={this.props.connectedEmployees.includes(this.props.employees[j].guid)}
        name={this.props.employees[j].givenname + ", " + this.props.employees[j].surname}
        mail={this.props.employees[j].mail}
        phone={this.props.employees[j].phone}
        photo={this.props.employees[j].photo}
        company={this.props.employees[j].company}
        edit={this.state.editEmployee}
        xing={this.props.employees[j].xing}
        linkedin={this.props.employees[j].linkedin}
        facebook={this.props.employees[j].facebook}
        twitter={this.props.employees[j].twitter}
      />)
    }
    return items;
  }

  renderTable(){
    var items = []
    for(var j = 0; j<this.props.employees.length;j++){
      items.push(<AccountTableRow
        key={this.props.employees[j].guid}
        onClickAccount={this.showAccount}
        online={this.props.connectedEmployees.includes(this.props.employees[j].guid)}
        guid={this.props.employees[j].guid}
        mail={this.props.employees[j].mail}
        phone={this.props.employees[j].phone}
        photo={this.props.employees[j].photo}
        surname={this.props.employees[j].surname}
        givenname={this.props.employees[j].givenname}
      />)
    }
    return items;
  }

  render(){
    const {t} = this.props
    return(
      <>
        <Row className={"table-title"}>
          <Col xs={5}>
            <h2>{t('Employee')} <b>{t('Network')}</b></h2>
          </Col>
          <Col xs={7}>
            <Button
              className={'edit-btn'}
              variant={'outline-secondary'}
              onClick={()=>this.props.ToggleGridView()}>
              <FontAwesomeIcon icon={faThLarge}/>
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <>
            {this.props.gridView ? 
            <>
              {this.renderGrid()}
            </>:
            <>
            <Table responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th>{t('Givenname')}</th>
                  <th>{t('Surname')}</th>
                  <th>{t('Phone')}</th>
                  <th>{t('Mail')}</th>
                  <th>{t('Action')}</th>
                </tr>
              </thead>
              <tbody>
              {this.renderTable()}
              </tbody>
            </Table>
            </>
            }
          </>
        </Row>
        <Pagination>
          {this.state.tableStartIndex > 0 ? <Pagination.Prev onClick={() => this.setTablePignation('-')}/> : <></>}
          {this.props.employees.length >= config.maxTableRows ? <Pagination.Next onClick={() => this.setTablePignation('+')}/> : <></>}
        </Pagination>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    gridView: state.gridView,
    user: state.user,
    employees:state.employees,
    connectedEmployees:state.connectedEmployees
  };
};

export default connect(mapStateToProps,{ShowSpinner, ToggleGridView, ListEmployees})(withTranslation()(Employees));