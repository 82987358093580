import React from 'react'
import { connect } from "react-redux";
import { Col } from 'react-bootstrap';
import "./Comment.scss"

const Comment = props => {
  return(
    <div className="commentCover">
      <Col lg={6} md={6} className="commentContent float-right">

      </Col>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    
  };
};

export default connect(mapStateToProps)(Comment);