function ConvertToSeconds(time) {
  var splitTime = time.split(":");
  return splitTime[0] * 3600 + splitTime[1] * 60;
}

function secondsTohhmmss(secs) {
  var hours = parseInt(secs / 3600);
  var seconds = parseInt(secs % 3600);
  var minutes = parseInt(seconds / 60) ;

  return {hours:hours,minutes:minutes}
}

export const DERgetTimeDifference = (earlierDate, laterDate) => {
  var smon = earlierDate ;
  var fmon = laterDate ;
  var diff = 0 ;
  if (smon && fmon) {
    smon = ConvertToSeconds(smon);
    fmon = ConvertToSeconds(fmon);
    diff = Math.abs( fmon - smon ) ;
  }
  return secondsTohhmmss(diff);
}