import React from 'react'
import { connect } from "react-redux"

import { ShowSpinner, InsertKanbanBucket } from "../../../store/Actions/index";

import { Col, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus} from '@fortawesome/free-solid-svg-icons'

import { withTranslation } from 'react-i18next';

class NewBucket extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      edit:false,
      name:null
    }
    this.wrapperRef = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleOnClickColumn = this.handleOnClickColumn.bind(this)
    this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOnClickOutside);
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleOnClickOutside);
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleOnClickColumn(){
    this.setState({
      edit:true
    })
  }

  handleOnClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if(this.state.name){
        this.props.InsertKanbanBucket(this.props.kanbanId, this.state.name, this.props.stage)
        this.setState({
          edit:false,
          name:null
        })
      }
      else this.setState({ edit:false, name:null })
    }
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if(this.state.name){
        this.props.InsertKanbanBucket(this.props.kanbanId, this.state.name, this.props.stage)
        this.setState({
          edit:false,
          name:null
        })
      }
    }
  }

	render() {
    const {t} = this.props
		return  (
      <Col className={'kanban-column align-middle'} ref={this.wrapperRef} onClick={this.handleOnClickColumn} onKeyDown={this.handleKeyDown}>
        {this.state.edit ? 
          <Form.Group controlId="name" >
            <Form.Control type={'text'} onChange={this.handleOnChange} />
          </Form.Group>
         : 
          <p style={{marginTop: 'revert'}}><FontAwesomeIcon icon={faPlus}/>{' '}{t('add bucket')}</p>}
      </Col>);
	}
}

export default connect(null,{ShowSpinner, InsertKanbanBucket})(withTranslation()(NewBucket));