import { UPDATE_SPINNER, TOGGLE_GRIDVIEW, TOGGLE_SIDEBAR } from "../Constants/action-types";

export function ToggleGridView(){
  return function(dispatch) {
    return dispatch({type:TOGGLE_GRIDVIEW, payload: true});
  }
}

export function ToggleSidebar(value){
  return function(dispatch) {
    return dispatch({type:TOGGLE_SIDEBAR, payload: value})
  }
}

export function ShowSpinner() {
  return function(dispatch) {
    return dispatch({type: UPDATE_SPINNER, payload: true });
  }
}

export function HideSpinner() {
  return function(dispatch) {
    return dispatch({type: UPDATE_SPINNER, payload: false });
  }
}