import { LOGIN_USER, LOGOUT_USER } from "../Constants/action-types";
import { sendSocketMessage } from '../../utils/WebsocketHelper'

export function LoginUser(guid) {
  sendSocketMessage(JSON.stringify({type:'userevent',event:'login',value:guid}))
  return function(dispatch){
    return dispatch({type: LOGIN_USER, payload: guid });
  }
}
  
export function LogoutUser(guid) {
  sendSocketMessage(JSON.stringify({type:'userevent',event:'logout',value:guid}))
  return function(dispatch){
    return dispatch({type: LOGOUT_USER, payload: guid });
  }
}