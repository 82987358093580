import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { getToken } from '../../utils/Common';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import defaultImgae from '../../images/defaultUserM.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { withTranslation } from 'react-i18next';
import './Avatar.scss'

const Avatar = props => {
  const [photo, setPhoto ] = useState(defaultImgae)
  const [online, setOnline ] = useState(false)
  const [position, setPosition] = useState('')

  useEffect(() => {
    if(props.guid){
      getMemberPhoto(props.guid)
      setOnline(props.connectedEmployees.includes(props.guid))
    }

    if(props.left)
      setPosition('absolute')

  },[props]);

  const getTooltip = (props) => (
    <Tooltip 
      id="button-tooltip"
      placement={props.placement}
    >
      {props}
    </Tooltip>
  )

  const getMemberPhoto = (guid) => {
    const token = getToken()
    if(token){
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/users/${guid}/photo?type=medium`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        }).then(response => {
          if(response.data.photo)
            setPhoto(response.data.photo)
      })
    }
  }

  if(props.align === 'right')
    return (
      <OverlayTrigger
        placement={props.placement}
        delay={{ show: 250, hide: 400 }}
        overlay={getTooltip(props.name, props.placement)}
      >
        <div onClick={props.onClick} className={"avatar-small"} style={{height: `${props.height}px`, width: `${props.height}px`, position: position, zIndex: (props.zIndex+props.index), right: `${props.left}px`}}>
          <img key={props.guid} src={photo} alt={props.name} style={{height: `${props.height}px`, width: `${props.height}px`, position: position, zIndex: (props.zIndex+props.index)}} className={"avatar-small"} />
          {online && (
            <div class="avatar-status-overlay" style={{zIndex: (props.zIndex+props.index+1)}}>
              <FontAwesomeIcon icon={faCheckCircle} style={{color: "#28a745", fontSize: "15px"}} />
            </div>
          )}
        </div>
      </OverlayTrigger>
    )
  else
  return (
    <OverlayTrigger
      placement={props.placement}
      delay={{ show: 250, hide: 400 }}
      overlay={getTooltip(props.name, props.placement)}
    >
      <div onClick={props.onClick} className={"avatar-small"} style={{height: `${props.height}px`, width: `${props.height}px`, position: position, zIndex: (props.zIndex+props.index), left: `${props.left}px`}}>
        <img key={props.guid} src={photo} alt={props.name} style={{height: `${props.height}px`, width: `${props.height}px`, position: position, zIndex: (props.zIndex+props.index)}} className={"avatar-small"} />
        {online && (
          <div class="avatar-status-overlay" style={{zIndex: (props.zIndex+props.index+1)}}>
            <FontAwesomeIcon icon={faCheckCircle} style={{color: "#28a745", fontSize: "15px"}} />
          </div>
        )}
      </div>
    </OverlayTrigger>
  )
}

const mapStateToProps = state => {
  return {
    connectedEmployees:state.connectedEmployees
  };
};

export default connect(mapStateToProps)(withTranslation()(Avatar))