import React from 'react';
import axios from 'axios';

import { connect } from "react-redux";
import { LoginUser,WhoIam } from "../../store/Actions/index";

import { Container, Row, Form, Col, Card, Button } from 'react-bootstrap';

import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from 'react-i18next';

class Registration extends React.Component{
  constructor(props){
    super(props)
    this.state = {
        mail: (typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.mail : ''),
        mailError: '',
        password: '',
        passwordConfirmation:'',
        passwordError: '',
        givenname: (typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.givenname : ''),
        givennameError: '',
        surname: (typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.surname : ''),
        surnameError: '',
        phone: '',
        phoneError: '',
        guid: uuidv4(),
        oAuthId: (typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.oAuthId : ''),
        oAuthProvider: (typeof this.props.location.authProps !== "undefined" ? this.props.location.authProps.oAuthProvider : ''),
        username: '',
        usernameError: '',
        error: false,
        registrationSuccess: false
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleUsernameChange = this.handleUsernameChange.bind(this);
    this.submitRegistration = this.submitRegistration.bind(this);
    this.submitBackToLogin = this.submitBackToLogin.bind(this);
  }

  validate = () => {
    let isError = false;
    const errors = {
      mailError: '',
      passwordError: '',
      givennameError: '',
      surnameError: '',
      usernameError: '',
      error: false
    }
    if(this.state.oAuthProvider === "custom"){
      if(typeof this.state.mail !== 'undefined'){
        if(this.state.mail.indexOf("@") < 1 ){
          isError = true;
          errors.error = true;
          errors.mailError = 'Geben Sie eine gültige Mailadresse ein!';
        }
      }
      else{
        isError = true;
        errors.error = true;
        errors.mailError = 'Geben Sie eine gültige Mailadresse ein!';
      }

  
      if(typeof this.state.password === 'undefined'){
        isError = true;
        errors.error = true;
        errors.passwordError = 'Geben Sie ein Passwort ein!';
      }else if(this.state.password !== this.state.passwordConfirmation){
        isError = true;
        errors.passwordError = 'Die Passwörter stimmen nicht überein!';
      }
    }

    if(typeof this.state.givenname === 'undefined'){
      isError = true;
      errors.error = true;
      errors.givennameError = 'Geben Sie einen Vornamen ein!';
    }

    if(typeof this.state.surname === 'undefined'){
      isError = true;
      errors.error = true;
      errors.surnameError = 'Geben Sie einen Namen ein!';
    }

    if(typeof this.state.username === 'undefined'){
      isError = true;
      errors.error = true;
      errors.usernameError = 'Geben Sie einen Benutzernamen ein!';
    }

    if(typeof this.state.username !== 'undefined'){
      if(this.state.usernameError.length > 1){
        isError = true;
        errors.error = true;
        errors.usernameError = 'Geben Sie einen Benutzernamen ein!';
      }
    }


    this.setState({
      ...this.state,
      ...errors
    })

    return isError;
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleUsernameChange(e){
    var attribute = e.target.id
    var value = e.target.value

    const err = {
      state:false, 
      message:''
    }

    if(value.length<=0){
      err.state = true
      err.message = '... zu kurz!!' 
    }

    if(!err.state){
      axios.put(`${window._env_.REACT_APP_SERVICE_API}/auth/register/`, {
        attribute:attribute,
        value:value
      }).then(response => {
        if(response.status === 200 && response.statusText === 'OK')
          this.setState({
            usernameError:''
          })
      }).catch(error => {
        this.setState({
          usernameError:'Der Benutzername ist nicht frei!'
        })
      })
    }

    this.setState({
      username:value
    })
  }

  submitRegistration(){
    const err = this.validate()
    if(!err){
      axios.post(`${window._env_.REACT_APP_SERVICE_API}/auth/register/`, {
        guid:this.state.guid,
        oAuthId:this.state.oAuthId,
        oAuthProvider:this.state.oAuthProvider,
        mail:this.state.mail,
        phone:this.state.phone,
        givenname:this.state.givenname,
        surname:this.state.surname,
        password:this.state.password,
        username:this.state.username
      }).then(response => {
        this.setState({
          registrationSuccess:true
        })
      }).catch(error => {
        console.log(error)
      });
    }
  }

  submitBackToLogin(){
    this.props.history.push('/')
  }

  render(){
    const {t} = this.props
    return(
      <>{this.state.registrationSuccess ? 
      <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className={"d-none d-md-flex col-md-4 col-lg-4 bg-image"}></div>
        <Col md={8} lg={8}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                  <Card.Body>
                    <Card.Text>
                      <h4>{t('Nearly shank')}!</h4>
                      {t('sentences.sendMail1')} {this.state.mail} {t('sentences.sendMail2')}
                    </Card.Text>
                    <Form.Group>
                      <Button 
                        variant="primary"
                        onClick={this.submitBackToLogin} >
                        {t('backLogin')}
                      </Button>
                    </Form.Group>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
      :
      <Row className={"no-gutter"} style={{marginLeft: "-15px", marginRight: "-15px"}}>
        <div className={"d-none d-md-flex col-md-4 col-lg-6 bg-image"}></div>
        <Col md={9} lg={6}>
          <div className={"login d-flex align-items-center py-5"}>
            <Container>
              <Row>
                <Col className={"mx-auto"} md={9} lg={8} >
                <h3>{t('Registration')},</h3>
                  <Card.Body>
                    <Form>
                      <Form.Row>
                        <Form.Group as={Col} controlId="givenname">
                          <Form.Label>{t('Givenname')}</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder={t('Givenname')}
                            value={this.state.givenname}
                            onChange={this.handleOnChange}
                            isInvalid={(this.state.givennameError.length > 1)}
                          />
                          <Form.Control.Feedback type="invalid">
                          {this.state.givennameError}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="surname">
                          <Form.Label>{t('Surname')}</Form.Label>
                          <Form.Control 
                            type="text" 
                            placeholder={t('Surname')}
                            value={this.state.surname}
                            onChange={this.handleOnChange}
                            isInvalid={(this.state.surnameError.length > 1)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.surnameError}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Form.Row>

                      <Form.Group controlId="mail">
                        <Form.Label>{t('Mail')}</Form.Label>
                        <Form.Control 
                          type="email" 
                          placeholder={t('Mail')}
                          value={this.state.mail}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.mailError.length > 1)}
                          disabled={(this.state.oAuthProvider !== "custom" ? true : false)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.mailError}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="username">
                        <Form.Label>{t('Username')}</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder={t('Username')}
                          value={this.state.username}
                          onChange={this.handleUsernameChange}
                          isInvalid={(this.state.usernameError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.usernameError}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="phone">
                        <Form.Label>{t('Phone')}</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder="Phone"
                          value={this.state.phone}
                          onChange={this.handleOnChange}
                          isInvalid={(this.state.phoneError.length > 1)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {this.state.phoneError}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {this.state.oAuthProvider === 'custom' &&
                      <Form.Row>
                        <Form.Group as={Col} controlId="password">
                          <Form.Label>{t('Password')}</Form.Label>
                          <Form.Control 
                            type="password" 
                            placeholder={t('Password')}
                            value={this.state.password}
                            onChange={this.handleOnChange}
                            isInvalid={(this.state.passwordError.length > 1)}
                          />
                          <Form.Control.Feedback type="invalid">
                            {this.state.passwordError}
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} controlId="passwordConfirmation">
                          <Form.Label>{t('Confirm password')}</Form.Label>
                          <Form.Control 
                            type="password" 
                            placeholder={t('Password')}
                            value={this.state.passwordConfirmation}
                            onChange={this.handleOnChange}
                            isInvalid={(this.state.passwordError.length > 1)}
                          />
                        </Form.Group>
                      </Form.Row>
                      }
                      <Form.Group id="datasecurity">
                        <Form.Check 
                          type="checkbox" 
                          label={t('sentences.datasecurity')}
                        />
                      </Form.Group>
                      
                      <Form.Group>
                        <Button 
                          variant="primary"
                          onClick={this.submitRegistration} >
                          {t('Done')}
                        </Button>{' '}

                        <Button 
                          variant="secondary"
                          onClick={this.submitBackToLogin} >
                          {t('Back')}
                        </Button>
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
     }</>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    authState: state.authState
  };
};

export default connect(mapStateToProps,{LoginUser,WhoIam})(withTranslation()(Registration));