import React, { useState } from 'react'
import { connect } from "react-redux"
import { useHistory } from "react-router-dom";
import { SearchBarSearch, SearchBarClear } from "../../store/Actions/index"
import { withTranslation } from 'react-i18next';
import { sort_by_key } from '../../utils/SorterHelper'
import { Nav } from 'react-bootstrap'
import SearchResult from './components/SearchResult'

import './SearchForm.scss'

const SearchForm = props => {

  const [showInput, setshowInput ] = useState(false)
  const [input, setInput ] = useState('')
  const history = useHistory()

  const handleOnClickButton = () => {
    if(showInput){
      props.SearchBarClear()
      setInput('')
    }
    setshowInput(!showInput)
  }

  const navigateTo = (route) => {
    props.SearchBarClear()
    setInput('')
    setshowInput(false)
    history.push(route)
  }

  const handleOnChange = (e) => {
    setInput(e.target.value)

    if (e.target.value.length > 2)
      props.SearchBarSearch(e.target.value)
  }

  const handleKeyDown = (e) => {
    if(input){
      if (e.key === 'Enter' && input.length > 2)
        props.SearchBarSearch(input)
    }
  }

  const renderResult = (result) => {
    var result = sort_by_key(result, 'type')
    var items = []
    for(var j = 0; j<result.length;j++){
      items.push(
        <SearchResult
          key={j}
          index={j}
          onClickResult={navigateTo}
          type={result[j].type}
          name={result[j].name}
          link={result[j].link}
          route={result[j].route}
        />
      )
    }
    return items
  }

  const {t} = props
  return(
    <div className={showInput ? "mr-0 search open" : "mr-0 search"}>
      <input type="search" className="search-box" value={input} onChange={handleOnChange} onKeyDown={handleKeyDown} placeholder={t('sentences.searchbar')} />
      <span className="search-button" onClick={handleOnClickButton}>
        <span className="search-icon"></span>
      </span>
      {props.searchResult && 
        <div className="mt-2 search open" style={{position: "fixed", height: "auto"}}>
          <Nav style={{flexDirection: "column"}} >
            {renderResult(props.searchResult)}
          </Nav>
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    searchResult: state.searchResult,
  };
};

export default connect(mapStateToProps,{SearchBarSearch, SearchBarClear})(withTranslation()(SearchForm));