import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/Common';
import { Media, Image } from 'react-bootstrap';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import defaultImgae from '../../images/defaultUserM.png'
import onlineImage from '../../images/online.png'
import { withTranslation } from 'react-i18next';

const UserCardSmall = props => {
  const [photo, setPhoto ] = useState(null)

  useEffect(() => {
    const token = getToken()
    if(token){
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/users/${props.guid}/photo?type=full`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        }).then(response => {
          setPhoto(response.data.photo)
      })
    }
  }, []);

  const {t} = props
  return(<>
    <ContextMenuTrigger id={'card-'+props.guid}>
      <Media
        className="border rounded-pill p-3 m-3 hover"
        style={{minWidth: "320px"}}
        onClick={()=>{props.onClickAccount(props.guid)}}
      >
        <div className="media mtb-auto">
          <Image
            width={64}
            height={64}
            src={photo ? photo : defaultImgae}
            alt={props.name}
            roundedCircle
          />
          {props.online && 
            <Image 
              width={64}
              height={64}
              alt={'isOnline'} 
              src={onlineImage}
              style={{position: "absolute"}}
            />
          }
          <Media.Body
            className="pl-3"
          >
            <h5><b>{props.name}</b></h5>
            <p>{props.company}</p>
          </Media.Body>
        </div>
      </Media>
    </ContextMenuTrigger>
    <ContextMenu id={'card-'+props.guid}>
      <MenuItem
        onClick={()=>{props.onDelete(props.guid)}}
      >
        {t('Remove')}
      </MenuItem>
    </ContextMenu></>
  );
}

export default (withTranslation()(UserCardSmall))