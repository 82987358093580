export const getDateMonthsBefore = function(date,nofMonths) {
  var thisMonth = date.getMonth();
  date.setMonth(thisMonth - nofMonths);
  if ((thisMonth - nofMonths < 0) && (date.getMonth() != (thisMonth + nofMonths))) {
    date.setDate(0);
  } 
  else if ((thisMonth - nofMonths >= 0) && (date.getMonth() != thisMonth - nofMonths)) {
    date.setDate(0);
  }
  return date;
}

export const getDateMonthsAfter = function(date,nofMonths) {
  var thisMonth = date.getMonth();
  date.setMonth(thisMonth + nofMonths);
  if ((thisMonth + nofMonths > 11) && (date.getMonth() != (thisMonth - nofMonths))) {
    date.setDate(0);
    date.setYear(date.getFullYear() + 1)
    date.setMonth(0)
  }
  else if ((thisMonth + nofMonths <= 11) && (date.getMonth() != (thisMonth + nofMonths))) {
    date.setDate(0);
  }
  return date;
}