import React from 'react';
import { IconEdit } from '../../../../assets/icons'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const AboutTab = (props) => {

  const {t} = props
  return(<>
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Job')} <b>{t('Description')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4} className={'text-right'}>
        <IconEdit 
          onClick={() => props.handleOnSelectDropdown("editJob")}
        />
      </Col>

      <Col md={12} lg={6}>
        <label>{t('jobTitle')}</label>
        {props.editJob ?
        <>
        <Form.Group controlId="jobTitle" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.jobTitle}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.jobTitle}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Department')}</label>
        {props.editJob ?
        <>
        <Form.Group controlId="department" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.department}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.department}</p>
        }
      </Col>

      {props.editJob ? 
      <>
      <Col md={12} lg={12}>
        <Form.Group>
          <Button onClick={() => props.handleOnCancel('editJob')} variant="secondary">{t('Cancel')}</Button>{' '}
          <Button onClick={props.handleOnSubmit} variant="primary" >{t('Save')}</Button>
        </Form.Group>
      </Col>
      </>
      :
      <>
      </>}
    </Row>

    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('About')} <b>{t('Me')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4} className={'text-right'}>
        <IconEdit 
          onClick={() => props.handleOnSelectDropdown("editAbout")}
        />
      </Col>
      <Col md={12} lg={12}>
        <label>{t('Description')}</label>
        {props.editAbout ? 
        <>
        <Form.Group controlId="about" >
          <Form.Control as="textarea" onChange={props.handleOnChange} defaultValue={props.user.about}/>
        </Form.Group>
        <Form.Group>
          <Button onClick={() => props.handleOnCancel('editAbout')} variant="secondary">{t('Cancel')}</Button>{' '}
          <Button onClick={props.handleOnSubmit} variant="primary" >{t('Save')}</Button>
        </Form.Group>  
        </>      
        :
        <p className="font-weight-bold" >{props.user.about}</p>}
      </Col>
    </Row>
  </>)
}

export default (withTranslation()(AboutTab));