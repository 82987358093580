import React, { useEffect } from 'react';

import { connect } from "react-redux";
import { ToggleGridView, ToggleSidebar } from './store/Actions/index'

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import {isMobile} from 'react-device-detect';

import PrivateRoute from './utils/PrivateRoute';
import PublicRoute from './utils/PublicRoute';

import Layout from './layout/Layout';
import { Loader, NotFound, Dialog, Dashboard } from './components'
import SessionExpired from './components/Login/SessionExpired';

import Login from './components/Login/Login';
import Registration from './components/Login/Registration';
import ForgottenPassword from './components/Login/ForgottenPassword';
import ResetPassword from './components/Login/ResetPassword';
import CodeVerification from './components/Login/CodeVerification';
import Profile from './components/IdentityManagement/Profile';

import Account from './components/IdentityManagement/Account';
import Customers from './components/ProjectTracking/Pages/Customers';
import Customer from './components/ProjectTracking/Pages/Customer';
import Project from './components/ProjectTracking/Pages/Project';
import Expenses from './components/ProjectTracking/Pages/Expenses';
import Work from './components/ProjectTracking/Pages/Work';
import Employees from './components/IdentityManagement/Accounts';
import TimeSheets from './components/ProjectTracking/Pages/TimeSheets';

import KanbanHub from './components/Kanban/KanbanHub';
import Kanban from './components/Kanban/Kanban';

import withClearCache from "./ClearCache";

import './App.scss';
import './fonts/index.css';

const ClearCacheComponent = withClearCache(MainApp);

function App(props) {

  useEffect(() => {
    if (isMobile){
      props.ToggleSidebar()
      props.ToggleGridView()
    }
      
  }, []);

  return <ClearCacheComponent />
}

function MainApp(props) {
  return(
    <BrowserRouter >
      <Layout>
        <Loader/>
        <Dialog/>
        <Switch>
          <PublicRoute exact path='/' component={Login} />
          <PublicRoute path='/registration' component={Registration} />
          <PublicRoute path='/forgottenPassword' component={ForgottenPassword} />
          <PublicRoute path='/resetPassword' component={ResetPassword} />
          <PublicRoute path='/codeVerification' component={CodeVerification} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <PrivateRoute path='/profile' component={Profile} />
          <PrivateRoute path='/customers' component={Customers} />
          <PrivateRoute path='/customer/:urlPath' component={Customer} />
          <PrivateRoute path='/project/:urlPath' component={Project} />
          <PrivateRoute path='/expenses' component={Expenses} />
          <PrivateRoute path='/work' component={Work} />
          <PrivateRoute path='/employees' component={Employees} />
          <PrivateRoute path='/employee/:urlPath' component={Account} />
          <PrivateRoute path='/timesheets' component={TimeSheets} />
          <PrivateRoute exact path='/planner' component={KanbanHub} />
          <PrivateRoute path='/planner/kanban/:urlPath' component={Kanban} />
          <Route path='/expired' component={SessionExpired} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
}

const mapStateToProps = state => {
  return {
    authState: state.authState,
  };
};

export default connect(mapStateToProps,{ ToggleGridView, ToggleSidebar })(App);