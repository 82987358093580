import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../Reducers/index";
import refreshMSAL from "../Middleware/refreshMSAL"

import thunk from "redux-thunk";

const storeEnhancers = compose;

const store = createStore(rootReducer, storeEnhancers(applyMiddleware(thunk,refreshMSAL)));

export default store;