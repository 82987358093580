import React from 'react';
import { IconEdit } from '../../../../assets/icons'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const ProfileTab = (props) => {

  const {t} = props
  return(<>
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Basic')} <b>{t('Information')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4} className={'text-right'}>
        <IconEdit 
          onClick={() => props.handleOnSelectDropdown("editBasic")}
        />
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Givenname')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="givenname" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.givenname}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.givenname}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Surname')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="surname" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.surname}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.surname}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Mail')}</label>
        <p className="font-weight-bold" >{props.user.mail}</p>
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Phone')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="phone" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.phone}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.phone}</p>
        }
      </Col>
      {props.edit ? 
      <>
      <Col md={12} lg={12}>
        <Form.Group>
          <Button onClick={() => props.handleOnCancel('editBasic')} variant="secondary">{t('Cancel')}</Button>{' '}
          <Button onClick={props.handleOnSubmit} variant="primary" >{t('Save')}</Button>
        </Form.Group>
      </Col>
      </>
      :
      <>
      </>}
    </Row>
    <Row className='mt-25'>
      <Col md={12} lg={12} xs={12}>
        <h4>{t('Employee')} <b>{t('Information')}</b></h4>
      </Col>
      <Col md={12} lg={6}>
        <label>{t('guid')}</label>
        <p className="font-weight-bold" >{props.user.guid}</p>
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Username')}</label>
        <p className="font-weight-bold" >{props.user.username}</p>
      </Col>
    </Row>
  </>)
}

export default (withTranslation()(ProfileTab));