import React from 'react'
import { connect } from "react-redux"

import { WhoIam, ShowSpinner, ListKanban, ListMyKanbanTask } from "../../store/Actions/index"

import { Row, Col, Card, OverlayTrigger, Popover, Button } from 'react-bootstrap'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import NewBoard from './components/NewBoard'
import TaskBucket from './components/TaskBucket'
import Avatar from '../Avatar/Avatar'
import { withTranslation } from 'react-i18next'

class KanbanHub extends React.Component {
  constructor(props){
    super(props)
    this.state=({
      showModal:false
    })
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount(){
    this.props.ShowSpinner()
    this.props.ListKanban()
    this.props.ListMyKanbanTask()
  }

  toggleModal(e){
    this.setState({
      showModal:e
    })
  }

  showBoard(e){
    if(this.props.history)
      this.props.history.push('/planner/kanban/'+e)
  }

  renderKanban(){
    const {t} = this.props
    var boards = []
    this.props.kanbanBoards.forEach(element => {
      var avatars = []
      let members = JSON.parse(element.members)
      if(members){
        let moveLeft = 10
        members.forEach( function(attendee, index) {
          if(index < 6){
            avatars.push(
              <Avatar
                key={attendee.guid}
                guid={attendee.guid}
                name={attendee.name}
                zIndex={100}
                index={index}
                left={moveLeft}
              />
            )
            moveLeft += 15
          }
        })
      }
      boards.push(
        <Col md={6} lg={3} xs={12} key={element.id}>
          <Card
            bg={'success'}
            border={'success'}
            text={'white'}
            className={'hover'}>
            <Card.Body>
              <div className={'rotate'}>
                <FontAwesomeIcon icon={faCalendarAlt} size={'3x'}/>
              </div>
              <h6 className="text-uppercase">{t(element.class)}</h6>
              <h1 onClick={()=>this.showBoard(element.id)} className="text-left">{element.title}</h1>
              <div style={{minHeight: "35px"}}>
                {avatars}
              </div>
            </Card.Body>
          </Card>
        </Col>
      )
    })
    return boards
  }

	render(){
    const {t} = this.props
		return(<>
      <Card>
        <Row className={"table-title"}>
          <Col md={8} lg={8} xs={8}>
            <h2>{t('Kanban')} <b>{t('Hub')}</b></h2>
            <p>{t('sentences.kanbanhub')}</p>
          </Col>
          <Col md={4} lg={4} xs={4}>
            <Button 
              variant="outline-secondary"
              onClick={()=>this.toggleModal(true)} >
              <FontAwesomeIcon icon={faPlus} />
              {' '}{t('New Planner')}
            </Button>
          </Col>
        </Row>
        <Row className={'mt-25'}>
          {this.props.kanbanBoards && (this.renderKanban())}
        </Row>
      </Card>
      <Card>
        <Row className={"table-title"}>
          <Col md={8} lg={8} xs={8}>
            <h2>{t('My')} <b>{t('Tasks')}</b></h2>
          </Col>
          <Col md={4} lg={4} xs={4}>

          </Col>
        </Row>
        <TaskBucket 
          myTasks={this.props.myTasks}
        />
      </Card>

      <NewBoard
        show={this.state.showModal}
        owner={this.props.user.guid}
        kanbanClass={'personally'}
        onHide={()=>this.toggleModal(false)}
      />
		</>);
	}
}

const mapStateToProps = state => {
  return {
    kanbanBoards: state.kanbanBoards,
    myTasks: state.myTasks,
    user: state.user
  };
};

export default connect(mapStateToProps,{WhoIam, ShowSpinner, ListKanban, ListMyKanbanTask})(withTranslation()(KanbanHub));