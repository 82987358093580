import React from 'react';
import { Image, Navbar } from 'react-bootstrap';
import { SearchForm } from '../../components'
import { IconBurger } from '../../assets/icons'

const Header = (props) => {

  return(
    <header>
      <div className="header-darkblue" ></div>
      <Navbar collapseOnSelect expand="lg" className={'fixed-top'} sticky="top" bg="white" style={{ fontFamily: "'Maersk Text' !important", fontWeight: 'bold'}} >
        <Navbar.Brand >
          <div className={'d-flex align-items-center'}>
            <IconBurger
              className={'pr-2'}
              onClick={props.toggleSidebar}
            />
            <a href={`${window._env_.PUBLIC_URL}/`}><Image className="header-logo" src={props.logo} alt="FirstAttribute AG" /></a>
          </div>
        </Navbar.Brand>
        <SearchForm />
        <div style={{flex: "none"}} className='d-none d-md-block d-lg-block'>
          <Image style={{width: '40px', height: '40px'}} src={props.photo} roundedCircle />
          <span>{props.givenname} {props.surname}</span>
        </div>
      </Navbar>
    </header>
  );
}

export default (Header);