import React from 'react'
import { Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTasks, faUserAlt, faProjectDiagram, faBuilding, faColumns } from '@fortawesome/free-solid-svg-icons'

const SearchResult = props => {
  return(
    <Nav.Link eventKey={props.index} onClick={()=>props.onClickResult(`/${props.route}/${props.link}`)}>
      {props.type === 'employee' && <FontAwesomeIcon icon={faUserAlt}/>}
      {props.type === 'task' && <FontAwesomeIcon icon={faTasks}/>} 
      {props.type === 'project' && <FontAwesomeIcon icon={faProjectDiagram}/>} 
      {props.type === 'customer' && <FontAwesomeIcon icon={faBuilding}/>} 
      {props.type === 'kanban' && <FontAwesomeIcon icon={faColumns}/>} 
      {' '}{props.name}
    </Nav.Link>
  );
}

export default (SearchResult)