import React from 'react';
import { Row, Col, Form } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const NotificationTab = (props) => {

  const {t} = props
  return(<>
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Email')} <b>{t('Delivery')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4}>

      </Col>
      <Col md={12} lg={6}>
        <label>{t('Projects and Customers')}</label>
          <Form.Group>
            <Form.Check
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('customerMember'))}
              type="switch"
              id="customerMember"
              label={t('sentences.customerMember')}
              onClick={props.handleOnChange}
            />
            <Form.Check
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('projectMember'))}
              type="switch"
              id="projectMember"
              label={t('sentences.projectMember')}
              onClick={props.handleOnChange}
            />
          </Form.Group>
      </Col>

      <Col md={12} lg={6}>
        <label>{t('Tasks Notifications')}</label>
          <Form.Group>
            <Form.Check
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('kanbanMember'))}
              type="switch"
              id="kanbanMember"
              label={t('sentences.kanbanMember')}
              onClick={props.handleOnChange}
            />
            <Form.Check
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('removeKanbanMember'))}
              type="switch"
              id="removeKanbanMember"
              label={t('sentences.removeKanbanMember')}
              onClick={props.handleOnChange}
            />
            <Form.Check
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('taskMember'))}
              type="switch"
              id="taskMember"
              label={t('sentences.taskMember')}
              onClick={props.handleOnChange}
            />
            <Form.Check
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('removeTaskMember'))}
              type="switch"
              id="removeTaskMember"
              label={t('sentences.removeTaskMember')}
              onClick={props.handleOnChange}
            />
            <Form.Check 
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('taskComplete'))}
              type="switch"
              id="taskComplete"
              label={t('sentences.taskComplete')}
              onClick={props.handleOnChange}
            />
            <Form.Check 
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('taskComment'))}
              type="switch"
              id="taskComment"
              label={t('sentences.taskComment')}
              onClick={props.handleOnChange}
            />
            <Form.Check 
              defaultChecked={props.userEmailNotification.mailSubscriptions && (props.userEmailNotification.mailSubscriptions.includes('taskReminder'))}
              type="switch"
              id="taskReminder"
              label={t('sentences.taskReminder')}
              onClick={props.handleOnChange}
            />
          </Form.Group>
      </Col>
    </Row>
  </>)
}

export default (withTranslation()(NotificationTab));