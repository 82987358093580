import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faExclamation, faAmbulance, faSortAmountDown, faComments } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from 'react-bootstrap';
import Avatar from '../../Avatar/Avatar'
import { withTranslation } from 'react-i18next';

function importAll(r) {
  return r.keys().map(r)
}

const images = importAll(require.context('../images/avatars', false, /\.(png|jpe?g|svg)$/))

const KanbanTask = props => {

  const renderTaskMembers = (members) =>{
    var avatars = []
    members.forEach( function(attendee, index) {
      avatars.push(
        <Avatar
          key={attendee.guid}
          guid={attendee.guid}
          name={attendee.name}
        />
      )
    })
    return avatars
  }

  const {t} = props
  return (
    <div
      key={props.project.id}
      className={'kanban-card'}
      draggable={true}
      onDragEnd={(e) => {props.onDragEnd(e, props.project);}}
      onClick={(e) => {props.onEditTask(e, props.project);}}
    >
      <div>
        <Row>
          <Col md={12} lg={12} xs={12}>
            <h5>{props.project.title}</h5>
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={12} lg={12} xs={12}>
            {props.project.priority === 'Important' && (<FontAwesomeIcon className={'text-danger'} icon={faExclamation} />)}
            {props.project.priority === 'Urgent' && (<FontAwesomeIcon className={'text-danger'} icon={faAmbulance} />)}
            {props.project.priority === 'Irrelevant' && (<FontAwesomeIcon className={'text-primary'} icon={faSortAmountDown} />)}
            {'  '}
            {props.project.state === 'Completed' && (<FontAwesomeIcon className={'text-success'} icon={faCircle} />)}
            {props.project.state === 'Not started' && (<FontAwesomeIcon className={'text-warning'} icon={faCircle} />)}
            {props.project.state === 'In work' && (<FontAwesomeIcon className={'text-info'} icon={faCircle} />)}
            {t(props.project.state)}
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={12} lg={12} xs={12}>
            <div style={{display: "flex"}}>
              {props.project.members &&(renderTaskMembers(JSON.parse(props.project.members)))}
              {props.project.hasComments === 'true' && (<div style={{flex:1}} className={'text-right'}><FontAwesomeIcon className={'text-primary'} icon={faComments}/></div>)}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );

}

export default (withTranslation()(KanbanTask));