import { LOGIN_USER, LOGOUT_USER, LOGIN_CHANGE, GET_ROLE, GET_USER, UPDATE_USER, UPDATE_SPINNER, UPDATE_DIALOG, RESET_ERROR, TOGGLE_GRIDVIEW, SET_ME_NOTIFICATION } from "../Constants/action-types";
import { LIST_CUSTOMERS, SELECT_CUSTOMER, GET_CUSTOMER, HIDE_CUSTOMER, ADD_USER, INSERT_PROJECT, GET_PROJECT, LIST_EMPLOYEES, SELECT_EMPLOYEE, UPDATE_EMPLOYEE, GET_CUSTOMER_HOUR_EVALUATION, GET_PROJECT_HOUR_EVALUATION } from "../Constants/action-types";
import { TOGGLE_SIDEBAR } from "../Constants/action-types";
import { LIST_WORKRECORDS, REMOVE_WORKRECORD,INSERT_WORKRECORD, UPDATE_WORKRECORD, SELECT_WORKRECORD, HIDE_WORKRECORD } from "../Constants/action-types";
import { LIST_EXPENSES, INSERT_EXPENSES, REMOVE_EXPENSES } from "../Constants/action-types";
import { LIST_TIMESHEETS, INSERT_TIMESHEET, REMOVE_TIMESHEET,UPDATE_TIMESHEET, SIGN_TIMESHEET } from "../Constants/action-types";
import { SHOW_SIGNATUREBOARD, HIDE_SIGNATUREBOARD } from "../Constants/action-types";
import { LIST_KANBAN, GET_KANBAN, INSERT_KANBAN, REMOVE_KANBAN, DESTROY_KANBAN, UPDATE_KANBAN, LIST_KANBANBUCKET, INSERT_KANBANBUCKET, REMOVE_KANBANBUCKET, UPDATE_KANBANBUCKET, LIST_KANBANTASK, INSERT_KANBANTASK, REMOVE_KANBANTASK, UPDATE_KANBANTASK, GET_KANBANTASK, DESTROY_KANBANTASK, LIST_KANBANCOMMENT, INSERT_KANBANCOMMENT, LIST_MY_KANBANTASK, LIST_MY_NEXT_KANBANTASK, LIST_CUSTOMER_KANBANTASK } from "../Constants/action-types";

import { SEARCH_SET_RESULT } from "../Constants/action-types";

import { sort_by_key, sort_by_key_desc } from '../../utils/SorterHelper';

import { } from '../../utils/Common'

const initialState = {
  user: {},
  authState: false,
  userRole:  null,
  userEmailNotification: [],
  showSpinner: false,
  gridView: false,
  sidebarView: true,

  customers: [],
  thisCustomer: {},
  thisCustomerHourEvaluation: [],

  customerModalShow: false,
  addUserModalShow: false,
  projects: [],
  thisProject: {},
  thisProjectHourEvaluation: [],

  expenses: [],

  workRecords: [],
  thisWorkRecord: null,
  workRecordModalShow: false,

  connectedEmployees: [],
  employees: [],
  thisEmployee: {},

  timeSheets: [],
  timeSheetToSign: null,
  signModalShow: false,

  kanbanBoards: [],
  kanbanBoard: null,
  kanbanBuckets: [],
  kanbanTasks: [],
  kanbanTask: null,
  kanbanTaskComments: [],
  customerTasks: [],
  myTasks: [],
  myNextTasks: [],

  error: {
    show:false,
    code:null,
    message:null,
  },

  dialog: {
    show: false,
    title:'',
    message:'',
  },

  searchResult: null
};

function rootReducer(state = initialState, action) {

  if (action.type === SEARCH_SET_RESULT) {
    return Object.assign({},state, {
      searchResult: action.payload
    });
  }

  if (action.type === UPDATE_DIALOG) {
    return Object.assign({},state, {
      dialog: action.payload
    });
  }
  
  if (action.type === TOGGLE_SIDEBAR) {
    return Object.assign({},state, {
      sidebarView: !state.sidebarView
    })
  }

  if (action.type === TOGGLE_GRIDVIEW) {
    return Object.assign({},state, {
      gridView: !state.gridView
    });
  }

  if (action.type === RESET_ERROR) {
    return Object.assign({},state, {
      error: action.payload
    });
  }

  if (action.type === UPDATE_SPINNER) {
    return Object.assign({},state, {
      showSpinner: action.payload
    });
  }

  if (action.type === LOGIN_USER) {
    return Object.assign({},state, {
      authState: true
    });
  }

  if (action.type === LOGOUT_USER) {
    return Object.assign({},state, {
      authState: false
    });
  }

  if (action.type === LOGIN_CHANGE){
    let users =[]
    Object.keys(action.payload).forEach(function(key) {
      users.push(key, action.payload[key])
    })
    return Object.assign({},state, {
      connectedEmployees: users
    });
  }

  if (action.type === GET_ROLE) {
    return Object.assign({},state, {
      userRole: action.payload
    });
  }

  if (action.type === SET_ME_NOTIFICATION) {
    return Object.assign({},state, {
      userEmailNotification: action.payload
    });
  }

  if (action.type === LIST_KANBAN) {
    return Object.assign({},state, {
      kanbanBoards: action.payload,
      showSpinner: false
    });
  }

  if (action.type === GET_KANBAN) {
    return Object.assign({},state, {
      kanbanBoard: action.payload,
      showSpinner: false
    });
  }

  if (action.type === INSERT_KANBAN) {
    let newArray = [...state.kanbanBoards]
    newArray.push(action.payload)

    return Object.assign({}, state, {
      kanbanBoards:newArray,
      showSpinner: false
    })
  }

  if (action.type === LIST_KANBANCOMMENT) {
    return Object.assign({},state, {
      kanbanTaskComments: action.payload,
      showSpinner: false
    });
  }

  if (action.type === INSERT_KANBANCOMMENT) {
    let newArray = []
    try{ newArray = [...state.kanbanTaskComments] }
    catch{ newArray = [] }

    newArray.push(action.payload)
    return Object.assign({}, state, {
      kanbanTaskComments:sort_by_key_desc(newArray, 'commentTime'),
      showSpinner: false
    })
  }

  if (action.type === UPDATE_KANBAN) {
    const elementsIndex = state.kanbanBoards.findIndex(element => element.id === action.payload.id)
    var oldKanban = state.kanbanBoards.find(element => element.id === action.payload.id)

    if(oldKanban)
    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldKanban[key] = action.payload[key]
    });

    let newArray = [...state.kanbanBoards]
    if(oldKanban)
      newArray[elementsIndex] = oldKanban
    else
    newArray[elementsIndex] = action.payload
    return Object.assign({}, state, {
      kanbanBoards:newArray,
      kanbanBoard:action.payload,
      showSpinner: false
    })
  }

  if (action.type === LIST_KANBANBUCKET) {
    return Object.assign({},state, {
      kanbanBuckets: sort_by_key(action.payload, 'stage'),
      showSpinner: false
    });
  }

  if (action.type === INSERT_KANBANBUCKET) {
    let newArray = [...state.kanbanBuckets]
    newArray.push(action.payload)

    return Object.assign({}, state, {
      kanbanBuckets:sort_by_key(newArray, 'stage'),
      showSpinner: false
    })
  }

  if (action.type === REMOVE_KANBANBUCKET) {
    return Object.assign({}, state, {
      kanbanBuckets:state.kanbanBuckets.filter(element=> element.id !== action.payload),
      showSpinner: false
    })
  }

  if (action.type === UPDATE_KANBANBUCKET) {
    const elementsIndex = state.kanbanBuckets.findIndex(element => element.id === action.payload.id)
    var oldBucket = state.kanbanBuckets.find(element => element.id === action.payload.id)

    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldBucket[key] = action.payload[key]
    });

    let newArray = [...state.kanbanBuckets]
    newArray[elementsIndex] = oldBucket
    return Object.assign({}, state, {
      kanbanBuckets:sort_by_key(newArray, 'stage'),
      showSpinner: false
    })
  }

  if (action.type === LIST_MY_NEXT_KANBANTASK) {
    return Object.assign({},state, {
      myNextTasks: sort_by_key(action.payload, 'state'),
      showSpinner: false
    });
    
  }

  if (action.type === LIST_MY_KANBANTASK) {
    return Object.assign({},state, {
      myTasks: sort_by_key(action.payload, 'state'),
      showSpinner: false
    });
  }

  if (action.type === LIST_CUSTOMER_KANBANTASK) {
    return Object.assign({},state, {
      customerTasks: sort_by_key(action.payload, 'state'),
      showSpinner: false
    });
  }

  if (action.type === LIST_KANBANTASK) {
    return Object.assign({},state, {
      kanbanTasks: sort_by_key(action.payload, 'state'),
      showSpinner: false
    });
  }

  if (action.type === INSERT_KANBANTASK) {
    let newArray = [...state.kanbanTasks]
    newArray.push(action.payload)

    return Object.assign({}, state, {
      kanbanTasks:sort_by_key(newArray, 'state'),
      showSpinner: false
    })
  }

  if (action.type === UPDATE_KANBANTASK) {
    const elementsIndex = state.kanbanTasks.findIndex(element => element.id === action.payload.id)
    const myElementsIndex = state.myTasks.findIndex(element => element.id === action.payload.id)
    const myNextElementsIndex = state.myNextTasks.findIndex(element => element.id === action.payload.id)
    const customerElementsIndex = state.customerTasks.findIndex(element => element.id === action.payload.id)

    let newArray = [...state.kanbanTasks]
    newArray[elementsIndex] = action.payload

    let myArray = [...state.myTasks]
    myArray[myElementsIndex] = action.payload

    let myNextArray = [...state.myNextTasks]
    myNextArray[myNextElementsIndex] = action.payload

    let customerArray = [...state.customerTasks]
    customerArray[customerElementsIndex] = action.payload

    return Object.assign({}, state, {
      kanbanTasks:sort_by_key(newArray, 'state'),
      myTasks:sort_by_key(myArray, 'state'),
      myNextTasks:sort_by_key(myNextArray, 'state'),
      customerTasks:sort_by_key(customerArray, 'state'),
      kanbanTask:action.payload,
      showSpinner: false
    })
  }

  if (action.type === GET_KANBANTASK) {
    return Object.assign({}, state, {
      kanbanTask: action.payload,
      showSpinner: false
    });
  }

  if (action.type === REMOVE_KANBAN) {
    return Object.assign({}, state, {
      kanbanBoards:state.kanbanBoards.filter(element=> element.id !== action.payload),
      showSpinner: false
    })
  }

  if (action.type === REMOVE_KANBANTASK) {
    return Object.assign({}, state, {
      kanbanTasks:state.kanbanTasks.filter(element=> element.id !== action.payload),
      showSpinner: false
    })
  }

  if (action.type === DESTROY_KANBANTASK) {
    return Object.assign({}, state, {
      kanbanTask:null,
      kanbanTaskComments:null,
      showSpinner: false
    })
  }

  if (action.type === DESTROY_KANBAN) {
    return Object.assign({}, state, {
      kanbanBoard:null,
      showSpinner: false
    })
  }

  if (action.type === GET_USER) {
    return Object.assign({}, state, {
      user: action.payload,
      showSpinner: false
    });
  }

  if (action.type === UPDATE_USER) {
    Object.assign(state.user, action.payload)
    return Object.assign({},state, {
      user:Object.assign(action.payload, state.user),
      showSpinner: false
    })
  }

  if (action.type === LIST_CUSTOMERS) {
    return Object.assign({}, state, {
      customers: action.payload,
      showSpinner: false
    });
  }

  if (action.type === LIST_WORKRECORDS) {
    return Object.assign({}, state, {
      workRecords: action.payload,
      showSpinner: false
    });
  }

  if (action.type === REMOVE_WORKRECORD) {
    return Object.assign({}, state, {
      workRecords:state.workRecords.filter(workRecords=> workRecords.Id !== action.payload),
      showSpinner: false
    })
  }

  if (action.type === INSERT_WORKRECORD) {
    let newArray = [...state.workRecords]
    newArray.unshift(action.payload)
    return Object.assign({}, state, {
      workRecords:newArray,
      showSpinner: false
    })
  }

  if (action.type === UPDATE_WORKRECORD) {
    const elementsIndex = state.workRecords.findIndex(element => element.Id === action.payload.Id)

    let newArray = [...state.workRecords]
    newArray[elementsIndex] = action.payload
    return Object.assign({}, state, {
      workRecords:newArray,
      showSpinner: false
    })
  }

  if (action.type === SELECT_WORKRECORD) {
    if(action.payload){
      return Object.assign({},state, {
        thisWorkRecord:state.workRecords.filter(element=> element.Id === action.payload),
      })
    }
    else{
      return Object.assign({},state, {
        thisWorkRecord: null
      })
    }
  }

  if (action.type === HIDE_WORKRECORD) {
    return Object.assign({},state, {
      workRecordModalShow: false,
      thisWorkRecord: null
    })
  }

  if (action.type === SHOW_SIGNATUREBOARD) {
    return Object.assign({},state, {
      timeSheetToSign: action.payload,
      signModalShow: true,
    })
  }

  if (action.type === HIDE_SIGNATUREBOARD) {
    return Object.assign({},state, {
      timeSheetToSign : null,
      signModalShow: false,
    })
  }

  if (action.type === SIGN_TIMESHEET) {
    return Object.assign({},state, {
      timeSheetToSign : action.payload,
      signModalShow: true,
    })
  }

  if (action.type === LIST_EMPLOYEES) {
    return Object.assign({}, state, {
      employees: action.payload,
      showSpinner: false
    });
  }

  if (action.type === SELECT_EMPLOYEE) {
    return Object.assign({},state, {
      thisEmployee: action.payload,
      showSpinner: false
    })
  }

  if (action.type === UPDATE_EMPLOYEE) {
    const elementsIndex = state.employees.findIndex(element => element.guid === action.payload.guid)
    var oldEmployee = state.employees.find(element => element.guid === action.payload.guid)

    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldEmployee[key] = action.payload[key]
    });

    let newArray = [...state.employees]
    newArray[elementsIndex] = oldEmployee
    return Object.assign({}, state, {
      employees:newArray,
      thisEmployee:oldEmployee,
      showSpinner: false
    })
  }

  if (action.type === INSERT_PROJECT) {
    const elementsIndex = state.customers.findIndex(element => element.Id === action.payload.Customers_fk)
    var oldCustomer = state.thisCustomer

    if(oldCustomer.Projects)
      oldCustomer.Projects.push(action.payload)
    else
      oldCustomer.Projects = [action.payload]

    let newArray = [...state.customers]
    newArray[elementsIndex] = oldCustomer
    return Object.assign({}, state, {
      customers:newArray,
      thisCustomer:oldCustomer,
      showSpinner: false
    })
  }

  if (action.type === LIST_EXPENSES) {
    return Object.assign({}, state, {
      expenses: action.payload,
      showSpinner: false
    });
  }

  if (action.type === INSERT_EXPENSES) {
    let newArray = [...state.expenses]
    newArray.unshift(action.payload)

    return Object.assign({}, state, {
      expenses:newArray,
      showSpinner: false
    })
  }

  if (action.type === REMOVE_EXPENSES) {
    return Object.assign({}, state, {
      expenses:state.expenses.filter(expenses=> expenses.Id !== action.payload),
      showSpinner: false
    })
  }

  if (action.type === LIST_TIMESHEETS) {
    return Object.assign({}, state, {
      timeSheets: action.payload,
      showSpinner: false
    })
  }

  if (action.type === INSERT_TIMESHEET) {
    let newArray = [...state.timeSheets]
    newArray.unshift(action.payload)

    return Object.assign({}, state, {
      timeSheets:newArray,
      showSpinner: false
    })
  }

  if (action.type === REMOVE_TIMESHEET) {
    return Object.assign({}, state, {
      timeSheets:state.timeSheets.filter(element=> element.id !== action.payload),
      showSpinner: false
    })
  }

  if (action.type === UPDATE_TIMESHEET) {
    const elementsIndex = state.timeSheets.findIndex(element => element.id === action.payload.id)
    var oldTimeSheet = state.timeSheets.find(element => element.id === action.payload.id)

    Object.keys(action.payload).forEach(function eachKey(key) { 
      oldTimeSheet[key] = action.payload[key]
    });

    let newArray = [...state.timeSheets]
    newArray[elementsIndex] = oldTimeSheet
    return Object.assign({}, state, {
      timeSheets:newArray,
      showSpinner: false
    })
  }

  if (action.type === SELECT_CUSTOMER) {
    if(action.payload){
      return Object.assign({},state, {
        thisCustomer:state.customers.filter(element=> element.Id === action.payload),
        customerModalShow: true
      })
    }
    else{
      return Object.assign({},state, {
        customerModalShow: true,
        thisCustomer: {}
      })
    }
  }

  if (action.type === GET_CUSTOMER) {
    return Object.assign({}, state, {
      thisCustomer: action.payload,
      showSpinner: false
    })
  }

  if (action.type === GET_CUSTOMER_HOUR_EVALUATION) {
    return Object.assign({}, state, {
      thisCustomerHourEvaluation: action.payload,
      showSpinner: false
    })
  }

  if (action.type === GET_PROJECT) {
    return Object.assign({}, state, {
      thisProject: action.payload,
      showSpinner: false
    })
  }

  if (action.type === GET_PROJECT_HOUR_EVALUATION) {
    return Object.assign({}, state, {
      thisProjectHourEvaluation: action.payload,
      showSpinner: false
    })
  }

  if (action.type === HIDE_CUSTOMER) {
    return Object.assign({},state, {
      customerModalShow: action.payload
    })
  }

  if (action.type === ADD_USER) {
    return Object.assign({},state, {
      addUserModalShow: action.payload
    })
  }

  return state;
}

export default rootReducer;