import React, { Component } from 'react';
import config from '../../../config/Settings.json'
import { connect } from "react-redux";
import { ShowSpinner, ListExpenses } from "../../../store/Actions/index";
import { getToken } from '../../../utils/Common';
import { Row, Col, Table, Button, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import ExpensesTableRow from '../components/TableRows/ExpensesTableRow';
import ExpensesModal from '../components/Modals/ExpensesModal';
import { withTranslation } from 'react-i18next';
import '../ProjectTracking.css';

class Expenses extends Component{
  constructor(props) {
    super(props);
    this.state = {
      modalShow:false,
      tableStartIndex:0
    }
    this.setNewModalShow = this.setNewModalShow.bind(this);
    this.renderTable = this.renderTable.bind(this);
    this.setTablePignation = this.setTablePignation.bind(this);
  }

  setNewModalShow(view){
    this.setState({
      modalShow: view,
    });
  }

  setTablePignation(op){
    var value = this.state.tableStartIndex
    if(op==='+')
      value = value+config.maxTableRows
    
    if(op==='-')
      value = value-config.maxTableRows
    
    this.props.ShowSpinner()
    this.props.ListExpenses(value,config.maxTableRows)
    this.setState({
      tableStartIndex:value
    })
  }

  componentDidMount(){
    if(getToken()){
      this.props.ShowSpinner()
      this.props.ListExpenses()
    }
  }

  renderTable(){
    var items = []
    for(var j = 0; j<this.props.expenses.length;j++){
      items.push(<ExpensesTableRow
        key={this.props.expenses[j].Id}
        Expenses={this.props.expenses[j]}
        Id={this.props.expenses[j].Id}
        Date={this.props.expenses[j].Date}
        TotalCost={this.props.expenses[j].TotalCost}
        Type={this.props.expenses[j].Type}
        Description={this.props.expenses[j].Description}
        VoucherNumber={this.props.expenses[j].VoucherNumber}
        FileLink={this.props.expenses[j].FileLink}>
      </ExpensesTableRow>)
    }
    return items;
  }

  render(){
    const {t} = this.props
    return(
      <>
      <Row className={"table-title"}>
        <Col xs={5}>
          <h2>{t('Expenses')} <b>{t('Management')}</b></h2>
        </Col>
        <Col xs={7}>
          <Button 
            variant="outline-secondary"
            onClick={()=>this.setNewModalShow(true)} >
            <FontAwesomeIcon icon={faPlus} />
            {' '}{t('New Entry')}
          </Button>
        </Col>
      </Row>
        <Table responsive hover>
          <thead>
            <tr>
              <th>#</th>
              <th>{t('Date')}</th>
              <th>{t('Cost')}</th>
              <th>{t('Type')}</th>
              <th>{t('Description')}</th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTable()}
          </tbody>
        </Table>
        <Pagination>
          {this.state.tableStartIndex > 0 ? <Pagination.Prev onClick={() => this.setTablePignation('-')}/> : <></>}
          {this.props.expenses.length >= config.maxTableRows ? <Pagination.Next onClick={() => this.setTablePignation('+')}/> : <></>}
        </Pagination>
        <ExpensesModal
          show={this.state.modalShow}
          onHide={() => this.setNewModalShow(false)}>
        </ExpensesModal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    expenses: state.expenses,
  };
};

export default connect(mapStateToProps,{ShowSpinner, ListExpenses})(withTranslation()(Expenses));