import React, { Component } from 'react'
import config from '../../../config/Settings.json'
import { connect } from "react-redux"
import { ShowSpinner, ListTimeSheets, WhoIam, ShowSignTimeSheet, HideSignTimeSheet, InsertTimeSheet } from "../../../store/Actions/index"
import { getToken, getUser } from '../../../utils/Common'

import { Row, Col, Table, Button, Pagination } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import TimeSheetTableRow from '../components/TableRows/TimeSheetTableRow'
import SelectDateTimeModal from '../components/Modals/SelectDateTimeModal'
import SignatureModal from '../components/Modals/SignatureModal'
import { withTranslation } from 'react-i18next'
import '../ProjectTracking.css'

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

class TimeSheets extends Component{
  constructor(props) {
    super(props);
    this.state = {
      modalShow:false,
      tableStartIndex:0
    }
    this.setNewModalShow = this.setNewModalShow.bind(this)
    this.handleOnNewTimeSheet = this.handleOnNewTimeSheet.bind(this)
    this.renderTable = this.renderTable.bind(this)
    this.setTablePignation = this.setTablePignation.bind(this)
  }

  setNewModalShow(view){
    this.setState({
      modalShow: view,
    });
  }

  setTablePignation(op){
    var value = this.state.tableStartIndex
    if(op==='+')
      value = value+config.maxTableRows
    
    if(op==='-')
      value = value-config.maxTableRows
      
    this.props.ShowSpinner()
    this.props.ListCustomers(value,config.maxTableRows)
    this.setState({
      tableStartIndex:value
    })
  }

  componentDidMount(){
    if(getToken()){
      this.props.ShowSpinner()
      this.props.ListTimeSheets()
    }
  }

  handleOnNewTimeSheet(extendedSelection, dateMonthYear, dateFrom, dateTo){
    if(!extendedSelection){
      var date = new Date(dateMonthYear);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.props.InsertTimeSheet({dateFrom:dateFormat(firstDay,"yyyy-mm-dd"), dateTo:dateFormat(lastDay,"yyyy-mm-dd")})
    }
    else
      this.props.InsertTimeSheet({dateFrom:dateFormat(dateFrom,"yyyy-mm-dd"), dateTo:dateFormat(dateTo,"yyyy-mm-dd")})

    this.setState({
      modalShow:false
    })
  }

  renderTable(){
    var items = []
    let me = getUser()
    for(var j = 0; j<this.props.timeSheets.length;j++){
      items.push(<TimeSheetTableRow
        key={this.props.timeSheets[j].id}
        Username={me.username}
        Id={this.props.timeSheets[j].id}
        From={this.props.timeSheets[j].from}
        To={this.props.timeSheets[j].to}
        State={this.props.timeSheets[j].state}
      >
      </TimeSheetTableRow>)
    }
    return items;
  }

  render(){
    const {t} = this.props
    return(
      <>
      <Row className={"table-title"}>
        <Col xs={5}>
          <h2>{t('Time')} <b>{t('Sheets')}</b></h2>
        </Col>
        <Col xs={7}>
          <Button 
            variant="outline-secondary"
            onClick={()=>this.setNewModalShow(true)} >
            <FontAwesomeIcon icon={faPlus} />
            {' '}{t('New time sheet')}
          </Button>
        </Col>
      </Row>
        <Table responsive hover>
          <thead>
            <tr>
              <th colSpan={3}>{t('File')}</th>
              <th></th>
              <th></th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {this.renderTable()}
          </tbody>
        </Table>
        <Pagination>
          {this.state.tableStartIndex > 0 ? <Pagination.Prev onClick={() => this.setTablePignation('-')}/> : <></>}
          {this.props.timeSheets.length >= config.maxTableRows ? <Pagination.Next onClick={() => this.setTablePignation('+')}/> : <></>}
        </Pagination>
        <SelectDateTimeModal
          show={this.state.modalShow}
          onHide={() => this.setNewModalShow(false)}
          onSubmit={this.handleOnNewTimeSheet}
        />
        <SignatureModal
          show={this.props.signModalShow}
          onHide={this.props.HideSignTimeSheet}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    timeSheets: state.timeSheets,
    signModalShow: state.signModalShow
  };
};

export default connect(mapStateToProps,{ShowSpinner, ListTimeSheets, WhoIam, HideSignTimeSheet, ShowSignTimeSheet, InsertTimeSheet})(withTranslation()(TimeSheets));