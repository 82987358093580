import React, { useState, useEffect }  from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { connect } from "react-redux";
import { GetCustomer, ShowAddUserModal, InsertProject, ShowSpinner, ShowDialog, CreateKanban, ListCustomerKanbanTask, GetCustomerHourEvaluation } from "../../../store/Actions/index";
import { getToken } from '../../../utils/Common';
import { exportCustomerHoursToCSV } from '../../../utils/ExportCSV';
import { getDateMonthsBefore, getDateMonthsAfter } from '../../../utils/DateHelper';
import { Row, Col, Form, Table, Button, Card, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChalkboard, faBriefcase, faTasks } from '@fortawesome/free-solid-svg-icons'
import { IconEdit, IconPlus } from '../../../assets/icons'
import Error from './Error'
import ProjectTableRow from '../components/TableRows/ProjectTableRow'
import AddAccountManager from '../components/Modals/AddUserModal'
import AccountManager from '../../BusinessCard/BusinessCardSmall'
import SelectDateTimeModal from '../components/Modals/SelectDateTimeModal'
import TaskBucket from '../../Kanban/components/TaskBucket'
import Charts from '../components/Charts/Charts'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { withTranslation } from 'react-i18next';

import '../ProjectTracking.css';

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

const Customer = (props) => {
  const {t} = props
  const history = useHistory()
  const dropDownStyle = 'outline-secondary rounded-lg'

  const [data, setData ] = useState({
    Name:null,
    Location:null,
    Address:null,
    PostalCode:null,
  })

  const [edit, setEdit ] = useState({
    editBasic:false,
    editAccount:false,
    editAddAccount:false,
    showExport:false
  })

  const [showCompleted, setShowCompleted ] = useState(false)

  const [dateValue, setDateValue] = useState({
    currentStartDate:getDateMonthsBefore(new Date(),6),
    currenEndDate:new Date()
  })

  useEffect(() => {
    let id = props.match.params.urlPath
    props.ShowSpinner()
    props.GetCustomer(id)
    props.ListCustomerKanbanTask(id)
    props.GetCustomerHourEvaluation(id, dateFormat(dateValue.currentStartDate,"yyyy-mm-dd"),dateFormat(dateValue.currenEndDate,"yyyy-mm-dd"))
    
  },[props.match.params.urlPath]);

  const showAccount = (e) => {
    history.push(`/employee/${e}`)
  }

  const setTablePignation = (op, type) => {
    var values = dateValue
    if(op ==='+' && type === 'start')
      values.currentStartDate = getDateMonthsAfter(values.currentStartDate,1)
    
    if(op==='-' && type === 'start')
      values.currentStartDate = getDateMonthsBefore(values.currentStartDate,1)

    if(op ==='+' && type === 'end')
      values.currenEndDate = getDateMonthsAfter(values.currenEndDate,1)
    
    if(op==='-' && type === 'end')
      values.currenEndDate = getDateMonthsBefore(values.currenEndDate,1)
    

    setDateValue(prevEdit => ({
      ...prevEdit,
      values
    }));

    props.GetCustomerHourEvaluation(props.thisCustomer.Id, dateFormat(dateValue.currentStartDate,"yyyy-mm-dd"),dateFormat(dateValue.currenEndDate,"yyyy-mm-dd"))
  }

  const handleOnNewPlanner = () =>{
    if(!props.thisCustomer.PlanerLink){
      props.CreateKanban({owner:props.thisCustomer.Id, title: props.thisCustomer.Name, class: 'customer'})
      history.push('/planner')
    }
  }

  const handleOnNewExport = (extendedSelection, dateMonthYear, dateFrom, dateTo) => {
    if(!extendedSelection){
      var date = new Date(dateMonthYear);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      exportCustomerHoursToCSV(props.thisCustomer.Id, dateFormat(firstDay,"yyyy-mm-dd"), dateFormat(lastDay,"yyyy-mm-dd"), props.thisCustomer.Name )
    }
    else
      exportCustomerHoursToCSV(props.thisCustomer.Id, dateFormat(dateFrom,"yyyy-mm-dd"), dateFormat(dateTo,"yyyy-mm-dd"), props.thisCustomer.Name )

    setEdit(prevEdit => ({
      ...prevEdit,
      showExport: false
    }));
  }

  const handleOnSelectDropdown = (e) => {
    if(e === "newProject"){
      props.ShowSpinner()
      props.InsertProject({Customers_fk:props.thisCustomer.Id})
      props.GetCustomer(props.thisCustomer.Id)
    }

    setEdit(prevEdit => ({
        ...prevEdit,
        [e]: true
    }));
  }

  const handleOnCancel = (e) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [e]: false
    }));
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }));
  };

  const handleRemoveManager = (guid) => {
    const token = getToken()
    if(token) {
      axios.delete(`${window._env_.REACT_APP_SERVICE_API}/customer/${props.thisCustomer.Id}/member`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: { 
          'guid':guid
        }}).then(response => {
          if(response.data.affectedRows>0){
            let id=props.match.params.urlPath
            props.ShowSpinner()
            props.GetCustomer(id)
          }
      }).catch(error =>{
        props.ShowDialog('Error', error.message)
      })
    }
  }

  const handleOnSaveProperties = (e) => {
    const token = getToken()

    if(token) {
      axios.put(`${window._env_.REACT_APP_SERVICE_API}/customer/${props.thisCustomer.Id}`, { Name:data.Name, Address:data.Address, Location:data.Location, PostalCode:data.PostalCode, CustomerNumber:data.CustomerNumber}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(response => {
          if(response.data.changedRows>0){
            handleOnCancel('editBasic')
            props.ShowSpinner()
            props.GetCustomer(props.thisCustomer.Id)
          }
      }).catch(error =>{
        props.ShowDialog('Error', error.message)
      })
    }
  }

  const renderManager = () => {
    var items = []
    if(props.thisCustomer.Members){
      for(var j = 0;j<props.thisCustomer.Members.length;j++){
        let userGuid = props.thisCustomer.Members[j].guid
        items.push(
          <AccountManager
            key={userGuid}
            guid={userGuid}
            online={props.connectedEmployees.includes(props.thisCustomer.Members[j].guid)}
            name={props.thisCustomer.Members[j].name}
            company={props.thisCustomer.Members[j].company}
            mail={props.thisCustomer.Members[j].mail}
            photo={props.thisCustomer.Members[j].photo}
            edit={edit.editAccount}
            onDelete={handleRemoveManager}
            onClickAccount={showAccount}
          />
        )
      }
    }
    return items
  }

  const renderTable = () => {
    var items = []
    if(props.thisCustomer.Projects){
      for(var j = 0; j<props.thisCustomer.Projects.length;j++){
        items.push(<ProjectTableRow
          key={props.thisCustomer.Projects[j].Id}
          Project={props.thisCustomer.Projects[j]}
          Id={props.thisCustomer.Projects[j].Id}
          Name={props.thisCustomer.Projects[j].Name}
          ProjectState={props.thisCustomer.Projects[j].ProjectState}
          History={history}
          ShowCompleted={showCompleted}>
        </ProjectTableRow>)
      }
    }
    return items;
  }

  
  return(
    <>{props.error.show ?
      <Error />
      :
      <>
      <Row className={'mt-25'}>
        <Col md={12} lg={12} xs={12}>
          <h5>{t('Customer')} <b>{props.thisCustomer.Name}</b></h5>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={4} md={6}>
          <Card
          bg={'success'}
          border={'success'}
          text={'white'}
          className={'hover'}
          >
            <Card.Body>
              <div className={'rotate'}>
                <FontAwesomeIcon icon={faChalkboard} size={'3x'}/>
              </div>
              <h6 className="text-uppercase">{t('Projects')}</h6>
              <h1 className="display-4">{props.thisCustomer.ProjectsCount ? props.thisCustomer.ProjectsCount : 0}</h1>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} lg={4} md={6}>
          <ContextMenuTrigger id={'hours-card'}>
            <Card
              bg={'info '}
              border={'info '}
              text={'white'}
              className={'hover'}
              >
              <Card.Body>
                <div className={'rotate'}>
                  <FontAwesomeIcon icon={faBriefcase} size={'3x'}/>
                </div>
                <h6 className="text-uppercase">{t('Hours worked')}</h6>
                <h1 className="display-4">{props.thisCustomerHourEvaluation && props.thisCustomerHourEvaluation.map(a => a.Hours).reduce((a, b) => a + b, 0)}</h1>
              </Card.Body>
            </Card>
          </ContextMenuTrigger>
          <ContextMenu id={'hours-card'}>
            <MenuItem
              onClick={()=>handleOnSelectDropdown("showExport")}
            >
              {t('Hour export')}
            </MenuItem>
          </ContextMenu>
        </Col>

        <Col sm={12} lg={4} md={6}>
          <ContextMenuTrigger id={'planner-card'}>
            <Card
              bg={'warning '}
              border={'warning '}
              text={'white'}
              className={'hover'}
              >
              <Card.Body>
                <div className={'rotate'}>
                  <FontAwesomeIcon icon={faTasks} size={'3x'}/>
                </div>
                <h6 className="text-uppercase">{t('Tasks')}{!props.thisCustomer.PlanerLink && (' (' + t('disabled') + ')')}</h6>
                <h1 className="display-4">{props.thisCustomer.PlanerTasks ? props.thisCustomer.PlanerTasks : 0}</h1>
              </Card.Body>
            </Card>
          </ContextMenuTrigger>
          <ContextMenu id={'planner-card'}>
            {!props.thisCustomer.PlanerLink && (
            <MenuItem
              onClick={()=>handleOnNewPlanner()}
            >
              {t('Activate Planner')}
            </MenuItem>)}
            {props.thisCustomer.PlanerLink && (
            <MenuItem
              onClick={()=>props.history.push('/planner/kanban/'+props.thisCustomer.PlanerLink)}
            >
              {t('To')} {t('Tasks')}
            </MenuItem>)}
          </ContextMenu>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={12} md={12}>
          {props.thisCustomerHourEvaluation.length > 0 ? (
            <Card
            bg={'white '}
            border={'white '}
            text={'white'}
            style={{border: 'none'}}
            >
              <Card.Body>
              <Charts
                data={[props.thisCustomerHourEvaluation.map(a => a.Hours)]}
                dataLabel={[props.thisCustomerHourEvaluation.map(a => a.Month)]}
                labels={[t('Hours worked')]}
                colors={ ['#002d73'] }
                height={ 250 }
              />
                <Col sm={12} lg={12} md={12} className={'mt-25'}>
                  <Pagination className={'float-left'}>
                    <Pagination.Prev onClick={() => setTablePignation('-', 'start')}/>
                    <span style={{color: "#000", padding: "10px"}}>{dateFormat(dateValue.currentStartDate,"dd. mmm yyyy")}</span>
                    <Pagination.Next onClick={() => setTablePignation('+', 'start')}/>
                  </Pagination>
                  <Pagination className={'float-right'}>
                    <Pagination.Prev onClick={() => setTablePignation('-', 'end')}/>
                    <span style={{color: "#000", padding: "10px"}}>{dateFormat(dateValue.currenEndDate,"dd. mmm yyyy")}</span>
                    <Pagination.Next onClick={() => setTablePignation('+', 'end')}/>
                  </Pagination>
                </Col>
              </Card.Body>
            </Card>
          ) : (
          <Card style={{border: 'none'}}>
            <Card.Body>
              <Col className={'text-center'} key={'noEntries'}>
                <h1>{t('no entries')}</h1>
                <h4>{t('sentences.noEntries')}</h4>
              </Col>
            </Card.Body>
          </Card>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={8} xs={8}>
          <h4>{t('Basic')} <b>{t('Information')}</b></h4>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => handleOnSelectDropdown("editBasic")}
          />
        </Col>
        <Col md={12} lg={6}>
          <label>{t('Name')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="Name" >
            <Form.Control type={'text'} onChange={handleOnChange} defaultValue={props.thisCustomer.Name}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisCustomer.Name}</p>
          }
        </Col>
        <Col md={12} lg={6}>
          <label>{t('customerNumber')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="CustomerNumber" >
            <Form.Control type={'text'} onChange={handleOnChange} defaultValue={props.thisCustomer.CustomerNumber}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisCustomer.CustomerNumber}</p>
          }
        </Col>
        <Col md={12} lg={12}>
          <label>{t('Address')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="Address" >
            <Form.Control type={'text'} onChange={handleOnChange} defaultValue={props.thisCustomer.Address}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisCustomer.Address}</p>
          }
        </Col>
        <Col md={12} lg={6}>
          <label>{t('postalCode')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="PostalCode" >
            <Form.Control type={'text'} onChange={handleOnChange} defaultValue={props.thisCustomer.PostalCode}/>
          </Form.Group>  
          </> 
          :
          <p className="font-weight-bold" >{props.thisCustomer.PostalCode}</p>
          }
        </Col>
        <Col md={12} lg={6}>
          <label>{t('Location')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="Location" >
            <Form.Control type={'text'} onChange={handleOnChange} defaultValue={props.thisCustomer.Location}/>
          </Form.Group>  
          </> 
          :
          <p className="font-weight-bold" >{props.thisCustomer.Location}</p>
          }
        </Col>
        {edit.editBasic && (<>
          <Col md={12} lg={12}>
            <Form.Group>
              <Button onClick={() => handleOnCancel('editBasic')} variant="secondary">{t('Cancel')}</Button>{' '}
              <Button onClick={()=> handleOnSaveProperties('editBasic')} variant="primary" >{t('Save')}</Button>
            </Form.Group>
          </Col>
        </>)}
      </Row>

      <Row className={"mt-25"}>
      <Col md={8} lg={8} xs={8}>
          <h4>{t('Detail')} <b>{t('Information')}</b></h4>
        </Col>
        <Col md={4} lg={4} xs={4}>

        </Col>
        <Col md={12} lg={6}>
          <label>{t('Customer Revenue')}</label>
          <p className="font-weight-bold" >{props.thisCustomer.Revenue || 0.00}{' €'}</p>
        </Col>
        <Col md={12} lg={6}>

        </Col>
        {props.thisCustomer.TimeBudgedActive && (<>
          <Col md={12} lg={6}>
            <label>{t('Revenue Active')}</label>
            <p className="font-weight-bold" >{props.thisCustomer.RevenueActive || 0.00}{' €'}</p>
          </Col>
          <Col md={12} lg={6}>
            <label>{t('Time Budged Active')}</label>
            <p className="font-weight-bold" >{props.thisCustomer.TimeBudgedActive}</p>
          </Col></>
        )}

        {props.thisCustomer.TimeBudgedPending && (<>
          <Col md={12} lg={6}>
            <label>{t('Revenue Pending')}</label>
            <p className="font-weight-bold" >{props.thisCustomer.RevenuePending || 0.00}{' €'}</p>
          </Col>
          <Col md={12} lg={6}>
            <label>{t('Time Budged Pending')}</label>
            <p className="font-weight-bold" >{props.thisCustomer.TimeBudgedPending}</p>
          </Col></>
        )}

        {props.thisCustomer.TimeBudgedVerified && (<>
        <Col md={12} lg={6}>
          <label>{t('Revenue Verified')}</label>
          <p className="font-weight-bold" >{props.thisCustomer.RevenueVerified || 0.00}{' €'}</p>
        </Col>
        <Col md={12} lg={6}>
          <label>{t('Time Budged Pending')}</label>
          <p className="font-weight-bold" >{props.thisCustomer.TimeBudgedVerified}</p>
        </Col></>
        )}
      </Row>

      <Row className={"table-title"}>
        <Col xs={8}>
          <h2>{t('Account')} <b>{t('Management')}</b></h2>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconPlus 
            onClick={() => handleOnSelectDropdown('editAddAccount')}
          />
        </Col>
      </Row>
      <Row>
        {renderManager()}
        {edit.editAccount && (<>
          <Col md={12} lg={12}>
            <Form.Group>
              <Button onClick={() => handleOnCancel('editAccount')} variant="primary" >{t('Done')}</Button>
            </Form.Group>
          </Col>
          </>
        )}
      </Row>

      {props.customerTasks.length > 0 && (<>
        <Row className={"table-title"}>
          <Col md={8} lg={8} xs={8}>
            <h2>{t('Customer')} <b>{t('Tasks')}</b></h2>
          </Col>
          <Col md={4} lg={4} xs={4}>

          </Col>
        </Row>
        <TaskBucket 
          myTasks={props.customerTasks}
        />
      </>)}

      <Row className={"table-title"}>
        <Col xs={8}>
          <h2>{t('Project')} <b>{t('Management')}</b></h2>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconPlus 
            onClick={() => handleOnSelectDropdown('newProject')}
          />
        </Col>
        <Col xs={12} className={'text-left'}>
        <Form.Switch
          onChange={()=>setShowCompleted(!showCompleted)}
          id="custom-switch"
          label={t('Show completed Projects')}
        />
      </Col>
      </Row>
      <Row>
        <Table responsive hover>
          <thead>
            <tr>
              <th>{t('Project Name')}</th>
              <th>{t('State')}</th>
              <th className='d-none d-md-none d-lg-table-cell' >{t('Order Number')}</th>
              <th>{t('Action')}</th>
            </tr>
          </thead>
          <tbody>
            {renderTable()}
          </tbody>
        </Table>
      </Row>
      <SelectDateTimeModal
        show={edit.showExport}
        onHide={() => handleOnCancel('showExport')}
        onSubmit={handleOnNewExport}
      />
      <AddAccountManager
        apiRoute={'customer'}
        id={props.thisCustomer.Id}
        onHide={() => handleOnCancel('editAddAccount')}
        show={edit.editAddAccount}
        animation={true}
      >
      </AddAccountManager>
      </>}
    </>
  )
}

const mapStateToProps = state => {
  return {
    error:state.error,
    thisCustomer: state.thisCustomer,
    thisCustomerHourEvaluation: state.thisCustomerHourEvaluation,
    connectedEmployees: state.connectedEmployees,
    customerTasks: state.customerTasks,
  };
};

export default connect(mapStateToProps,{ShowDialog, GetCustomer, ShowSpinner, ShowAddUserModal, InsertProject, CreateKanban, ListCustomerKanbanTask, GetCustomerHourEvaluation})(withTranslation()(Customer));