import { w3cwebsocket as W3CWebSocket } from "websocket"
import store from '../store/Store'

const ws = new W3CWebSocket(window._env_.REACT_APP_SERVICE_WSS)

ws.onmessage = (message) => {
  if(message.data){
    let json = JSON.parse(message.data)

    if(json.type === "userevent")
      store.dispatch({type: "LOGIN_CHANGE", payload: json.data.users });

    if(json.type === "kanbanevent")
      console.log(json)
  }
}

const waitForOpenConnection = () => {
  return new Promise((resolve, reject) => {
      const maxNumberOfAttempts = 10
      const intervalTime = 200 //ms

      let currentAttempt = 0
      const interval = setInterval(() => {
          if (currentAttempt > maxNumberOfAttempts - 1) {
              clearInterval(interval)
              reject(new Error('Maximum number of attempts exceeded'))
          } else if (ws.readyState === ws.OPEN) {
              clearInterval(interval)
              resolve()
          }
          currentAttempt++
      }, intervalTime)
  })
}

export const sendSocketMessage = async (msg) => {
  if (ws.readyState !== ws.OPEN) {
      try {
          await waitForOpenConnection()
          ws.send(msg)
      } catch (err) { console.error(err) }
  } else {
      ws.send(msg)
  }
}