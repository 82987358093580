import React from 'react';
import { IconEdit } from '../../../../assets/icons'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const ContactTab = (props) => {

  const {t} = props
  return(<>
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Employee')} <b>{t('Settings')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4} className={'text-right'}>
        <IconEdit 
          onClick={() => props.handleOnSelectDropdown("editSettings")}
        />
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Verification')} {t('State')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="verificationState" >
          <Form.Control as="select" onChange={props.handleOnChange} defaultValue={props.user.verificationState}>
            <option key={"pending"} value={"pending"}>{t('pending')}</option>
            <option key={"verified"} value={"verified"}>{t('verified')}</option>
            <option key={"authorized"} value={"authorized"}>{t('authorized')}</option>
          </Form.Control>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.verificationState}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <label>{t('employeeRole')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="roles" >
          <Form.Control as="select" onChange={props.handleOnChange} defaultValue={props.user.roles ? props.user.roles.split(',') : ''}>
            <option key={"admin"} value={"admin"}>{t('administrator')}</option>
            <option key={"manager"} value={"manager"}>{t('manager')}</option>
            <option key={"member"} value={"member"}>{t('member')}</option>
          </Form.Control>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.roles}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Accountant')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="accountant" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.accountant}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.accountant}</p>
        }
      </Col>
      {props.edit ? 
      <>
      <Col md={12} lg={12}>
        <Form.Group>
          <Button onClick={() => props.handleOnCancel('editSettings')} variant="secondary">{t('Cancel')}</Button>{' '}
          <Button onClick={props.handleOnSubmit} variant="primary" >{t('Save')}</Button>
        </Form.Group>
      </Col>
      </>
      :
      <>
      </>}
    </Row>
  </>)
}

export default (withTranslation()(ContactTab));