import { LIST_CUSTOMERS, SELECT_CUSTOMER, GET_CUSTOMER, HIDE_CUSTOMER, RESET_ERROR, GET_CUSTOMER_HOUR_EVALUATION } from "../Constants/action-types";

import { getToken } from '../../utils/Common';
import config from '../../config/Settings.json';

export function ListCustomers(start,count){
  return function(dispatch){
    const token = getToken();
    if(token){
      var request = require('request');
      var options = {
        method: 'POST',
        url: window._env_.REACT_APP_SERVICE_API + '/customers',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "start":start,
          "count":count})
      };
      request(options, function (error, response,body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_CUSTOMERS, payload: json });
        }
      });
    }
  }
}

export function SelectCustomer(Id) {
  return function(dispatch){
    return dispatch({type: SELECT_CUSTOMER, payload: Id });
  }
}

export function GetCustomer(Id) {
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + '/customer/' + Id,
        'headers': {
          Authorization: 'Bearer ' + token
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        
        var json = JSON.parse(body)
        if(response.statusCode === 403)
          return dispatch({type: RESET_ERROR, payload: {show:true,code:403,message:'You do nat have permisson to access this document'} });
        else if(json) {
          return dispatch({type: GET_CUSTOMER, payload: json });
        }
      });
    }
  }
}

export function GetCustomerHourEvaluation(Id, StartDate, EndDate) {
  return function(dispatch){
    const token = getToken()

    if(token){
      var request = require('request');
      var options = {
        'method': 'GET',
        'url': window._env_.REACT_APP_SERVICE_API + `/customer/${Id}/evaluation?startDate=${StartDate}&endDate=${EndDate}`,
        'headers': {
          Authorization: 'Bearer ' + token
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        
        var json = JSON.parse(body)
        if(json) {
          return dispatch({type: GET_CUSTOMER_HOUR_EVALUATION, payload: json });
        }
      });
    }
  }
}

export function HideCustomer() {
  return function(dispatch){
    return dispatch({type: HIDE_CUSTOMER, payload: false });
  }
}