import React, { useState } from 'react'
import { ListGroup, Nav } from 'react-bootstrap'
import { IconLogout, IconContacts, IconLocation, IconAgents, IconOverview, IconIdeas, IconArticles, IconTickets, IconSettings, IconCalendar, IconReceipt } from '../../assets/icons'
import SidebarItem from './SidebarItem'
import SideBarSubitem from './SidebarSubitem'
import { withTranslation } from 'react-i18next'
import './Sidebar.scss'

const Sidebar =(props) => {

  const [showSubItem, setShowSubitem] = useState()
  const handleOnShowSubitem = (props) => {
    if(showSubItem === props)
      setShowSubitem(null)
    else
      setShowSubitem(props)
  }

  const {t} = props
  return(
    <>
      <Nav className="col-md-12 sidebar" id="sticky-sidebar" style={{ fontFamily: "'Maersk Text' !important", fontWeight: 'bold'}}>
        <div className="sidebar-sticky"></div>
        <ListGroup variant="flush" className={'position-fixed'}>
          <SidebarItem 
            key={1}
            path={'/dashboard'}
            title={'Dashboard'}
            icon={<IconOverview style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SidebarItem 
            key={7}
            path={'/profile'}
            title={'Profile'}
            icon={<IconIdeas style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SidebarItem 
            key={2}
            path={'/employees'}
            title={'employees'}
            icon={<IconContacts style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SidebarItem 
            key={3}
            path={'/customers'}
            title={'customers'}
            icon={<IconAgents style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SidebarItem 
            title={'timeRecording'}
            onClick={()=>handleOnShowSubitem('timeRecording')}
            icon={<IconReceipt style={{margin: "5px"}}/>}
          />
          <SideBarSubitem 
            show={showSubItem === 'timeRecording'}
            key={4}
            path={'/work'}
            title={'workRecord'}
            icon={<IconArticles style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SideBarSubitem 
            show={showSubItem === 'timeRecording'}
            key={5}
            path={'/expenses'}
            title={'expenses'}
            icon={<IconTickets style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SideBarSubitem 
            show={showSubItem === 'timeRecording'}
            key={6}
            path={'/timesheets'}
            title={'timeSheets'}
            icon={<IconOverview style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SidebarItem 
            key={8}
            path={'/planner'}
            title={'Planner'}
            icon={<IconCalendar style={{margin: "5px"}}/>}
            onClick={props.toggleSidebar}
          />
          <SidebarItem 
            title={'Language'}
            onClick={()=>handleOnShowSubitem('language')}
            icon={<IconLocation style={{margin: "5px"}}/>}
          />
          <SideBarSubitem 
            show={showSubItem === 'language'}
            onClick={()=>props.handleOnLanguageChange("DE")}
            title={'German'}
            icon={<IconSettings style={{margin: "5px"}}/>}
          />
          <SideBarSubitem 
            show={showSubItem === 'language'}
            onClick={()=>props.handleOnLanguageChange("EN")}
            title={'English'}
            icon={<IconSettings style={{margin: "5px"}}/>}
          />
          <SidebarItem
            onClick={props.handleOnLogout}
            title={'logout'}
            icon={<IconLogout style={{margin: "5px"}}/>}
          />
        </ListGroup>
      </Nav>
    </>
  )
}

export default withTranslation()(Sidebar)