import React, { Component } from 'react';
import axios from 'axios';
import config from '../../../../config/Settings.json'
import { connect } from "react-redux";
import { InsertWorkRecords } from "../../../../store/Actions/index";
import { getToken } from '../../../../utils/Common';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import DraggableModalDialog from '../../../DraggableModalDialog/DraggableModalDialog'
import { withTranslation } from 'react-i18next';

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

class WorkModalSmall extends Component {
  constructor(props){
    super(props)
    this.state = {
      Id:null,
      Date:'',
      TravelTime:0,
      Employee:'',
      Description:'',

      Customer_fk:null,
      Customer_Name:null,
      Project_fk:null,
      Project_Name:null,

      HoursWorked:0,
      Location:'',
      CustomerOptions:'',
      ProjectOptions:'',
      ProjectOptionsArray: [],
      CustomerOptionsArray:[],
      DescriptionError: null,
      HoursWorkedError: null,
      LocationError: null,
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleCustomerChange = this.handleCustomerChange.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.show && !this.props.show){
      let token = getToken()
      if(token){
        var selectedCustomer = null

        const header = {
          headers: { Authorization: `Bearer ${token}` }
        };
    
        axios.get(`${window._env_.REACT_APP_SERVICE_API}/customers`,header).then(response => {
          var items = []
          var list = []
          for(var j = 0; j<response.data.length;j++){
            if(!selectedCustomer)
              selectedCustomer = response.data[j]
            list.push({key:response.data[j].Id,value:response.data[j].Name})
            items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
          }
          this.setState({
            Customer_fk:selectedCustomer.Id,
            Customer_Name:selectedCustomer.Name,
            CustomerOptions:items,
            CustomerOptionsArray:list,
          })
          if(selectedCustomer){
            axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${selectedCustomer.Id}`,header).then(response => {
              var items = []
              var list = []
              var selected = null
              for(var j = 0; j<response.data.length;j++){
                if(!selected)
                  selected = response.data[j]
                list.push({key:response.data[j].Id,value:response.data[j].Name})
                items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
              }
              this.setState({
                Project_fk:selected.Id,
                Project_Name:selected.Name,
                ProjectOptions:items,
                ProjectOptionsArray:list
              })
            }).catch(error => {
              this.setState({
                Project_fk:null,
                ProjectOptions:<option >Error</option>,
                ProjectOptionsArray:null
              })
            });
          }
        }).catch(error => {
          this.setState({
            Customer_fk:null,
            CustomerOptions:<option >Error</option>,
            CustomerOptionsArray:null
          })
        });
      }
      this.setState({
        Id:null,
        Date:dateFormat(nextProps.date,"yyyy-mm-dd"),
        TravelTime:0,
        Employee:'',
        Description:nextProps.description,
        Location:nextProps.user.location,
        HoursWorked:nextProps.hoursWorked,
        CustomerOptions:'',
        ProjectOptions:'',
        DescriptionError: null,
        HoursWorkedError: null,
        LocationError: null,
      })
    }
  }

  validate = () => {
    let isError = false;
    const errors = {
      DescriptionError: null,
      HoursWorkedError: null,
      LocationError: null,
    }

    if(!this.state.Description){
      isError = true;
      errors.DescriptionError = 'Enter description';
    }

    if(!this.state.Location && !this.props.user.location){
      isError = true;
      errors.LocationError = 'Enter location';
    }

    if(this.state.HoursWorked === null){
      isError = true;
      errors.HoursWorkedError = 'Enter hours';
    }
    this.setState({
      ...this.state,
      ...errors
    })
    return isError;
  }

  handleCustomerChange(event){
    let token = getToken()

    if(token){
      this.setState({[event.target.id]: event.target.value})

      const header = {
        headers: { Authorization: `Bearer ${token}` }
      };

      axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${event.target.value}`,header).then(response => {
        var items = []
        var list = []
        var selected = null
        for(var j = 0; j<response.data.length;j++){
          if(!selected)
            selected = response.data[j]
          list.push({key:response.data[j].Id,value:response.data[j].Name})
          items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
        }
        this.setState({
          Project_fk:selected.Id,
          Project_Name:selected.Name,
          ProjectOptions:items,
          ProjectOptionsArray:list
        })
      }).catch(error => {
        this.setState({
          Project_fk:null,
          ProjectOptions:<option >Error</option>,
          ProjectOptionsArray:null
        })
      });
    }
  }

  handleOnSubmit(){
    const err = this.validate()
    if(!err)
    {
      var cObj = this.state.CustomerOptionsArray.filter(element=> element.key === parseInt(this.state.Customer_fk))
      var pObj = this.state.ProjectOptionsArray.filter(element=> element.key === parseInt(this.state.Project_fk))
  
      var cName = cObj[0].value
      var pName = pObj[0].value

      this.props.InsertWorkRecords({
        Date:dateFormat(this.state.Date,"yyyy-mm-dd"), 
        Project_fk:this.state.Project_fk, 
        Description:this.state.Description, 
        Customer_fk:this.state.Customer_fk,
        TravelTime:this.state.TravelTime,
        HoursWorked:this.state.HoursWorked,
        Location:(this.state.Location === '' ? this.props.user.location : this.state.Location),
        Customer_Name:cName,
        Project_Name:pName
      })
      
      this.props.onHide()
    }
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="lg"
      backdrop="static"
      dialogAs={DraggableModalDialog} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Record')}  <b>{t('Working Time')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group controlId="Date" as={Col}>
              <Form.Label>{t('Date')}</Form.Label>
              <Form.Control 
                ref={'Date'}
                onChange={this.handleOnChange} 
                defaultValue={dateFormat(this.state.Date,"yyyy-mm-dd")} 
                type="date"/>
            </Form.Group>

            <Form.Group controlId="Location" as={Col}>
              <Form.Label>{t('Location')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} defaultValue={this.state.Location} type="text" isInvalid={(this.state.LocationError)} />
              <Form.Control.Feedback type="invalid">
                {this.state.LocationError}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>  
            <Form.Group controlId="HoursWorked" as={Col}>
              <Form.Label>{t('Work hours')}</Form.Label>
              <Form.Control onChange={this.handleOnChange}  defaultValue={this.state.HoursWorked} type="number" placeholder="0" isInvalid={(this.state.HoursWorkedError)} />
              <Form.Control.Feedback type="invalid">
                {this.state.HoursWorkedError}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="TravelTime" as={Col}>
              <Form.Label>{t('Travel time')}</Form.Label>
              <Form.Control ref={'TravelTime'} onChange={this.handleOnChange} defaultValue={this.state.TravelTime} type="number" placeholder="0" />
            </Form.Group>
          </Form.Row>

          <Form.Row>
          <Form.Group controlId="Customer_fk" as={Col}>
              <Form.Label>{t('Customer')}</Form.Label>
              <Form.Control defaultValue={this.state.Customer_fk} onChange={this.handleCustomerChange.bind(this)} as="select" >
                {this.state.CustomerOptions}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="Project_fk" as={Col}>
              <Form.Label>{t('Project')}</Form.Label>
              <Form.Control defaultValue={this.state.Project_fk} onChange={this.handleOnChange} as="select" >
                {this.state.ProjectOptions}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="Description" >
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control as="textarea" onChange={this.handleOnChange} defaultValue={this.state.Description} type="text" placeholder={t('Description')} isInvalid={(this.state.DescriptionError)}/>
            <Form.Control.Feedback type="invalid">
              {this.state.DescriptionError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        <Button onClick={this.handleOnSubmit} variant="primary" >{t('saveClose')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps,{InsertWorkRecords})(withTranslation()(WorkModalSmall));