import React from 'react';
import axios from 'axios';

import { connect } from "react-redux";

import { getMSALAccessToken, getMSALAccessTokenExpiresOn, killSession, getToken } from '../../../utils/Common';
import { Row, Col, Pagination, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faUserCheck, faUser, faTasks, faGlobe } from '@fortawesome/free-solid-svg-icons'
import { isMobile } from 'react-device-detect';

import WorkModalSmall from '../../ProjectTracking/components/Modals/WorkModalSmall';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { withTranslation } from 'react-i18next';
import defaultImage from '../../../images/defaultUserM.png'

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000)
  var offset = date.getTimezoneOffset() / 60
  var hours = date.getHours()
  newDate.setHours(hours - offset)
  return newDate
}

function diff_hours(dt2, dt1) {
  var diff =(dt2.getTime() - dt1.getTime()) / 1000
  diff /= (60 * 60)
  return Math.abs(Math.round(diff))
}

class Calendar extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      dateStart:null,
      dateEnd:null,
      eventData:[],
      showInsert:false,
      insertDescription:null,
      insertHoursWorked:null,
      insertDate:null,
    }
    this.setTablePignation = this.setTablePignation.bind(this)
    this.handleInsertWorkRecord = this.handleInsertWorkRecord.bind(this)
    this.handleOnHideWorkRecord = this.handleOnHideWorkRecord.bind(this)
  }

  componentDidMount(){
    var current = new Date();   
    this.setState({
      dateEnd:current,
      dateStart:current
    })
    this.getCalendarview(current,current)
  }

  setTablePignation(op){
    Date.prototype.addDays = function (days) {
      let date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
    let value = 0
    if(op==='+')
      value = 1
    
    if(op==='-')
      value = -1
    
    this.getCalendarview(this.state.dateStart.addDays(value),this.state.dateEnd.addDays(value))
    this.setState({
      dateStart:this.state.dateStart.addDays(value),
      dateEnd:this.state.dateEnd.addDays(value),
    })
  }

  getCalendarview(start,end){
    var token = getToken()
    var msalAccessToken = getMSALAccessToken()
    var msalAccessTokenExpiresOn = getMSALAccessTokenExpiresOn()
    if(msalAccessToken && token && msalAccessTokenExpiresOn){
      axios.patch(`${window._env_.REACT_APP_SERVICE_API}/calendar/me/view`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: { 
          'expiresOn':msalAccessTokenExpiresOn,
          'msalAccessToken':msalAccessToken,
          "startDate": dateFormat(start,"yyyy-mm-dd"),
          "endDate": dateFormat(end,"yyyy-mm-dd"),
        }}).then(response => {
          this.setState({
            eventData:response.data
          })
      }).catch(error =>{
        console.log(error.message)
        if(error.message.indexOf('401')>-1)
          killSession()
      })
    }
  }

  handleInsertWorkRecord(description,date,duration){
    this.setState({
      showInsert:true,
      insertDescription:description,
      insertDate:date,
      insertHoursWorked:duration
    })
  }

  handleOnHideWorkRecord(){
    this.setState({
      showInsert:false
    })
  }

  renderEventCard(){
    const {t} = this.props
    var data = this.state.eventData
    var meetingCards = []
    if(data.length === 0){
      meetingCards.push(
        <Col className={'text-center'} key={'noEntries'}>
          <h1>{t('no entries')}</h1>
          <h4>{t('sentences.noEntries')}</h4>
        </Col>
      )
    }
    else{
      data.forEach(element => {
        var start = convertUTCDateToLocalDate(new Date(element.start.dateTime));
        var end = convertUTCDateToLocalDate(new Date(element.end.dateTime));
        var avatars = []
        let left = 0
        let zIndex = 100
        element.attendees.forEach( function(attendee, index) {
          if(index < 6)
            avatars.push(
              <OverlayTrigger
                trigger="click"
                key={index}
                placement='bottom'
                overlay={
                  <Popover id={`popover-positioned-${index}`}>
                    <Popover.Title as="h3">{attendee.emailAddress.name}{' '}</Popover.Title>
                    <Popover.Content>
                      <strong><FontAwesomeIcon icon={faEnvelope} /></strong> <a href={"mailto:" + attendee.emailAddress.address}>{attendee.emailAddress.address}</a><br></br>
                      {attendee.status.response === "accepted" && (<strong><FontAwesomeIcon icon={faUserCheck} /></strong>)}{attendee.status.response === "none" && (<strong><FontAwesomeIcon icon={faUser} /></strong>)}{' '}{t(attendee.status.response)}
                    </Popover.Content>
                  </Popover>
                }
              >
                <img src={defaultImage} alt={attendee.emailAddress.name} style={{position: "absolute", zIndex: (zIndex+index), left: `${left}px`}} className={"avatar"} />
              </OverlayTrigger> 
            )
          left += 20
        })
        meetingCards.push(
          <Col md={6} lg={6} xs={12} key={element.id}>
            <ContextMenuTrigger id={element.id}>
              <div className={"event-card hover border-primary"}>
                <div className={"details"}>
                  <Col md={3} lg={3} xs={3} >
                    <h4>{dateFormat(start,isMobile ? "ddd" : "dddd")}</h4>
                    <h2>{dateFormat(start,"d.mm")}</h2>
                    <p>{dateFormat(start,"HH:MM")} - {dateFormat(end,"HH:MM")}<br></br>
                    {diff_hours(start, end)} {t('hour')}</p>
                  </Col>
                  <Col md={7} lg={7} xs={7}>
                    <Col md={12} lg={12} xs={12}>
                      <h5><b>{element.subject}</b></h5>
                      <p>{element.location.displayName && (<><FontAwesomeIcon icon={faMapMarkerAlt} />{' '}{element.location.displayName}</>)}</p>
                      <div style={{position: "relative"}}>
                        {avatars}
                      </div>
                    </Col>
                  </Col>
                  <Col md={2} lg={2} xs={2}>
                    <div className={'rotate'}>
                      <FontAwesomeIcon style={{color: '#006635'}} icon={element.isOnlineMeeting ? faGlobe : faTasks} size={'3x'}/>
                    </div>               
                  </Col>
                </div>
              </div>
            </ContextMenuTrigger>
            <ContextMenu id={element.id}>
              {element.isOnlineMeeting && dateFormat(start,"yyyy-mm-dd") >= dateFormat(new Date(),"yyyy-mm-dd") && (
              <MenuItem
                onClick={()=> window.open(element.onlineMeeting.joinUrl, '_blank')}
              >
                {t('Join')}
              </MenuItem>)}
              <MenuItem
                onClick={()=>this.handleInsertWorkRecord(element.subject,dateFormat(start,"yyyy-mm-dd"),diff_hours(start,end))}
              >
                {t('Takeover')}
              </MenuItem>
              <MenuItem divider />
              <MenuItem
                onClick={()=> window.open(element.webLink, '_blank')}
              >
                {t('Open')}
              </MenuItem>
            </ContextMenu>
          </Col>
        )
      });
    }
    return meetingCards
  }

  render(){
    const {t} = this.props
    return(
      <>
      <Row className='mt-25'>
        <Col md={8} lg={8} xs={8}>
          <h4>{t('Today`s')} <b>{t('calendar entries')}</b></h4>
          <p>{t('sentences.calendarEntries')}</p>
        </Col>
        <Col md={4} lg={4} xs={4}>

        </Col>
        <Col md={12} lg={12} xs={12}>
        <Pagination>
          <Pagination.Prev onClick={() => this.setTablePignation('-')} size="lg" />
          <Pagination.Item disabled size="lg" ><b>{dateFormat(this.state.dateStart,"dd.mm.yyyy")}</b></Pagination.Item>
          <Pagination.Next onClick={() => this.setTablePignation('+')} size="lg" />
        </Pagination>
        </Col>
      </Row>
      <Row className={'mt-25'}>
        {this.renderEventCard()}
      </Row>
      <WorkModalSmall
        show={this.state.showInsert}
        onHide={()=>this.handleOnHideWorkRecord()}
        description={this.state.insertDescription}
        hoursWorked={this.state.insertHoursWorked}
        date={this.state.insertDate}
      />
      </>
    );
  }
}

export default connect()(withTranslation()(Calendar))