import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import config from '../../../config/Settings.json'
import { connect } from "react-redux";
import { GetProject, ShowAddUserModal, ShowSpinner, GetProjectHourEvaluation } from "../../../store/Actions/index";
import { getToken } from '../../../utils/Common';
import { exportProjectHoursToCSV } from '../../../utils/ExportCSV';
import { getDateMonthsBefore, getDateMonthsAfter } from '../../../utils/DateHelper';
import { Row, Col, Form, Dropdown, DropdownButton, Button, Card, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faUsers, faAngleRight, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { IconEdit, IconPlus } from '../../../assets/icons'
import Error from './Error'
import AddProjectMember from '../components/Modals/AddUserModal';
import ProjectMember from '../../BusinessCard/BusinessCardSmall'
import SelectDateTimeModal from '../components/Modals/SelectDateTimeModal'
import Charts from '../components/Charts/Charts'
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu'
import { withTranslation } from 'react-i18next';
import '../ProjectTracking.css';

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

const Project = (props) => {

  const history = useHistory()
  const dropDownStyle = 'outline-secondary rounded-lg'

  const [data, setData ] = useState({
    Name:null,
    OrderNumber:null,
    Description:null,
    ProjectState:null,
    TimeBudged:null,
    Revenue:null
  })

  const [edit, setEdit ] = useState({
    editBasic:false,
    editAccount:false,
    editAddAccount:false,
    showExport:false,
    editDetail:false
  })

  const [dateValue, setDateValue] = useState({
    currentStartDate:getDateMonthsBefore(new Date(),6),
    currenEndDate:new Date()
  })

  useEffect(() => {
    let id = props.match.params.urlPath
    props.ShowSpinner()
    props.GetProject(id)
    props.GetProjectHourEvaluation(id, dateFormat(dateValue.currentStartDate,"yyyy-mm-dd"),dateFormat(dateValue.currenEndDate,"yyyy-mm-dd"))
  },[props.match.params.urlPath]);

  const showAccount = (e) => {
    history.push(`/employee/${e}`)
  }

  const setTablePignation = (op, type) => {
    var values = dateValue
    if(op ==='+' && type === 'start')
      values.currentStartDate = getDateMonthsAfter(values.currentStartDate,1)
    
    if(op==='-' && type === 'start')
      values.currentStartDate = getDateMonthsBefore(values.currentStartDate,1)

    if(op ==='+' && type === 'end')
      values.currenEndDate = getDateMonthsAfter(values.currenEndDate,1)
    
    if(op==='-' && type === 'end')
      values.currenEndDate = getDateMonthsBefore(values.currenEndDate,1)
    

    setDateValue(prevEdit => ({
      ...prevEdit,
      values
    }));

    props.GetProjectHourEvaluation(props.thisProject.Id, dateFormat(dateValue.currentStartDate,"yyyy-mm-dd"),dateFormat(dateValue.currenEndDate,"yyyy-mm-dd"))
  }

  const handleOnNewExport = (extendedSelection, dateMonthYear, dateFrom, dateTo) => {
    if(!extendedSelection){
      var date = new Date(dateMonthYear);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      exportProjectHoursToCSV(props.thisProject.Id, dateFormat(firstDay,"yyyy-mm-dd"), dateFormat(lastDay,"yyyy-mm-dd"), props.thisProject.Name )
    }
    else
      exportProjectHoursToCSV(props.thisProject.Id, dateFormat(dateFrom,"yyyy-mm-dd"), dateFormat(dateTo,"yyyy-mm-dd"), props.thisProject.Name )

    setEdit(prevEdit => ({
      ...prevEdit,
      showExport: false
    }));
  }


  const handleOnBack = () => {
    history.push('/customer/'+props.thisProject.Customers_fk)
  }

  const mouseOver = (e) => {
    e.target.style.color = '#618BD4';
  }

  const mouseLeave = (e) => {
    e.target.style.color = '#000';
  }

  const handleOnSelectDropdown = (e) => {
    setEdit(prevEdit => ({
        ...prevEdit,
        [e]: true
    }));
  }

  const handleOnCancel = (e) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [e]: false
    }));
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }));
  };

  const handleRemoveManager = (guid) => {
    const token = getToken()
    if(token) {
      axios.delete(`${window._env_.REACT_APP_SERVICE_API}/project/${props.thisProject.Id}/member`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: { 
          'guid':guid
        }}).then(response => {
          if(response.data.affectedRows>0){
            let id=props.match.params.urlPath
            props.ShowSpinner()
            props.GetProject(id)
          }
      }).catch(error =>{
        console.log(error)
      })
    }
  }

  const handleOnSaveProperties = (e) => {
    const token = getToken()
    if(token) {
      axios.put(`${window._env_.REACT_APP_SERVICE_API}/project/${props.thisProject.Id}`, { Name:data.Name, Description:data.Description, OrderNumber:data.OrderNumber, ProjectState:data.ProjectState, TimeBudged:data.TimeBudged, Revenue:data.Revenue}, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(response => {
          if(response.data.changedRows>0){
            handleOnCancel('editBasic')
            props.ShowSpinner()
            props.GetProject(props.thisProject.Id)
            handleOnCancel(e)
          }
      }).catch(error =>{
        console.log(error)
      })
    }
  }

  const renderManager = () => {
    var items = []
    if(props.thisProject.Members){
      for(var j = 0;j<props.thisProject.Members.length;j++){
        let userGuid = props.thisProject.Members[j].guid
        items.push(
          <ProjectMember
            key={userGuid}
            guid={userGuid}
            online={props.connectedEmployees.includes(props.thisProject.Members[j].guid)}
            name={props.thisProject.Members[j].name}
            mail={props.thisProject.Members[j].mail}
            company={props.thisProject.Members[j].company}
            phone={props.thisProject.Members[j].phone}
            photo={props.thisProject.Members[j].photo}
            onDelete={handleRemoveManager}
            onClickAccount={showAccount}
          />
        )
      }
    }
    return items
  }

  const {t} = props
  return(
    <>{props.error.show ?
      <Error />
      :
      <>
      <Row className={'mt-25'}>
        <Col md={12} lg={12} xs={12}>
        <h5>{t('Customer')} <b onMouseLeave={mouseLeave} onMouseOver={mouseOver} onClick={handleOnBack}>{props.thisProject.Customers_Name}</b> <FontAwesomeIcon style={{color: "#618BD4", fontSize: "20px"}} icon={faAngleRight}/><FontAwesomeIcon style={{color: "#618BD4", fontSize: "20px"}} icon={faAngleRight}/> {t('Project')} <b>{props.thisProject.Name}</b></h5>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={6} md={6}>
          <Card
          bg={'success'}
          border={'success'}
          text={'white'}
          >
            <Card.Body>
              <div className={'rotate'}>
                <FontAwesomeIcon icon={faUsers} size={'3x'}/>
              </div>
              <h6 className="text-uppercase">{t('Team Members')}</h6>
              <h1 className="display-4">{props.thisProject.Members ? props.thisProject.Members.length : 0}</h1>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={12} lg={6} md={6}>
          <ContextMenuTrigger id={'hours-card'}>
            <Card
            bg={'info '}
            border={'info '}
            text={'white'}
            >
              <Card.Body>
                <div className={'rotate'}>
                  <FontAwesomeIcon icon={faBriefcase} size={'3x'}/>
                </div>
                <h6 className="text-uppercase">{t('Hours worked')}</h6>
                <h1 className="display-4">{props.thisProjectHourEvaluation && props.thisProjectHourEvaluation.map(a => a.Hours).reduce((a, b) => a + b, 0)}</h1>
              </Card.Body>
            </Card>
          </ContextMenuTrigger>
          <ContextMenu id={'hours-card'}>
            <MenuItem
              onClick={()=>handleOnSelectDropdown("showExport")}
            >
              {t('Hour export')}
            </MenuItem>
          </ContextMenu>
        </Col>
      </Row>
      <Row>
        <Col sm={12} lg={12} md={12}>
          {props.thisProjectHourEvaluation.length > 0 ? (
            <Card
            bg={'white '}
            border={'white '}
            text={'white'}
            >
              <Card.Body>
                <Charts
                  data={[props.thisProjectHourEvaluation.map(a => a.Hours)]}
                  dataLabel={[props.thisProjectHourEvaluation.map(a => a.Month)]}
                  labels={[t('Hours worked')]}
                  colors={ ['#002d73'] }
                  height={ 250 }
                />
              </Card.Body>
            </Card>
          ) : (
          <Col className={'text-center'} key={'noEntries'}>
            <h1>{t('no entries')}</h1>
            <h4>{t('sentences.noEntries')}</h4>
          </Col>
          )}
        </Col>
        <Col sm={12} lg={12} md={12}>
          <Pagination className={'float-left'}>
            <Pagination.Prev onClick={() => setTablePignation('-', 'start')}/>
            <span style={{padding: "10px"}}>{dateFormat(dateValue.currentStartDate,"dd. mmm yyyy")}</span>
            <Pagination.Next onClick={() => setTablePignation('+', 'start')}/>
          </Pagination>
          <Pagination className={'float-right'}>
            <Pagination.Prev onClick={() => setTablePignation('-', 'end')}/>
            <span style={{padding: "10px"}}>{dateFormat(dateValue.currenEndDate,"dd. mmm yyyy")}</span>
            <Pagination.Next onClick={() => setTablePignation('+', 'end')}/>
          </Pagination>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={8} xs={8}>
          <h4>{t('Basic')} <b>{t('Information')}</b></h4>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => handleOnSelectDropdown('editBasic')}
          />
        </Col>
        <Col md={12} lg={6}>
          <label>{t('Name')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="Name" >
            <Form.Control type={'text'} onChange={handleOnChange} defaultValue={props.thisProject.Name}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisProject.Name}</p>
          }
        </Col>
        <Col md={12} lg={6}>
          <label>{t('Order Number')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="OrderNumber" >
            <Form.Control type={'text'} onChange={handleOnChange} defaultValue={props.thisProject.OrderNumber}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisProject.OrderNumber}</p>
          }
        </Col> 
        <Col md={12} lg={6}>
          <label>{t('Project')} {t('State')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="ProjectState" >
            <Form.Control as="select" onChange={handleOnChange} defaultValue={props.thisProject.ProjectState}>
              <option key={"pending"} value={"pending"}>{t('pending')}</option>
              <option key={"verified"} value={"verified"}>{t('verified')}</option>
              <option key={"active"} value={"active"}>{t('active')}</option>
              <option key={"failed"} value={"failed"}>{t('failed')}</option>
              <option key={"closed"} value={"closed"}>{t('closed')}</option>
            </Form.Control>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{t(props.thisProject.ProjectState)}</p>
          }
        </Col>
        <Col md={12} lg={12}>
          <label>{t('Description')}</label>
          {edit.editBasic ?
          <>
          <Form.Group controlId="Description" >
            <Form.Control type={'textarea'} onChange={handleOnChange} defaultValue={props.thisProject.Description}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisProject.Description}</p>
          }
        </Col>
        {edit.editBasic && (
        <>
        <Col md={12} lg={12}>
          <Form.Group>
            <Button onClick={() => handleOnCancel('editBasic')} variant="secondary">{t('Cancel')}</Button>{' '}
            <Button onClick={()=> handleOnSaveProperties('editBasic')} variant="primary" >{t('Save')}</Button>
          </Form.Group>
        </Col>
        </>
        )}
      </Row>

      <Row className={"mt-25"}>
        <Col md={8} lg={8} xs={8}>
          <h4>{t('Detail')} <b>{t('Information')}</b></h4>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconEdit 
            onClick={() => handleOnSelectDropdown('editDetail')}
          />
        </Col>
        <Col md={12} lg={6}>
          <label>{t('Project Revenue')}</label>
          {edit.editDetail ?
          <>
          <Form.Group controlId="Revenue" >
            <Form.Control type={'number'} onChange={handleOnChange} defaultValue={props.thisProject.Revenue}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisProject.Revenue || 0.00}{' €'}</p>
          }
        </Col> 
        <Col md={12} lg={6}>
          <label>{t('Time Budged')}</label>
          {edit.editDetail ?
          <>
          <Form.Group controlId="TimeBudged" >
            <Form.Control type={'number'} onChange={handleOnChange} defaultValue={props.thisProject.TimeBudged}/>
          </Form.Group>
          </> 
          :
          <p className="font-weight-bold" >{props.thisProject.TimeBudged}</p>
          }
        </Col>
        {edit.editDetail && (
        <>
        <Col md={12} lg={12}>
          <Form.Group>
            <Button onClick={() => handleOnCancel('editDetail')} variant="secondary">{t('Cancel')}</Button>{' '}
            <Button onClick={()=> handleOnSaveProperties('editDetail')} variant="primary" >{t('Save')}</Button>
          </Form.Group>
        </Col>
        </>
        )}
      </Row>

      <Row className={"table-title"}>
        <Col xs={8}>
          <h2>{t('Project')} <b>{t('Team')}</b></h2>
        </Col>
        <Col md={4} lg={4} xs={4} className={'text-right'}>
          <IconPlus 
            onClick={() => handleOnSelectDropdown('editAddAccount')}
          />
        </Col>
      </Row>
      <Row>
        {renderManager()}
        {edit.editAccount && (
        <>
        <Col md={12} lg={12}>
          <Form.Group>
            <Button onClick={() => handleOnCancel('editAccount')} variant="primary" >{t('Done')}</Button>
          </Form.Group>
        </Col>
        </>
        )}
      </Row>
      <SelectDateTimeModal
        show={edit.showExport}
        onHide={() => handleOnCancel('showExport')}
        onSubmit={handleOnNewExport}
      />
      <AddProjectMember
        apiRoute={'project'}
        id={props.thisProject.Id}
        onHide={() => handleOnCancel('editAddAccount')}
        show={edit.editAddAccount}
        animation={true}
      >
      </AddProjectMember>
      </>}
    </>
  )
}

const mapStateToProps = state => {
  return {
    error: state.error,
    thisProject: state.thisProject,
    connectedEmployees: state.connectedEmployees,
    thisProjectHourEvaluation: state.thisProjectHourEvaluation
  };
};

export default connect(mapStateToProps,{GetProject, ShowSpinner, ShowAddUserModal, GetProjectHourEvaluation})(withTranslation()(Project));