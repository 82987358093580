import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/Common';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedin, faXing, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import defaultImgae from '../../images/defaultUserM.png'
import defaultBackground from '../../images/defaultUserBackground.jpg'
import onlineImage from '../../images/online.png'
import './BusinessCard.scss'

const UserCard = props => {
  const [photo, setPhoto ] = useState(null)
  const [background, setBackground ] = useState(null)

  useEffect(() => {
    const token = getToken()
    if(token){
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/users/${props.guid}/photo?type=full`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        }).then(response => {
          setPhoto(response.data.photo)
      })
      axios.get(`${window._env_.REACT_APP_SERVICE_API}/users/${props.guid}/background`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        }).then(response => {
          setBackground(response.data.background)
      })
    }
  }, []);

  return(
    <Col sm={12} lg={3} md={4}>
      <div className="card profile-card-3 hover" onClick={!props.edit ? ()=>{props.onClickAccount(props.guid)} : null }>
        <div className="background-block">
          <img src={background ? background : defaultBackground} alt="profile-sample1" className="background"/>
        </div>
        {props.edit && <div style={{position: "absolute", paddingLeft: "15px", paddingTop: "15px"}} className="text-right"><FontAwesomeIcon style={{ fontSize: '30px', color: 'red' }} icon={faTrash} onClick={()=>{props.onDelete(props.guid)}}/></div>}
        <div className="profile-thumb-block">
          <img alt={props.name} src={photo ? photo : defaultImgae} className="profile"/>
          {props.online && <img alt={'isOnline'} src={onlineImage} className="profile-online" />}
        </div>
        <div className="card-content">
          <h2>{props.name}</h2>
          <h2><small>{props.company}</small></h2>
          <h2><small><a href={"mailto:" + props.mail}>{props.mail}</a></small></h2>
          <h2><small><a href={"tel:" + props.phone}>{props.phone}</a></small></h2>
          <div className="icon-block">
            {props.xing && (<i><FontAwesomeIcon onClick={()=> window.open(props.xing, '_blank')} icon={faXing} target={'_blank'}/></i>)}
            {props.linkedin && (<i><FontAwesomeIcon onClick={()=> window.open(props.linkedin, '_blank')} icon={faLinkedin} target={'_blank'}/></i>)}
            {props.facebook && (<i><FontAwesomeIcon onClick={()=> window.open(props.facebook, '_blank')} icon={faFacebookF} target={'_blank'}/></i>)}
            {props.twitter && (<i><FontAwesomeIcon onClick={()=> window.open(props.twitter, '_blank')} icon={faTwitter} target={'_blank'}/></i>)}
          </div>
        </div>
      </div>
    </Col>
  );
}

export default UserCard