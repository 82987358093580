import React, { useState } from 'react';
import { connect } from "react-redux"
import { AddKanbanTaskMember, RemoveKanbanTaskMember, UpdateKanbanTask, RemoveKanbanTask, CreateKanbanTaskComment } from '../../../store/Actions'

import { Col, Modal, Button, Form, Row} from 'react-bootstrap'
import DraggableModalDialog from '../../DraggableModalDialog/DraggableModalDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Avatar from '../../Avatar/Avatar'
import AddMemberOverlay from './AddMemberOverlay'

import { withTranslation } from 'react-i18next'

const dateFormat = require('dateformat')
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

const KanbanTaskModal = (props) => {

  const [data, setData ] = useState({
    title:null,
    description:null,
    startDate:null,
    endDate:null,
    priority:null,
    state:null,
    comment:null,
  })

  const handleOnAddMember = (e) => {
    props.AddKanbanTaskMember(props.kanbanTask.id,e.id)
  }

  const handleOnRemoveMember = (e) => {
    props.RemoveKanbanTaskMember(props.kanbanTask.id,e.guid)
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }));
  }

  const handleOnRemoveTask = () => {
    props.RemoveKanbanTask(props.kanbanTask.id)
    props.onHide()
  }

  const handleOnSubmitComment = () =>{
    if(data.comment){
      props.CreateKanbanTaskComment({taskId:props.kanbanTask.id,content:data.comment})
      setData(prevData => ({
        ...prevData,
        comment:null
      }));
    }
  }

  const handleOnSubmit = (e) => {
    async function saveComments() {
      const token = await handleOnSubmitComment()
    }
    saveComments();
    
    let obj = {
      id:props.kanbanTask.id,
      title:data.title,
      description:data.description,
      startDate:data.startDate ? dateFormat(data.startDate,"yyyy-mm-dd") : null,
      endDate:data.endDate ? dateFormat(data.endDate,"yyyy-mm-dd") : null,
      priority:data.priority,
      state:data.state,
    }
    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key])
    props.UpdateKanbanTask(obj)
    setData(prevData => ({
      ...prevData,
      title:null,
      description:null,
      startDate:null,
      endDate:null,
      priority:null,
      state:null,
    }));
    props.onHide()
  }

  const renderComments = (comments) => {
    var avatars = []
    comments.forEach( function(comment, index) {
      let author = JSON.parse(comment.author)
      avatars.push(
        <Col key={comment.id} md={12} lg={12} xs={12}>
          <div style={{display: "flex"}}>
            <Avatar
              key={author.guid}
              guid={author.guid}
              name={author.name}
            /><small style={{margin: "auto"}} >{author.name}</small>
            <div style={{flex:1}} className={'text-right'}>
              <small className={'float-right'}>{dateFormat(comment.commentTime,"d. mmmm yyyy HH:MM")}</small>
            </div>
          </div>
          <p style={{paddingLeft: '40px'}}>{comment.content}</p>
        </Col>
      )
    })
    return avatars
  }

  const renderProjectMembers = (members) => {
    var avatars = []
    let moveLeft = 10
    let zIndex = 100
    members.forEach( function(attendee, index) {
      avatars.push(<Avatar
        height={35}
        width={35}
        key={attendee.guid}
        guid={attendee.guid}
        name={attendee.name}
        zIndex={zIndex}
        index={index}
        left={moveLeft}
        placement={'right'}
      />)
      moveLeft += 15
    })
    return avatars
  }

  const {t} = props
  return(
    props.kanbanTask && (
      <Modal
        {...props}
        size="lg"
        backdrop="static"
        dialogAs={DraggableModalDialog} 
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        onHide={props.onHide}
      >
        <Modal.Header closeButton >
          <Modal.Title>
            {props.kanbanTask.state === 'Completed' && (<FontAwesomeIcon className={'text-success'} icon={faCircle} />)}
            {props.kanbanTask.state === 'Not started' && (<FontAwesomeIcon className={'text-warning'} icon={faCircle} />)}
            {props.kanbanTask.state === 'In work' && (<FontAwesomeIcon className={'text-info'} icon={faCircle} />)}
            <span>{props.kanbanTask.title}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {props.fullControll && (
            <Row>
              <Col>
                {props.kanbanTask.members && (renderProjectMembers(JSON.parse(props.kanbanTask.members)))}
              </Col>
              <Col className={'text-right'}>
                <AddMemberOverlay
                  key={props.kanbanTask.id}
                  members={JSON.parse(props.kanbanTask.members)}
                  handleOnAdd={handleOnAddMember}
                  handleOnRemove={handleOnRemoveMember}
                />
              </Col>
            </Row>
          )}
          <Form>
            {props.fullControll && (
            <Form.Group controlId="title">
              <Form.Label>{t('Title')}</Form.Label>
              <Form.Control onChange={handleOnChange} type="text" defaultValue={props.kanbanTask.title} placeholder={t('Title')} />
            </Form.Group>
            )}
            <Form.Row>
              <Form.Group controlId="state" as={Col}>
                <Form.Label>{t('State')}</Form.Label>
                <Form.Control onChange={handleOnChange} as="select" >
                  {props.kanbanTask.state === 'Not started' ? <option selected value="Not started">{t('Not started')}</option> : <option value="Not started">{t('Not started')}</option>}
                  {props.kanbanTask.state === 'In work' ? <option selected value="In work">{t('In work')}</option> : <option value="In work">{t('In work')}</option>}
                  {props.kanbanTask.state === 'Completed' ? <option selected value="Completed">{t('Completed')}</option> : <option value="Completed">{t('Completed')}</option>}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="priority" as={Col}>
                <Form.Label>{t('Priority')}</Form.Label>
                <Form.Control onChange={handleOnChange} as="select">
                  {props.kanbanTask.priority === 'Urgent' ? <option selected value="Urgent">{t('Urgent')}</option> : <option value="Urgent">{t('Urgent')}</option>}
                  {props.kanbanTask.priority === 'Important' ? <option selected value="Important">{t('Important')}</option> : <option value="Important">{t('Important')}</option>}
                  {props.kanbanTask.priority === 'Normal' ? <option selected value="Normal">{t('Normal')}</option> : <option value="Normal">{t('Normal')}</option>}
                  {props.kanbanTask.priority === 'Irrelevant' ? <option selected value="Irrelevant">{t('Irrelevant')}</option> : <option value="Irrelevant">{t('Irrelevant')}</option>}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group controlId="startDate" as={Col}>
                <Form.Label>{t('Start date')}</Form.Label>
                <Form.Control defaultValue={props.kanbanTask.startDate ? dateFormat(props.kanbanTask.startDate,"yyyy-mm-dd"): null} onChange={handleOnChange} type="date" />
              </Form.Group>
              <Form.Group controlId="endDate" as={Col}>
                <Form.Label>{t('Due date')}</Form.Label>
                <Form.Control defaultValue={props.kanbanTask.endDate ? dateFormat(props.kanbanTask.endDate,"yyyy-mm-dd") : null} onChange={handleOnChange} type="date" />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="description">
              <Form.Label>{t('Description')}</Form.Label>
              <Form.Control as="textarea" onChange={handleOnChange} type="text" defaultValue={props.kanbanTask.description} placeholder={t('Description')} />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Body>
          <Form>
            <Form.Group controlId="comment">
              <Form.Label><b>{t('Comments')}</b></Form.Label>
              <Form.Control as="textarea" onChange={handleOnChange} type="text" placeholder={t('Type comment')} />
            </Form.Group>
            <Button size="sm" onClick={handleOnSubmitComment} variant="primary" >{t('Send')}</Button>
          </Form>
          <Row className={'mt-25'}>
            {props.kanbanTaskComments && (renderComments(props.kanbanTaskComments))}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Col>
            {props.fullControll && (
              <Button onClick={handleOnRemoveTask} variant="secondary">{t('Delete')}</Button>
            )}
          </Col>
          <Button onClick={props.onHide} variant="secondary">{t('Close')}</Button>{' '}
          <Button onClick={handleOnSubmit} variant="primary" >{t('saveClose')}</Button>{' '}
        </Modal.Footer>
      </Modal>
    )
  )
}

const mapStateToProps = state => {
  return {
    kanbanTask: state.kanbanTask,
    kanbanTaskComments: state.kanbanTaskComments
  };
};

export default connect(mapStateToProps,{AddKanbanTaskMember, RemoveKanbanTaskMember, UpdateKanbanTask, RemoveKanbanTask, CreateKanbanTaskComment })(withTranslation()(KanbanTaskModal));