import { LIST_EXPENSES, INSERT_EXPENSES, REMOVE_EXPENSES } from "../Constants/action-types";

import { getToken, getUser } from '../../utils/Common';
import config from '../../config/Settings.json';

export function RemoveExpenses(id){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'DELETE',
        'url': window._env_.REACT_APP_SERVICE_API + '/expenses/' + id,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        return dispatch({ type: REMOVE_EXPENSES, payload: id });
      });
    }
  }
}

export function InsertExpenses(obj){
  return function(dispatch){
    const token = getToken()
    const user = getUser()
    if(token && user){
      var request = require('request');
      var options = {
        'method': 'PUT',
        'url': window._env_.REACT_APP_SERVICE_API + '/expenses',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "Employee":user.username,
          "Date":obj.Date,
          "VoucherNumber":obj.VoucherNumber,
          "Type":obj.Type,
          "TotalCost":obj.TotalCost,
          "Project_fk":obj.Project_fk,
          "Customer_fk":obj.Customer_fk,
          "Description":obj.Description,
          "FileLink":obj.FileLink,
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        obj = JSON.parse(response.request.body)
        if(json.insertId){
          obj.Id = json.insertId
          obj.TotalCost = parseFloat(obj.TotalCost)
          obj.VoucherNumber = parseInt(obj.VoucherNumber)
          console.log(obj)
          return dispatch({ type: INSERT_EXPENSES, payload: obj });
        }
      });
    }
  }
}

export function ListExpenses(start,count){
  return function(dispatch){
    const user = getUser()
    const token = getToken()

    if(token && user){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/expenses',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"start":start,"count":count})
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_EXPENSES, payload: json });
        }
      });
    }
  }
}