import React, { Component } from 'react';
import axios from 'axios';
import config from '../../../../config/Settings.json'
import { connect } from "react-redux";
import { InsertExpenses } from "../../../../store/Actions/index";
import { getToken } from '../../../../utils/Common';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import DraggableModalDialog from '../../../DraggableModalDialog/DraggableModalDialog'
import { withTranslation } from 'react-i18next';

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

class ExpensesModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      Date:null,
      VoucherNumber:'',
      Type:'Kraftstoff',
      Description:'',
      TotalCost:0,
      FileLink: '',
      selectedFile: null,
      ProjectCost:false,
      Customer_fk:null,
      Project_fk:null,
      CustomerOptions:'',
      ProjectOptions:''
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleProjectCost = this.handleProjectCost.bind(this)
    this.handleCustomerSelect = this.handleCustomerSelect.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.handleOnSubmitAndNext = this.handleOnSubmitAndNext.bind(this)
    this.handleImageChange = this.handleImageChange.bind(this)
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.show && !this.props.show){
      let token = getToken()
  
      if(token){
        const header = {
          headers: { Authorization: `Bearer ${token}` }
        };
    
        axios.get(`${window._env_.REACT_APP_SERVICE_API}/customers`,header).then(response => {
          var items = []
          for(var j = 0; j<response.data.length;j++){
            items.push(<option key={response.data[j].Id} value={response.data[j].Id}>{response.data[j].Name}</option>)
          }
          this.setState({
            CustomerOptions:items,
            Date:dateFormat(Date.now(),"yyyy-mm-dd"),
            VoucherNumber:'',
            Type:'Kraftstoff',
            Description:'',
            TotalCost:0,
            FileLink: '',
            selectedFile: null,
            ProjectCost:false,
            Customer_fk:null,
            Project_fk:null,
          })
        }).catch(error => {
          this.setState({
            CustomerOptions:<option >Error</option>,
            Date:dateFormat(Date.now(),"yyyy-mm-dd"),
            VoucherNumber:'',
            Type:'Kraftstoff',
            Description:'',
            TotalCost:0,
            FileLink: '',
            selectedFile: null,
            ProjectCost:false,
            Customer_fk:null,
            Project_fk:null,
          })
        });
      }
    }
  }

  handleImageChange(e) {
    e.preventDefault();
    let file = e.target.files[0];
    if(file.size > 3480260)
      return false

    if(file.type !== 'application/pdf' && file.type !== 'image/png' && file.type !== 'image/jpg')
      return false

    var uploadElement = { filename: file.name, contentType: file.type, encoding:null, path:null}
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      uploadElement.path = e.target.result
      uploadElement.encoding = 'base64'
      this.setState({FileLink: uploadElement, selectedFile: file.name});
      console.log(uploadElement)
    }
  }

  handleProjectCost(){
    var value = this.state.ProjectCost
    this.setState({
      ProjectCost:!value,
      Customer_fk:null,
      Project_fk:null
    })
  }

  handleCustomerSelect(event){
    let token = getToken()

    if(token){
      this.setState({[event.target.id]: event.target.value})

      const header = {
        headers: { Authorization: `Bearer ${token}` }
      };

      axios.get(`${window._env_.REACT_APP_SERVICE_API}/projects/${event.target.value}`,header).then(response => {
        var items = []
        for(var j = 0; j<response.data.length;j++){
          items.push(<option value={response.data[j].Id}>{response.data[j].Name}</option>)
        }
        this.setState({
          ProjectOptions:items
        })
      }).catch(error => {
        this.setState({
          ProjectOptions:<option >Error</option>
        })
      });
    }
  }

  handleOnSubmit(){
    this.props.InsertExpenses({Date:dateFormat(this.state.Date,"yyyy-mm-dd"), VoucherNumber:this.state.VoucherNumber, Type:this.state.Type, TotalCost:this.state.TotalCost, Project_fk:this.state.Project_fk, Description:this.state.Description, Customer_fk:this.state.Customer_fk})
    this.props.onHide()
  }

  handleOnSubmitAndNext(){
    this.props.InsertExpenses({Date:dateFormat(this.state.Date,"yyyy-mm-dd"), VoucherNumber:this.state.VoucherNumber, Type:this.state.Type, TotalCost:this.state.TotalCost, Project_fk:this.state.Project_fk, Description:this.state.Description, Customer_fk:this.state.Customer_fk})
    this.setState({
      FileLink: '',
      selectedFile: null,
      ProjectCost:false,
      Customer_fk:null,
      Project_fk:null,
    })
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="lg"
      backdrop="static"
      dialogAs={DraggableModalDialog} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Record')}  <b>{t('Expenses')}</b>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Row>
            <Form.Group controlId="Date" as={Col}>
              <Form.Label>{t('Date')}</Form.Label>
              <Form.Control defaultValue={dateFormat(Date.now(),"yyyy-mm-dd")} onChange={this.handleOnChange} type="date" />
            </Form.Group>

            <Form.Group controlId="Type" as={Col}>
              <Form.Label>{t('Type')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} as="select" >
                <option value="Kraftstoff">{t('Fuel')}</option>
                <option value="Bürobedarf">{t('Office supplies')}</option>
                <option value="Reisekosten">{t('Travel expenses')}</option>
                <option value="Sonstiges">{t('Others')}</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>  
            <Form.Group controlId="TotalCost" as={Col}>
              <Form.Label>{t('Cost')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="number" placeholder="0.00" />
            </Form.Group>

            <Form.Group controlId="VoucherNumber" as={Col}>
              <Form.Label>{t('voucherNumber')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="text" placeholder={t('voucherNumber')} />
            </Form.Group>
          </Form.Row>

          <Form.Group controlId="Description" >
            <Form.Label>{t('Description')}</Form.Label>
            <Form.Control as="textarea" onChange={this.handleOnChange} type="text" placeholder={t('Description')} />
          </Form.Group>

          <Form.Group onChange={this.handleProjectCost}>
            <Form.Label as="legend" column sm={2}>
            {t('Project')} {t('Expenses')}
            </Form.Label>
            <Col sm={10}>
            <Form.Check 
              type="switch"
              id="ProjectCost"
              label={t('no') + " / " + t('yes')}
              checked={this.state.ProjectCost}
              />
            </Col>
          </Form.Group>

          <Form.Row hidden={!this.state.ProjectCost}>
            <Form.Group controlId="Customer_fk" as={Col}>
              <Form.Label>{t('Customer')}</Form.Label>
              <Form.Control onChange={this.handleCustomerSelect.bind(this)} as="select" >
                {this.state.CustomerOptions}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="Project_fk" as={Col}>
              <Form.Label>{t('Project')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} as="select" >
                {this.state.ProjectOptions}
              </Form.Control>
            </Form.Group>
          </Form.Row>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        <Button onClick={this.handleOnSubmitAndNext} variant="secondary" >{t('saveNext')}</Button>{' '}
        <Button onClick={this.handleOnSubmit} variant="primary" >{t('saveClose')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

export default connect(null,{InsertExpenses})(withTranslation()(ExpensesModal));