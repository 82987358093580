import { ClientAuthError, InteractionRequiredAuthError, UserAgentApplication } from 'msal'
import Identity from '../models/Identity'
import { getUser } from './Common'
import config from '../config/Settings.json'

class AuthService {
  constructor(configuration) {
    this.signInOptions = {
      scopes: configuration.scopes
    };

    this.msalConfig = {
      auth: {
        authority: configuration.authority,
        clientId: configuration.clientId,
        redirectUri: window.location.origin
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
      }
    };

    this.msalClient = new UserAgentApplication(this.msalConfig);
  }

  get serviceName() { return 'Microsoft'; }

  async signIn() {
    const response = await this.msalClient.loginPopup(this.signInOptions);
    return new Identity(response);
  }

  async signInSSO() {
    const user = getUser()
    if(user){
      const response = await this.msalClient.ssoSilent({loginHint:user.mail});
      return new Identity(response);
    }
    else{
      const response = await this.msalClient.loginPopup(this.signInOptions);
      return new Identity(response);
    }
  }

  async renewIdToken() {
    const account = this.msalClient.getAccount();
    if (account) {
      try {
        const response = await this.msalClient.acquireTokenSilent(this.signInOptions);
        return new Identity(response);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          throw new Error(error);
        }
        if (error instanceof ClientAuthError) {
          if (error.errorCode === 'block_token_requests') {
            throw new Error(error);
          }
          console.warn('ClientAuthError: error code = ', error.errorCode);
          return null
        }
        throw error;
      }
    }
  }

  signOut() {
    this.msalClient.logout();
  }

  async getIdentity() {
    const account = this.msalClient.getAccount();
    if (account) {
      try {
        const response = await this.msalClient.acquireTokenSilent(this.signInOptions);
        return new Identity(response);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          throw new Error(error);
        }
        if (error instanceof ClientAuthError) {
          if (error.errorCode === 'block_token_requests') {
            throw new Error(error);
          }
          console.warn('ClientAuthError: error code = ', error.errorCode);
        }
        throw error;
      }
    }
  }
}

var msal = {
  "authority": config.msal.authority,
  "clientId" : window._env_.REACT_APP_MSAL_CLIENTID,
  "clientSecret" : window._env_.REACT_APP_MSAL_CLIENTSECRET,
  "subscriptionId" : window._env_.REACT_APP_MSAL_SUBSCRIPTION,
  "scopes": config.msal.scopes
}
const authService = new AuthService(msal);

export default authService;