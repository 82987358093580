import { LogoutUser } from "../Actions/index"
import { setMSALSession } from '../../utils/Common'
import authService from '../../utils/AuthHelper'

export const refreshMSAL = ({ dispatch }) => next => {
  const refreshToken = async () => {
    var expiresOn = localStorage.getItem('msal.expiresOn')
    if(expiresOn){
      expiresOn = expiresOn / 100
      var expirationDate = new Date((expiresOn*100));
      expirationDate.setMinutes(expirationDate.getMinutes() -5)
      var currentDate = new Date()

      if(currentDate >= expirationDate){
        const identity = await authService.signInSSO()
        if(identity){
          const msalAccount = await authService.getIdentity()
          if(msalAccount){
            console.log("msal.silent")
            setMSALSession(msalAccount.accessToken,msalAccount.tenantId,parseInt((new Date(msalAccount.expiresOn).getTime())))
          }
        }
        else{
          localStorage.clear()
          LogoutUser()
          window.location.href = '/expired'
        }
      }
      setTimeout(refreshToken, 5000);
    }
  };

  refreshToken();
  return action => next(action);
};

export default refreshMSAL