import React, { Component } from 'react';
import config from '../../../config/Settings.json'
import { connect } from "react-redux";
import { ToggleGridView, ShowSpinner, ListCustomers, HideCustomer, SelectCustomer, GetRole } from "../../../store/Actions/index";
import { getToken } from '../../../utils/Common';
import { Row, Col, Table, Button, Pagination } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faThLarge } from '@fortawesome/free-solid-svg-icons'
import CustomerTableRow from '../components/TableRows/CustomerTableRow';
import CustomerCard from '../components/Cards/CustomerCard';
import CustomerModal from '../components/Modals/CustomerModal';
import { withTranslation } from 'react-i18next';
import '../ProjectTracking.css';

class Customers extends Component{
  constructor(props) {
    super(props);
    this.state = {
      tableStartIndex:0
    }
    this.renderTable = this.renderTable.bind(this);
    this.setTablePignation = this.setTablePignation.bind(this);
    this.showCustomer = this.showCustomer.bind(this)
  }

  setTablePignation(op){
    var value = this.state.tableStartIndex
    if(op==='+')
      value = value+config.maxTableRows
    
    if(op==='-')
      value = value-config.maxTableRows
     
    this.props.ShowSpinner()
    this.props.ListCustomers(value,config.maxTableRows)
    this.setState({
      tableStartIndex:value
    })
  }

  UNSAFE_componentWillMount(){
    if(getToken()){
      this.props.ShowSpinner()
      this.props.GetRole()
      this.props.ListCustomers(0,config.maxTableRows)
    }
  }

  showCustomer(e){
      this.props.history.push(`/customer/${e}`)
  }

  renderGrid(){
    var items = []
    for(var j = 0; j<this.props.customers.length;j++){
      items.push(<CustomerCard
        key={this.props.customers[j].Id}
        id={this.props.customers[j].Id}
        name={this.props.customers[j].Name}
        location={this.props.customers[j].Location}
        address={this.props.customers[j].Address}
        postalCode={this.props.customers[j].PostalCode}
        onClickCustomer={this.showCustomer}
      />)
    }
    return items;
  }

  renderTable(){
    var items = []
    for(var j = 0; j<this.props.customers.length;j++){
      items.push(<CustomerTableRow
        key={this.props.customers[j].Id}
        Members={this.props.customers[j].Members}
        Id={this.props.customers[j].Id}
        Name={this.props.customers[j].Name}
        Location={this.props.customers[j].Location}
        CustomerNumber={this.props.customers[j].CustomerNumber}
        Projects={this.props.customers[j].Projects}
        History={this.props.history}
      >
      </CustomerTableRow>)
    }
    return items;
  }

  render(){
    const {t} = this.props
    return(
      <>
      <Row className={"table-title"}>
        <Col xs={5}>
          <h2>{t('Customer')} <b>{t('Management')}</b></h2>
        </Col>
        <Col xs={7}>
        <Button
              className={'edit-btn'}
              variant={'outline-secondary'}
              onClick={()=>this.props.ToggleGridView()}>
              <FontAwesomeIcon icon={faThLarge}/>
            </Button>{' '}
          {(this.props.userRole === 'admin' || this.props.userRole === 'manager') && <Button 
            variant="outline-secondary"
            onClick={()=>this.props.SelectCustomer(null)} >
            <FontAwesomeIcon icon={faPlus} />
            {' '}{t('New Customer')}
          </Button>}
        </Col>
      </Row>
      <Row>
        <> {this.props.gridView ? 
        <>
          {this.renderGrid()}
        </>:
          <Table responsive hover>
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Responsilble')}</th>
                <th className='d-none d-md-none d-lg-table-cell' >{t('Projects')}</th>
                <th className='d-none d-md-none d-lg-table-cell' >{t('Location')}</th>
                <th className='d-none d-md-none d-lg-table-cell' >{t('customerNumber')}</th>
                <th>{t('Action')}</th>
              </tr>
            </thead>
            <tbody>
              {this.renderTable()}
            </tbody>
          </Table>
        } </>
      </Row>
        <Pagination>
          {this.state.tableStartIndex > 0 ? <Pagination.Prev onClick={() => this.setTablePignation('-')}/> : <></>}
          {this.props.customers.length >= config.maxTableRows ? <Pagination.Next onClick={() => this.setTablePignation('+')}/> : <></>}
        </Pagination>
        <CustomerModal
          show={this.props.customerModalShow}
          onHide={() => this.props.HideCustomer()}>
        </CustomerModal>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    gridView: state.gridView,
    userRole: state.userRole,
    customers: state.customers,
    customerModalShow: state.customerModalShow,
  };
};

export default connect(mapStateToProps,{ToggleGridView, ShowSpinner, ListCustomers, HideCustomer, SelectCustomer, GetRole})(withTranslation()(Customers));