import React, { Component } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { ShowSpinner, ListTimeSheets } from "../../../../store/Actions/index";
import SignatureCanvas from 'react-signature-canvas';
import { getToken } from '../../../../utils/Common';
import { Modal, Button } from 'react-bootstrap';
import DraggableModalDialog from '../../../DraggableModalDialog/DraggableModalDialog'
import { withTranslation } from 'react-i18next';

class SignatureModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      trimmedDataURL:null,
      signed:false
    }
  }
  sigPad = {}

  clear = () => {
    this.sigPad.clear()
    this.props.onHide()
  }
  trim = () => {
    this.setState({
      trimmedDataURL: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
      signed:true
    })
  }

  handleOnSubmit = () => {
    let token = getToken()
    if(token && this.state.trimmedDataURL) {
      axios.post(`${window._env_.REACT_APP_SERVICE_API}/sheet/time/${this.props.timeSheetToSign}/sign`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        data: { 
          'data':this.state.trimmedDataURL
        }}).then(response => {
          if(response.status === 204){
            this.props.ShowSpinner()
            this.props.ListTimeSheets()
            this.props.onHide()
          }      
      }).catch(error =>{
        console.log(error)
      }) 
    }
  }

  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="md"
      backdrop="static"
      dialogAs={DraggableModalDialog} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Sign')} <b>{t('document')}</b>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {t('sentences.digitalSign')}
      </Modal.Body>
      <Modal.Body>
        <SignatureCanvas
          canvasProps={{width: 500, height: 200,}}
          penColor={'green'}
          ref={(ref) => { this.sigPad = ref }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={this.clear} variant="secondary">{t('Cancel')}</Button>{' '}
        {this.state.signed ? <Button onClick={this.handleOnSubmit} variant="primary" >{t('Save')}</Button> : <Button onClick={this.trim} variant="primary" >{t('Sign')}</Button>}
        {' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

const mapStateToProps = state => {
  return {
    timeSheetToSign: state.timeSheetToSign, 
    signModalShow: state.signModalShow,
  };
};

export default connect(mapStateToProps,{ ShowSpinner, ListTimeSheets })(withTranslation()(SignatureModal));