import React, { Component } from 'react';
import { connect } from "react-redux";
import { InsertTimeSheet } from "../../../../store/Actions/index";
import { Col, Modal, Button, Form } from 'react-bootstrap';
import DraggableModalDialog from '../../../DraggableModalDialog/DraggableModalDialog'
import { withTranslation } from 'react-i18next';

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

class SelectDateTimeModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      dateFrom:null,
      dateTo:null,
      dateMonthYear:null,
      extendedSelection:false
    }
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnExtendedSelection = this.handleOnExtendedSelection.bind(this)
    this.handleOnSubmit = this.handleOnSubmit.bind(this)
  }

  componentWillUnmount(){
    this.setState({
      dateFrom:null,
      dateTo:null,
      dateMonthYear:null,
      extendedSelection:false
    })
  }

  handleOnSubmit(){
    this.props.onSubmit(this.state.extendedSelection,this.state.dateMonthYear,this.state.dateFrom,this.state.dateTo)
  }

  handleOnChange(e){
    this.setState({[e.target.id]: e.target.value})
  }

  handleOnExtendedSelection(e){
    this.setState({extendedSelection:e.target.checked})
  }

  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="md"
      backdrop="static"
      dialogAs={DraggableModalDialog} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Time')} <b>{t('Sheet')}</b>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {t('sentences.selectMonthTimeSheet')}
      </Modal.Body>
      <Modal.Body>
        <Form>
          {this.state.extendedSelection ? 
          <Form.Row>
            <Form.Group controlId="dateFrom" as={Col}>
              <Form.Label>{t('Date')} {t('from')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="date" />
            </Form.Group>

            <Form.Group controlId="dateTo" as={Col}>
              <Form.Label>{t('Date')} {t('to')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="date" />
            </Form.Group>
          </Form.Row>
          :
          <Form.Row>
            <Form.Group controlId="dateMonthYear" as={Col}>
              <Form.Label>{t('selectMonth')}</Form.Label>
              <Form.Control onChange={this.handleOnChange} type="month" />
            </Form.Group>
          </Form.Row>
          }
          <fieldset>
            <Form.Group onChange={this.handleOnExtendedSelection}>
              <Col sm={10}>
              <Form.Check 
                type="switch"
                id="extended"
                label={t('extendedSelection')}
                checked={this.state.extendedSelection}
                />
              </Col>
            </Form.Group>
          </fieldset>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        <Button onClick={this.handleOnSubmit} variant="primary" >{t('Save')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

export default connect(null,{InsertTimeSheet})(withTranslation()(SelectDateTimeModal));