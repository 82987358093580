export default class Identity {
  constructor(tokenResponse) {
    this.account = tokenResponse.account
    this.rawIdToken = tokenResponse.idToken.rawIdToken
    this.response = tokenResponse
  }

  get userId() {
    return this.account.accountIdentifier;
  }

  get emailAddress() {
    return this.account.userName;
  }

  get name() {
    return this.account.name;
  }

  get idToken() {
    return this.rawIdToken;
  }

  get accessToken() {
    return this.response.accessToken
  }

  get expiresOn() {
    return this.response.expiresOn
  }

  get tenantId() {
    return this.response.tenantId
  }

}