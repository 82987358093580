import React, { useState, useEffect } from 'react';
import { getMSALAccessToken } from '../../utils/Common'
import { connect } from "react-redux"
import { ShowSpinner, WhoIam, ListMyNextKanbanTask } from "../../store/Actions/index"
import { withTranslation } from 'react-i18next'

import { Row, Col, Card } from 'react-bootstrap'

import Calendar from './components/Calendar'
import WorkEvaluation from './components/WorkEvaluation'
import CustomersProjectsAnalysis from './components/CustomersProjectsAnalysis'
import PendingTasks from './components/PendingTasks'

const dateFormat = require('dateformat');
dateFormat.i18n = {
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

const Dashboard = props => {

  const [windowWidth, setWindowWidth ] = useState(window.innerWidth)

  useEffect(() => {
    var current = new Date(); 
    window.onresize = () => {
      setWindowWidth(window.innerWidth); 
    };
    if(!props.user.guid){
      props.ShowSpinner()
      props.WhoIam()
    }
    props.ListMyNextKanbanTask(dateFormat(current, 'yyyy-mm-dd'),dateFormat(current, 'yyyy-mm-dd'))
  },[]);

  const {t} = props
  return(<>
    <Row className='mt-25'>
      <Col md={12} lg={12} xs={12}>
        <h2>{t('Hello')}, <b>{props.user.givenname}</b></h2>
      </Col>
      <Col md={12} lg={12} xs={12}>
        <p style={{fontSize: '5rem'}}>{dateFormat(Date.now(),"hh:MM TT")}</p>
      </Col>
    </Row>
    <Card style={{border: 'none'}}>
      <WorkEvaluation windowWidth={windowWidth-20}/>
    </Card>
    
    {getMSALAccessToken() && (
      <Card style={{border: 'none'}}>
        <Calendar />
      </Card>
    )}
    <Card style={{border: 'none'}}>
      <PendingTasks/>
    </Card>
    <Card style={{border: 'none'}}>
      <CustomersProjectsAnalysis windowWidth={windowWidth-20}/>
    </Card>
    </>
  );
  
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps,{ShowSpinner, WhoIam, ListMyNextKanbanTask})(withTranslation()(Dashboard));