import React, { useState } from 'react'
import { connect } from "react-redux"
import { WhoIam, ShowSpinner, ListKanban, DestroyKanbanTaskStore, GetKanbanTask, ListKanbanTaskComment } from "../../../store/Actions/index"
import { Row, Col } from 'react-bootstrap'
import KanbanTaskCard from './KanbanTaskCard'
import KanbanTaskModal from './KanbanTaskModal'
import { withTranslation } from 'react-i18next'

const TaskBucket = props => {
  const [ showModal, setShowModal] = useState(false)

  const handleOnEditTask = (e, project) => {
    props.GetKanbanTask(project.id)
    props.ListKanbanTaskComment(project.id)
    setShowModal(true)
  }

  const handleOnHideTask = () => {
    setShowModal(false)
    props.DestroyKanbanTaskStore()
  }

  const renderTasks = (tasks) => {
		return tasks.slice(0).map((project) => {
			return (
				<KanbanTaskCard
					project={project}
					key={project.id}
          onEditTask={handleOnEditTask}
				/>
			);
		});
	}

  const {t} = props
  return(
    <Row>
      <Col md={6} lg={3} xs={12} style={{textAlign: 'center'}}>
        <p><b>{t('Urgent')}</b></p>
        {props.myTasks && renderTasks(props.myTasks.filter((task) => {return task.priority === 'Urgent';}))}
      </Col>
      <Col md={6} lg={3} xs={12} style={{textAlign: 'center'}}>
        <p><b>{t('Important')}</b></p>
        {props.myTasks && renderTasks(props.myTasks.filter((task) => {return task.priority === 'Important';}))}
      </Col>
      <Col md={6} lg={3} xs={12} style={{textAlign: 'center'}}>
        <p><b>{t('Normal')}</b></p>
        {props.myTasks && renderTasks(props.myTasks.filter((task) => {return task.priority === 'Normal';}))}
      </Col>
      <Col md={6} lg={3} xs={12} style={{textAlign: 'center'}}>
        <p><b>{t('Irrelevant')}</b></p>
        {props.myTasks && renderTasks(props.myTasks.filter((task) => {return task.priority === 'Irrelevant';}))}
      </Col>
      <KanbanTaskModal 
        show = {showModal}
        onHide = {handleOnHideTask}
      />
    </Row>
  )
}

export default connect(null,{WhoIam, ShowSpinner, ListKanban, DestroyKanbanTaskStore, GetKanbanTask, ListKanbanTaskComment})(withTranslation()(TaskBucket));