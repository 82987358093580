export const sort_by_key = (array, key) => {
 return array.sort(function(a, b){
  var x = a[key]; var y = b[key];
  return ((x < y) ? -1 : ((x > y) ? 1 : 0));
 });
}

export const sort_by_key_desc = (array, key) => {
  return array.sort(function(a, b){
    var x = b[key]; var y = a[key];
    return ((x < y) ? -1 : ((x > y) ? 1 : 0));
  });
}
