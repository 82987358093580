import axios from 'axios';
import { LogoutUser } from "../store/Actions/index";
import authService from './AuthHelper'

export const getUser = () => {
  const userStr = localStorage.getItem('faal.user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

export const verifyToken = () => {
  var token = localStorage.getItem('faal.token');
  if(token){
    axios.get(`${window._env_.REACT_APP_SERVICE_API}/auth/verifyToken?token=${token}`).then(response => {
      setUserSession(response.data.token, response.data.user);
      return response.data.token;
    }).catch(error => {
      removeUserSession();
      return null
    });
  }
  else{
    return null
  }
}

export const getToken = () => {
  return localStorage.getItem('faal.token') || null;
}
 
export const removeUserSession = () => {
  localStorage.removeItem('faal.token')
  localStorage.removeItem('faal.user')
  localStorage.clear()
  LogoutUser()
}

export const setUserSession = (token, user) => {
  if(token && user) {
    localStorage.setItem('faal.token', token)
    localStorage.setItem('faal.user', JSON.stringify(user))
  }
}

export const setMSALSession = (accessToken, tenantId, expiresOn) => {
  if(accessToken && tenantId) {
    localStorage.setItem('msal.accessToken', accessToken)
    localStorage.setItem('msal.tenantId',tenantId)
    localStorage.setItem('msal.expiresOn',expiresOn)
  }
}

export const getMSALAccessTokenExpiresOn = () => {
  return localStorage.getItem('msal.expiresOn') || null;
}

export const getMSALTenantId = () => {
  return localStorage.getItem('msal.tenantId') || null;
}

export const getMSALAccessToken = () => {
  return localStorage.getItem('msal.accessToken') || null;
}

export const runSession = () => {
  var the_interval = 2 * 60 * 1000;
  if(localStorage.getItem('msal.expiresOn')){
    setInterval(async function() {
      var expiresOn = localStorage.getItem('msal.expiresOn')
      expiresOn = expiresOn / 100
      var expirationDate = new Date((expiresOn*100));
      expirationDate.setMinutes(expirationDate.getMinutes() -5)
      var currentDate = new Date()
      if(currentDate >= expirationDate){
        var res = await authService.renewIdToken()
        if(res)
          setMSALSession(res.accessToken,res.tenantId,parseInt((new Date(res.expiresOn).getTime())))
        else
          killSession()
      }
    }, the_interval);
  }
}

export const killSession = () => {
  localStorage.clear()
  LogoutUser()
  window.location.href = '/expired'
}