import React from 'react'
import axios from 'axios';
import config from '../../../config/Settings.json'

import { getToken } from '../../../utils/Common';

import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import { Row, Col, Button, Overlay, Popover} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import userAddImage from '../images/userAdd.png'
import Avatar from '../../Avatar/Avatar'
import { withTranslation } from 'react-i18next'

const AsyncTypeahead = withAsync(Typeahead);

class AddMemberOverlay extends React.Component{
  constructor(props){
    super(props)
    this.state = {
      show:false,
      isLoading:false,
      shouldUpdate:true,
      target:null,
      userList:[],
      selected:null,
      assignedMembers:[]
    }
    this.ref = React.createRef();
    this.handleOnSelectedClick = this.handleOnSelectedClick.bind(this)
  }

  shouldComponentUpdate(nextProps){
    if(nextProps.members && this.state.shouldUpdate)
      this.setState({assignedMembers:nextProps.members,shouldUpdate:false})
    return true
  }

  handleClick(e){
    this.setState({
      show:!this.state.show,
      target:e.target
    })
  }

  handleOnSelectedClick(e){
    this.setState({selected:this.state.selected.filter(element=> element.id !== e.id)})
    this.props.handleOnAdd(e)
  }

  renderSelected(members){
    var avatars = []
    for(var j = 0; j<members.length;j++){
      let guid = members[j]
      avatars.push(
        <Row 
          key={guid} 
          className={'member-overlay-selected flex-nowrap'}>
            <Col>
              <Avatar
                height={35}
                width={35}
                key={guid.id}
                guid={guid.id}
                name={members[j].label}
                placement={'top'}
              />
            </Col>
            <Col>
              <Button onClick={() => this.handleOnSelectedClick(guid)} className={'float-right'} variant="link"><FontAwesomeIcon icon={faPlusCircle} /></Button>
            </Col>
        </Row>
      )
    }
    return avatars
  }

  renderMembers(members){
    var avatars = []
    for(var j = 0; j<members.length;j++){
      let guid = members[j]
      avatars.push(
        <Row 
          key={members[j].guid} 
          className={'member-overlay-selected flex-nowrap'}>
            <Col>
              <Avatar
                height={35}
                width={35}
                key={members[j].guid}
                guid={members[j].guid}
                name={members[j].name}
                placement={'top'}
              />
            </Col>
            <Col>
              <Button onClick={() => this.props.handleOnRemove(guid)} className={'float-right'} variant="link"><FontAwesomeIcon icon={faTrash} /></Button>
            </Col>
        </Row>
      )
    }
    return avatars
  }

  render(){
    const {t} = this.props
    return(<>
    <Col ref={this.ref} >
      <img onClick={(e)=>this.handleClick(e)} style={{width:'35px',height:'35px'}} key={'addMember'} src={userAddImage} alt={'attendee.name'} className={"avatar"} />
      <Overlay
        show={this.state.show}
        target={this.state.target}
        placement='bottom-end'
        container={this.ref.current}
        rootClose={true}
        rootCloseEvent='click'
        onHide={() => {
          this.setState({
            show:false
          })
        }}
        onExited={() => {
          this.setState({
            userList:[],
            selected:null
          })
        }}
        >
          <Popover id={`popover-positioned-1`}>
            <Popover.Content>
              <AsyncTypeahead
                id={'usersSearch'}
                searchText={t('searching...')}
                placeholder={t('Enter email address')}
                isLoading={this.state.isLoading}
                onSearch={(query) => {
                  this.setState({isLoading: true});
                  let token = getToken()
                  if(token) {
                    axios.post(`${window._env_.REACT_APP_SERVICE_API}/users/find`, {  
                      value:query+'%'
                    }, {
                      headers: {
                        'Authorization': `Bearer ${token}`
                      }}).then(response => {
                        if(response.data){
                          var items=[]
                          for(var j = 0; j<response.data.length;j++){
                            items.push(
                              {id: response.data[j].guid, label:response.data[j].mail}
                            )
                          }
                          this.setState({
                            isLoading: false,
                            userList:items
                          })
                        }
                    }).catch(error =>{
                      this.setState({
                        isLoading: false,
                      })
                    })
                  }
                }}
                onChange={(selected) => {
                  this.setState({selected});
                }}
                options={this.state.userList}
              />
              {this.state.selected && (<><p><b>{t('Not assigned')}</b></p>{this.renderSelected(this.state.selected)}</>)}
              {this.props.members && (<><p><b>{t('Assigned')}</b></p>{this.renderMembers(this.props.members)}</>)}
            </Popover.Content>
          </Popover>
        </Overlay>
      </Col>
    </>
    )
  }
}

export default (withTranslation()(AddMemberOverlay));