import React from 'react';
import './Charts.scss'

function BarGroup(props) {
  let maxValue = props.maxValue + 20 || 100
  let barPadding = 2
  let barColour = '#002d73'
  const withScale = (m, w, d) => {
    let oneWidth = d * (w / m)
    return (oneWidth)
  }

  let width = withScale(maxValue,props.barWidth,props.d.value)
  let yMid = props.barHeight * 0.5
  
  return (<g className="bar-group">
    <text className="name-label" x="-6" y={yMid} alignmentBaseline="middle" >{props.d.name}</text>
    <rect y={barPadding * 0.5} width={width} height={props.barHeight - barPadding} fill={barColour} />
    {width > 0 && (<text className="value-label" x={width- 8} y={yMid} alignmentBaseline="middle" >{props.d.value}</text>)}
  </g>)
} 

const BarChart = props => {
  let maxValue = Math.max.apply(Math, props.data.map(function(d) { return d.value; }))
  let barHeight = 60
  let barGroups = props.data.map((d, i) => 
    <g key={d.name} transform={`translate(0, ${i * barHeight})`}>
      <BarGroup d={d} barWidth={props.maxWidth} barHeight={barHeight} maxValue={maxValue}/>
    </g>
  )

  return(
    <svg width="100%" height="350" >
      <g className="chart-container">
        <g className="chart" transform="translate(50,60)">
          {barGroups}
        </g>
      </g>
    </svg>
  )
}

export default BarChart;