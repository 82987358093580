import axios from 'axios';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getToken } from './Common';

export const exportCustomerHoursToCSV = (id,startDate,endDate,fileName) => {
  let token = getToken()
  if(token) {
    axios.get(`${window._env_.REACT_APP_SERVICE_API}/customer/${id}/export?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(response => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(response.data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);

      }).catch(error =>{
        console.log(error)
    })
  }
}

export const exportProjectHoursToCSV = (id,startDate,endDate,fileName) => {
  let token = getToken()
  if(token) {
    axios.get(`${window._env_.REACT_APP_SERVICE_API}/project/${id}/export?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }}).then(response => {
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(response.data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);

      }).catch(error =>{
        console.log(error)
    })
  }
}