import React, { useState, useEffect, useRef }  from 'react';
import { connect } from "react-redux";
import { ShowSpinner, UpdateUser, WhoIam, GetMyNotification, AddMyNotification, RemoveMyNotification } from "../../store/Actions/index";
import ImageCropper from './components/ImageCropper'
import { withTranslation } from 'react-i18next';
import { Image, Row, Tabs, Tab, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin, faXing, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import ProfileTab from './components/Tabs/ProfileTab'
import ContactTab from './components/Tabs/ContactTab'
import AboutTab from './components/Tabs/AboutTab'
import NotificationTab from './components/Tabs/NotificationTab'

import defaultImgae from '../../images/defaultUserM.png'
import defaultBackground from '../../images/defaultUserBackground.jpg'

const Profile = (props) => {

  
  const dropDownStyle = 'outline-secondary rounded-lg'
  const [data, setData ] = useState({
    about: null,
    jobTitle: null,
    department: null,
    givenname: null,
    surname: null,
    location: null,
    company: null,
    postalCode: null,
    phone: null,
    photo: null,
    background:null,
    verificationState: null,
    accountant: null,
    xing: null,
    linkedin: null,
    twitter: null,
    facebook: null,
  })

  const [edit, setEdit ] = useState({
    editAbout: false,
    editBasic: false,
    editSocial: false,
    editJob:false,
    editNotification: false,
  })

  const [imageCropper, setImageCropper ] = useState({
    showImageCropper: false,
    attributeImageCropper:'photo',
  }) 

  const [tabKey, setTabKey ] = useState('profile')

  useEffect(() => {
    props.ShowSpinner()
    props.WhoIam()
    props.GetMyNotification()
  },[]);

  const initState = () => {
    setData({
      about: null,
      jobTitle: null,
      department: null,
      xing: null,
      linkedin: null,
      twitter: null,
      facebook: null,
      givenname: null,
      surname: null,
      company: null,
      location: null,
      postalCode: null,
      phone: null,
      photo:null,
      background:null,
      selectedFile: null,
    })
    setEdit({
      editAbout: false,
      editBasic: false,
      editSocial: false,
      editOffice: false,
      editJob: false,
    })
  }
  const inputReference = useRef();
  const inputReferencePhoto = useRef();

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }));
  };


  const handleOnToggleImageCropper = (value,type) => {
    setImageCropper({
      showImageCropper:value, 
      attributeImageCropper:type
    })
  }

  const handleOnSelectDropdown = (e) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [e]: true
    }));
  }

  const handleOnCancel = (e) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [e]: false
    }));
  }

  const handleOnDeleteBackground = () => {
    setData(prevData => ({
        ...prevData,
        background: ''
    }));

    handleOnSubmit();
  }

  const handleOnChangeNotification = (e) => {
    var eData = e.target
    if(eData.checked)
      props.AddMyNotification(eData.id)
    else
      props.RemoveMyNotification(eData.id)
  }

  const handleOnSubmit = () => {
    let obj = {
      guid:props.user.guid,
      givenname:data.givenname,
      surname:data.surname,
      phone:data.phone,
      photo:data.photo,
      company:data.company,
      location:data.location,
      postalCode:data.postalCode,
      department:data.department,
      jobTitle:data.jobTitle,
      about:data.about,
      background:data.background,
      xing:data.xing,
      facebook:data.facebook,
      linkedin:data.linkedin,
      twitter:data.twitter,
    }
    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key])
    props.UpdateUser(obj)
    initState()
  }

  const {t} = props
  return(
    <>
    <Card style={{border: 'none'}} >
      <Card.Img
        className={'user-background'}
        src={props.user.background ? props.user.background : defaultBackground}
      >
      </Card.Img>
      <Card.ImgOverlay className={'text-right'}>
        <Button 
          className={'edit-btn'}
          variant={dropDownStyle}
          onClick={handleOnDeleteBackground}>
            X
        </Button>{' '}
        <Button 
          className={'edit-btn'}
          variant={dropDownStyle}
          onClick={()=>handleOnToggleImageCropper(true,'background')}>
            {t('imageUpload')}
        </Button>
      </Card.ImgOverlay>
      <Card.Body>
        <Row>
          <Col md={6} lg={6} xs={6} className={'text-left'}>
            <Image onClick={()=>handleOnToggleImageCropper(true,'photo')} className={'img-fluid user-image'} src={props.user.photo ? props.user.photo : defaultImgae} roundedCircle />
          </Col>
          <Col md={4} lg={6} xs={4} className={'text-right'}>
            {props.user.xing ? <><FontAwesomeIcon style={{fontSize: "20px"}} onClick={()=> window.open(props.user.xing, '_blank')} icon={faXing} target={'_blank'}/>{' '}</> : <></>}
            {props.user.linkedin ? <><FontAwesomeIcon  style={{fontSize: "20px"}} onClick={()=> window.open(props.user.linkedin, '_blank')} icon={faLinkedin} />{' '}</> : <></>}
            {props.user.facebook ? <><FontAwesomeIcon style={{fontSize: "20px"}} onClick={()=> window.open(props.user.facebook, '_blank')} icon={faFacebookF} />{' '}</> : <></>}
            {props.user.twitter ? <><FontAwesomeIcon style={{fontSize: "20px"}} onClick={()=> window.open(props.user.twitter, '_blank')} icon={faTwitter} />{' '}</> : <></>}
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <h1>{props.user.givenname}, {props.user.surname}</h1>
            <h5>{props.user.jobTitle}</h5>
          </Col>
        </Row>
        <Row>
          <Col md={7} lg={7}>
            <h6>{props.user.company ? props.user.company : ''}</h6>
            <h6>{props.user.department ? props.user.department : ''}</h6>
            <h6>{props.user.postalCode ? props.user.postalCode + ' ' + props.user.location : ''}</h6>
          </Col>
          <Col md={5} lg={5}>
            {props.user.mail ? <><p><FontAwesomeIcon icon={faEnvelope}/>{' '}<a href={"mailto:" + props.user.mail}>{props.user.mail}</a></p></> : ''}
            {props.user.phone ? <><p><FontAwesomeIcon icon={faPhoneAlt}/>{' '}<a href={"tel:" + props.user.phone}>{props.user.phone}</a></p></>  : ''}
          </Col>
        </Row>
        <Row className={'mt-25'}>
          <Col md={12}>
            <Tabs 
              defaultActiveKey="profile" 
              id="employee-tabs"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab eventKey="profile" title={t('Profile')}>
                <ProfileTab
                  dropDownStyle={dropDownStyle}
                  user={props.user}
                  edit={edit.editBasic}
                  handleOnChange={handleOnChange}
                  handleOnSelectDropdown={handleOnSelectDropdown}
                  handleOnCancel={handleOnCancel}
                  handleOnSubmit={handleOnSubmit}
                />
              </Tab>

              <Tab eventKey="contact" title={t('Contact')}>
                <ContactTab
                    dropDownStyle={dropDownStyle}
                    user={props.user}
                    edit={edit.editOffice}
                    showSocial={true}
                    editSocial={edit.editSocial}
                    handleOnChange={handleOnChange}
                    handleOnSelectDropdown={handleOnSelectDropdown}
                    handleOnCancel={handleOnCancel}
                    handleOnSubmit={handleOnSubmit}
                  />
              </Tab>

              <Tab eventKey="about" title={t('About')}>
                <AboutTab
                  dropDownStyle={dropDownStyle}
                  user={props.user}
                  editJob={edit.editJob}
                  editAbout={edit.editAbout}
                  handleOnChange={handleOnChange}
                  handleOnSelectDropdown={handleOnSelectDropdown}
                  handleOnCancel={handleOnCancel}
                  handleOnSubmit={handleOnSubmit}
                />
              </Tab>

              <Tab eventKey="notification" title={t('Notification')}>
                <NotificationTab
                  userEmailNotification={props.userEmailNotification}
                  handleOnChange={handleOnChangeNotification}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Card.Body>
    </Card>
    <ImageCropper 
      show={imageCropper.showImageCropper}
      animation={true}
      onHide={()=>handleOnToggleImageCropper(false)}
      attribute={imageCropper.attributeImageCropper}
      guid={props.user.guid}
    />
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    userEmailNotification: state.userEmailNotification
  };
};

export default connect(mapStateToProps,{ShowSpinner, UpdateUser, WhoIam, GetMyNotification, AddMyNotification, RemoveMyNotification})(withTranslation()(Profile));