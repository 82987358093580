import React from 'react';
import { IconEdit } from '../../../../assets/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faLinkedin, faXing, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'

const ContactTab = (props) => {

  const {t} = props
  return(<>
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Office')} <b>{t('Address')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4} className={'text-right'}>
        <IconEdit 
          onClick={() => props.handleOnSelectDropdown("editOffice")}
        />
      </Col>
      <Col md={12} lg={12}>
        <label>{t('Company')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="company" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.company}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.company}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <label>{t('postalCode')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="postalCode" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.postalCode}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.postalCode}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <label>{t('Location')}</label>
        {props.edit ?
        <>
        <Form.Group controlId="location" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.location}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.location}</p>
        }
      </Col>
      {props.edit ? 
      <>
      <Col md={12} lg={12}>
        <Form.Group>
          <Button onClick={() => props.handleOnCancel('editOffice')} variant="secondary">{t('Cancel')}</Button>{' '}
          <Button onClick={props.handleOnSubmit} variant="primary" >{t('Save')}</Button>
        </Form.Group>
      </Col>
      </>
      :
      <>
      </>}
    </Row>
    {props.showSocial &&
    <Row className='mt-25'>
      <Col md={8} lg={8} xs={8}>
        <h4>{t('Sozial')} <b>{t('Media')}</b></h4>
      </Col>
      <Col md={4} lg={4} xs={4} className={'text-right'}>
        <IconEdit 
          onClick={() => props.handleOnSelectDropdown("editSocial")}
        />
      </Col>
      <Col md={12} lg={6}>
        <FontAwesomeIcon icon={faXing} />{' '}<label>Xing</label>
        {props.editSocial ?
        <>
        <Form.Group controlId="xing" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.xing}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.xing}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <FontAwesomeIcon icon={faLinkedin} />{' '}<label>LinkedIn</label>
        {props.editSocial ?
        <>
        <Form.Group controlId="linkedin" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.linkedin}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.linkedin}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <FontAwesomeIcon icon={faFacebookF} />{' '}<label>Facebook</label>
        {props.editSocial ?
        <>
        <Form.Group controlId="facebook" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.facebook}/>
        </Form.Group>
        </> 
        :
        <p className="font-weight-bold" >{props.user.facebook}</p>
        }
      </Col>
      <Col md={12} lg={6}>
        <FontAwesomeIcon icon={faTwitter} />{' '}<label>Twitter</label>
        {props.editSocial ?
        <>
        <Form.Group controlId="twitter" >
          <Form.Control type={'text'} onChange={props.handleOnChange} defaultValue={props.user.twitter}/>
        </Form.Group>  
        </> 
        :
        <p className="font-weight-bold" >{props.user.twitter}</p>
        }
      </Col>
      {props.editSocial ? 
      <>
      <Col md={12} lg={12}>
        <Form.Group>
          <Button onClick={() => props.handleOnCancel('editSocial')} variant="secondary">{t('Cancel')}</Button>{' '}
          <Button onClick={props.handleOnSubmit} variant="primary" >{t('Save')}</Button>
        </Form.Group>
      </Col>
      </>
      :
      <>
      </>}
    </Row>}
  </>)
}

export default (withTranslation()(ContactTab));