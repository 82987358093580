import React, { Component } from 'react';
import axios from 'axios';
import config from '../../../../config/Settings.json'
import { connect } from "react-redux";
import { GetCustomer, GetProject, ShowSpinner, ShowDialog } from "../../../../store/Actions/index";
import Select from 'react-select'
import { getToken } from '../../../../utils/Common';
import { Modal, Button } from 'react-bootstrap';
import DraggableModalDialog from '../../../DraggableModalDialog/DraggableModalDialog'
import { withTranslation } from 'react-i18next';

class AddUserModal extends Component {
  constructor(props){
    super(props)
    this.state = {
      userList:null,
      mail:'',
      selectedUsers:null
    }

    this.handleOnSubmit = this.handleOnSubmit.bind(this)
    this.searchUser = this.searchUser.bind(this)
  }

  handleOnSubmit(){
    let token = getToken()
    if(token && this.state.selectedUsers) {
      for(var j = 0; j<this.state.selectedUsers.length;j++){
        axios.post(`${window._env_.REACT_APP_SERVICE_API}/${this.props.apiRoute}/${this.props.id}/member`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: { 
            'guid':this.state.selectedUsers[j].value
          }}).then(response => {
            if(response.data.affectedRows>0){
              this.props.ShowSpinner()
              if(this.props.apiRoute === "customer")
                this.props.GetCustomer(this.props.id)
              else if(this.props.apiRoute === "project")
                this.props.GetProject(this.props.id)

              this.props.onHide()
            }
        }).catch(error =>{
          console.log(error)
          this.props.onHide()
          this.props.ShowDialog('Error', 'You do not have permisson to process this action.')
        })
      }
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.show && !this.props.show)
      this.searchUser()
  }

  searchUser(){
    let token = getToken()
    if(token) {
      axios.post(`${window._env_.REACT_APP_SERVICE_API}/users/find`, {  
        value:this.state.mail+'%'
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(response => {
          if(response.data){
            var items=[]
            for(var j = 0; j<response.data.length;j++){
              items.push(
                {value: response.data[j].guid, label:response.data[j].mail}
              )
            }
            this.setState({userList:items})
          }
      }).catch(error =>{
        this.props.onHide()
        this.props.ShowDialog('Error', 'You do not have permisson to process this action.')
      })
    }
  }

  handleChange = selectedOption => {
    this.setState({selectedUsers:selectedOption})
  };

  render(){
    const {t} = this.props
    return(
      <Modal
      {...this.props}
      size="md"
      backdrop="static"
      dialogAs={DraggableModalDialog} 
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        {t('Add')} <b>{t('Member')}</b>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      {t('sentences.selectMemberAddUserModal')}
      </Modal.Body>
      <Modal.Body>
        <Select 
          name="manager"
          options={this.state.userList}
          isClearable={true}
          isMulti
          delimiter={';'}
          onChange={this.handleChange}
          />
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={this.props.onHide} variant="secondary">{t('Cancel')}</Button>{' '}
        <Button onClick={this.handleOnSubmit} variant="primary" >{t('Save')}</Button>{' '}
      </Modal.Footer>
    </Modal>
    )
  }
}

export default connect(null,{GetCustomer, GetProject, ShowSpinner, ShowDialog})(withTranslation()(AddUserModal));