import React from 'react'
import { connect } from "react-redux"
import { ShowSpinner, HideSpinner, RemoveTimeSheet, RequestTimeSheet, SignTimeSheet } from "../../../../store/Actions/index"
import { createTimeSheetPDF } from "../../../../utils/PDF"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faFileDownload, faShareSquare, faTrash, faSignature } from '@fortawesome/free-solid-svg-icons'

const dateFormat = require('dateformat');
dateFormat.i18n = {
  dayNames: [
      'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
      'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'
  ],
  monthNames: [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ],
  timeNames: [
      'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
  ]
};

const TimeSheetTableRow = props => {

  const handleDeleteEntry = (event) => {
    props.RemoveTimeSheet(event)
  }

  const handleRequestTimesheet = (id,filename) => {
    props.ShowSpinner()
    props.RequestTimeSheet(id,filename,props.Username)
  }

  const handleDownloadPDF = (id,name,out) => {
    props.ShowSpinner()
    createTimeSheetPDF(id,name, props.Username, out)
    props.HideSpinner()
  }

  return(
    <tr onDoubleClick={()=>handleDownloadPDF(props.Id, `${props.Username}_${dateFormat(props.From,"d mmm yyyy")}_${dateFormat(props.To,"d mmm yyyy")}.pdf`, 'dataurlnewwindow')}>
      <td><FontAwesomeIcon style={{color: "#EE3B3B", fontSize: "30px"}} icon={faFilePdf} onClick={()=>handleDownloadPDF(props.Id, `${props.Username}_${dateFormat(props.From,"d mmm yyyy")}_${dateFormat(props.To,"d mmm yyyy")}.pdf`, 'dataurlnewwindow')}/></td>
      <td colSpan={2}> {props.Username}_{dateFormat(props.From,"d mmm yyyy")}_{dateFormat(props.To,"d mmm yyyy")}.pdf</td>
      <td></td>
      <td></td>
      <td>
        {props.State === 'signed' ? <><FontAwesomeIcon style={{color: "#008000", fontSize: "20px"}} icon={faShareSquare} onClick={()=>handleRequestTimesheet(props.Id, `${props.Username}_${dateFormat(props.From,"d mmm yyyy")}_${dateFormat(props.To,"d mmm yyyy")}.pdf`)} />{'  '}</> : <></>}
        <FontAwesomeIcon className={"text-info"} style={{fontSize: "20px"}} icon={faFileDownload} onClick={()=>handleDownloadPDF(props.Id, `${props.Username}_${dateFormat(props.From,"d mmm yyyy")}_${dateFormat(props.To,"d mmm yyyy")}.pdf`)} />{'  '}
        <FontAwesomeIcon style={{color: "#d9534f", fontSize: "20px"}} icon={faTrash} onClick={()=>handleDeleteEntry(props.Id)} />{'  '}
        {props.State === 'prepared' ? <FontAwesomeIcon className={"text-success"} style={{fontSize: "20px"}} icon={faSignature} onClick={()=>props.SignTimeSheet(props.Id)} /> : ''}{'  '}
      </td>
    </tr>
  );
  
}

export default connect(null,{ShowSpinner, HideSpinner, RemoveTimeSheet, RequestTimeSheet, SignTimeSheet})(TimeSheetTableRow)