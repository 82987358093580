import { LIST_WORKRECORDS, REMOVE_WORKRECORD, INSERT_WORKRECORD, UPDATE_WORKRECORD, SELECT_WORKRECORD, HIDE_WORKRECORD } from "../Constants/action-types";

import { getToken, getUser } from '../../utils/Common';
import config from '../../config/Settings.json';

export function ListWorkRecords(start,count){
  return function(dispatch){
    const user = getUser()
    const token = getToken()

    if(token && user){
      var request = require('request');
      var options = {
        'method': 'POST',
        'url': window._env_.REACT_APP_SERVICE_API + '/workrecords',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({"start":start,"count":count})
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json) {
          return dispatch({ type: LIST_WORKRECORDS, payload: json });
        }
      });
    }
  }
}

export function RemoveWorkRecords(id){
  return function(dispatch){
    const token = getToken()
    if(token){
      var request = require('request');
      var options = {
        'method': 'DELETE',
        'url': window._env_.REACT_APP_SERVICE_API + '/workrecords/' + id,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        return dispatch({ type: REMOVE_WORKRECORD, payload: id });
      });
    }
  }
}

export function UpdateWorkRecords(obj){
  return function(dispatch){
    const token = getToken()
    if(token){
      var requestObj = obj
      if('Customer_Name' in requestObj)
        delete requestObj['Customer_Name']

      if('Project_Name' in requestObj)
        delete requestObj['Project_Name']

      var request = require('request');
      var options = {
        'method': 'PATCH',
        'url': window._env_.REACT_APP_SERVICE_API + '/workrecords/' + obj.Id,
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestObj)
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if (json)
          return dispatch({ type: UPDATE_WORKRECORD, payload: json });
      });
    }
  }
}

export function InsertWorkRecords(obj){
  return function(dispatch){
    const token = getToken()
    const user = getUser()
    if(token && user){
      var request = require('request');
      var options = {
        'method': 'PUT',
        'url': window._env_.REACT_APP_SERVICE_API + '/workrecords',
        'headers': {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          "Employee":user.username,
          "Date":obj.Date,
          "Project_fk":obj.Project_fk || null,
          "Customer_fk":obj.Customer_fk || null,
          "Description":obj.Description || null,
          "TravelTime":obj.TravelTime || null,
          "Departure":obj.Departure || null,
          "Arrival":obj.Arrival || null,
          "DistanceKM":obj.DistanceKM || null,
          "FoodAllowance":obj.FoodAllowance || null,
          "TravelExpenses":obj.TravelExpenses || null,
          "HoursWorked":obj.HoursWorked || null,
          "Location":obj.Location || null,
        })
      };
      request(options, function (error, response, body) {
        if (error) throw new Error(error);
        var json = JSON.parse(body)
        if(json.insertId){
          obj.Id = json.insertId
          obj.Customer_fk = parseInt(obj.Customer_fk)
          obj.Project_fk = parseInt(obj.Project_fk)
          obj.TravelTime = parseFloat(obj.TravelTime)
          obj.HoursWorked = parseFloat(obj.HoursWorked)
          obj.DistanceKM = parseFloat(obj.DistanceKM)
          return dispatch({ type: INSERT_WORKRECORD, payload: obj });
        }
      });
    }
  }
}

export function SelectWorkRecord(Id) {
  return function(dispatch){
    return dispatch({type: SELECT_WORKRECORD, payload: Id });
  }
}

export function DestroyWorkRecordStore(){
  return function(dispatch){
    return dispatch({type: SELECT_WORKRECORD, payload: null });
  }
}

export function HideWorkRecord() {
  return function(dispatch){
    return dispatch({type: HIDE_WORKRECORD, payload: false });
  }
}