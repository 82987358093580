import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken, removeUserSession, setUserSession } from './Common';
import axios from 'axios';
function PrivateRoute({ component: Component, user, ...rest }) {

  useEffect( () => {
    const token = getToken();
    if (!token) {
      return;
    }
  
    axios.get(`${window._env_.REACT_APP_SERVICE_API}/auth/verifyToken?token=${token}`).then(response => {
      setUserSession(response.data.token, response.data.user);
    }).catch(error => {
      removeUserSession();
    });
  });

  return (
    <Route
      {...rest}
      render={(props) => getToken() ? <Component {...props} {...user} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />}
    />
  )
}
 
export default PrivateRoute;