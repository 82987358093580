import React from 'react';
import { connect } from "react-redux";
import { Container, Col, Jumbotron, Image } from 'react-bootstrap';
import '../ProjectTracking.css';
import defaultImgae from '../../../images/fatal.png'

const Error = props => {
  return(
    <Jumbotron fluid>
      <Container>
        <Col md={6} lg={6} xs={6}>
          <Image style={{ width: '180px'}} src={defaultImgae} roundedCircle />
        </Col>
        <Col md={6} lg={6} xs={6}>
          <h3>Server Error</h3>
          <h1>{props.error.code}</h1>
        </Col>
        <Col md={12} lg={12}>
          <h6>{props.error.message}</h6>
        </Col>
      </Container>
    </Jumbotron>
  )
}

const mapStateToProps = state => {
  return {
    error: state.error,
  };
};

export default connect(mapStateToProps)(Error);