import React, { useState, useEffect }  from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { ShowSpinner, GetRole, SelectEmployees, UpdateEmployee } from "../../store/Actions/index";
import { Image, Row, Tabs, Tab, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'

import ProfileTab from './components/Tabs/ProfileTab'
import ContactTab from './components/Tabs/ContactTab'
import AboutTab from './components/Tabs/AboutTab'
import SettingsTab from './components/Tabs/SettingsTab';

import defaultImgae from '../../images/defaultUserM.png'
import defaultBackground from '../../images/defaultUserBackground.jpg'


import './IdentityManagement.scss'

const Account = (props) => {

  const dropDownStyle = 'outline-secondary rounded-lg'
  const [data, setData ] = useState({
    about: null,
    jobTitle: null,
    department: null,
    givenname: null,
    surname: null,
    location: null,
    company: null,
    postalCode: null,
    phone: null,
    verificationState: null,
    accountant: null,
    roles: null,
  })

  const [edit, setEdit ] = useState({
    editProfile: false,
    editAbout: false,
    editBasic: false,
    editSettings: false,
    editJob:false,
  })

  const [tabKey, setTabKey ] = useState('profile')

  useEffect(() => {
    let id = props.match.params.urlPath
    props.ShowSpinner()
    props.GetRole()
    props.SelectEmployees(id)
  },[props.match.params.urlPath]);

  const initState = () => {
    setData({
      about: null,
      jobTitle: null,
      department: null,
      givenname: null,
      surname: null,
      company: null,
      location: null,
      postalCode: null,
      accountant: null,
      phone: null,
      verificationState: null,
      roles: null,
    })
    setEdit({
      editProfile: false,
      editAbout: false,
      editBasic: false,
      editOffice: false,
      editSettings:false,
      editJob:false,
    })
  }

  const handleOnChange = (e) => {
    const { id, value } = e.target
    setData(prevData => ({
        ...prevData,
        [id]: value
    }));
  };

  const handleOnSelectDropdown = (e) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [e]: true
    }));
  }

  const handleOnCancel = (e) => {
    setEdit(prevEdit => ({
      ...prevEdit,
      [e]: false
    }));
  }

  const handleOnSubmit = () => {
    let obj = {
      guid:props.user.guid,
      givenname:data.givenname,
      surname:data.surname,
      phone:data.phone,
      company:data.company,
      location:data.location,
      postalCode:data.postalCode,
      verificationState:data.verificationState,
      department:data.department,
      jobTitle:data.jobTitle,
      accountant:data.accountant,
      roles:data.roles,
      about:data.about
    }
    Object.keys(obj).forEach((key) => (obj[key] == null) && delete obj[key])
    props.UpdateEmployee(obj)
    initState()
  }

  const {t} = props
  return(
    <Card style={{border: 'none'}}>
      <Card.Img
        className={'user-background'}
        src={props.user.background ? props.user.background : defaultBackground}
      >
      </Card.Img>
      <Card.Body>
        <Row>
          <Col md={8} lg={6} xs={8} className={'text-left'}>
            <Image className={'img-fluid user-image'} src={props.user.photo ? props.user.photo : defaultImgae} roundedCircle />
          </Col>
          <Col md={4} lg={6} xs={4} className={'text-right'}>
          </Col>
        </Row>

        <Row>
          <Col md={12} lg={12}>
            <h1>{props.user.givenname}, {props.user.surname}</h1>
            <h5>{props.user.jobTitle}</h5>
          </Col>
        </Row>

        <Row>
          <Col md={8} lg={8}>
            <h6>{props.user.company && props.user.company }</h6>
            <h6>{props.user.department && props.user.department }</h6>
            <h6>{props.user.postalCode && props.user.postalCode + ' ' + props.user.location }</h6>
          </Col>
          <Col md={4} lg={4}>
            {props.user.mail && <><p><FontAwesomeIcon icon={faEnvelope}/>{' '}<a href={"mailto:" + props.user.mail}>{props.user.mail}</a></p></> }
            {props.user.phone && <><p><FontAwesomeIcon icon={faPhoneAlt}/>{' '}<a href={"tel:" + props.user.phone}>{props.user.phone}</a></p></> }
          </Col>
        </Row>

        {props.user.about && 
        <Row className={'mt-25'}>
          <Col md={8} lg={8}>
            <h4>{t('About')} <b>{t('Me')}</b></h4>
          </Col>
          <Col md={4} lg={4}>
          </Col>
          <Col  md={12} lg={12} xs={12}>
            <p>{props.user.about}</p>
          </Col>
        </Row>}
        {props.userRole === 'admin' &&
        <Row className={'mt-25'}>
          <Col md={12}>
            <Tabs 
              defaultActiveKey="profile" 
              id="employee-tabs"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k)}
            >
              <Tab eventKey="profile" title={t('Profile')}>
                <ProfileTab
                  dropDownStyle={dropDownStyle}
                  user={props.user}
                  edit={edit.editBasic}
                  handleOnChange={handleOnChange}
                  handleOnSelectDropdown={handleOnSelectDropdown}
                  handleOnCancel={handleOnCancel}
                  handleOnSubmit={handleOnSubmit}
                />
              </Tab>

              <Tab eventKey="contact" title={t('Contact')}>
                <ContactTab
                    dropDownStyle={dropDownStyle}
                    user={props.user}
                    edit={edit.editOffice}
                    handleOnChange={handleOnChange}
                    handleOnSelectDropdown={handleOnSelectDropdown}
                    handleOnCancel={handleOnCancel}
                    handleOnSubmit={handleOnSubmit}
                  />
              </Tab>

              <Tab eventKey="about" title={t('About')}>
                <AboutTab
                  dropDownStyle={dropDownStyle}
                  user={props.user}
                  editJob={edit.editJob}
                  editAbout={edit.editAbout}
                  handleOnChange={handleOnChange}
                  handleOnSelectDropdown={handleOnSelectDropdown}
                  handleOnCancel={handleOnCancel}
                  handleOnSubmit={handleOnSubmit}
                />
              </Tab>
              {props.userRole === 'admin' &&
              <Tab eventKey="settings" title={t('Settings')}>
                <SettingsTab
                  dropDownStyle={dropDownStyle}
                  user={props.user}
                  edit={edit.editSettings}
                  handleOnChange={handleOnChange}
                  handleOnSelectDropdown={handleOnSelectDropdown}
                  handleOnCancel={handleOnCancel}
                  handleOnSubmit={handleOnSubmit}
                />
              </Tab>
              }
            </Tabs>
          </Col>
        </Row>}
      </Card.Body>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    user: state.thisEmployee,
    userRole: state.userRole,
  };
};

export default connect(mapStateToProps,{ShowSpinner, GetRole, SelectEmployees, UpdateEmployee})(withTranslation()(Account));